<div id="dailydairy" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    DAILY DIARY
                    <a [routerLink]="['./']" skipLocationChange="true" replaceUrl="false"
                        class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)"
                        placeholder="Search for a daily diary">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <!-- <button *ngIf="PageRights.IsAdd" mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false"
                    class="add-dailydairy-button fuse-white mt-24 mt-md-0">
                    <span>ADD</span>
                </button> -->
                <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exporter.exportTable('xlsx', {fileName:'DailydairyFullReport', sheet: 'DailydairyFullReport', Props: {Author: 'Lighthouse'}})">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

                <mat-table matTableExporter class="dailydairy-table responsive-table" #table [dataSource]="tableDataSource" #exporter="matTableExporter" [hiddenColumns]="[13]"
                matSort fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">
                
                <!-- 1DateOfActivityColumn -->
                <ng-container matColumnDef="DateOfActivity">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Date Of Activity
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailydairy" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{dailydairy.DateOfActivity | date: Constants.ShortDateFormat}} </p>
                    </mat-cell>
                </ng-container>

                <!-- 2FullName Column -->
                <ng-container matColumnDef="FullName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailydairy" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{dailydairy.FullName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 3WhatsappMobile Column -->
                <ng-container matColumnDef="WhatsappMobile">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Mobile(WhatsApp)
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailydairy" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{dailydairy.WhatsappMobile}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 3IsActivityPerformed Column -->
                <ng-container matColumnDef="IsActivityPerformed">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Did you perform any activity at your internship today?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailydairy" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{dailydairy.IsActivityPerformed}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 4HoursWorked Column -->
                <ng-container matColumnDef="HoursWorked">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>How many hours did you work today?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailydairy" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{dailydairy.HoursWorked}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 5ActivitiesPerformed Column -->
                <ng-container matColumnDef="ActivitiesPerformed">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Specific activities you performed today!
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailydairy" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{dailydairy.ActivitiesPerformed}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 6LearntToday Column -->
                <ng-container matColumnDef="LearntToday">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Share what all you learnt today! 
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailydairy" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{dailydairy.LearntToday}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 7TookSafetyPrecautions Column -->
                <ng-container matColumnDef="TookSafetyPrecautions">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Did you take safety precautions suggested by the employer/Teacher?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailydairy" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{dailydairy.TookSafetyPrecautions}}</p>
                    </mat-cell>
                </ng-container>


                <!-- 8EmployerTookSafety Column -->
                <ng-container matColumnDef="EmployerTookSafety">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Did the employer take care of your safety?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailydairy">
                        <p class="text-truncate">{{dailydairy.EmployerTookSafety}}</p>
                    </mat-cell>
                </ng-container>


                <!-- 9IsSafetyIssues Column -->
                <ng-container matColumnDef="IsSafetyIssues">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Were there any safety issues? 
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailydairy" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{dailydairy.IsSafetyIssues}}</p>
                    </mat-cell>
                </ng-container>


                <!-- 10SafetyIssues Column -->
                <ng-container matColumnDef="SafetyIssues">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Share the safety issues if any.
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailydairy" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{dailydairy.SafetyIssues}}</p>
                    </mat-cell>
                </ng-container>

 
                <ng-container matColumnDef="Attachment">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Today’s daily dairy picture</mat-header-cell>
                    <mat-cell *matCellDef="let dailydairy">
                        <a target="_blank" *ngIf="dailydairy.Attachment" href="{{dailydairy.Attachment}}">{{dailydairy.Attachment}}</a>
                        <p *ngIf="!dailydairy.Attachment" class="text-truncate">No Image</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Attachment2">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Other picture and videos from your workplace or any work done toda</mat-header-cell>
                    <mat-cell *matCellDef="let dailydairy">
                        <a target="_blank" *ngIf="dailydairy.Attachment2" href="{{dailydairy.Attachment2}}">{{dailydairy.Attachment2}}</a>
                        <p *ngIf="!dailydairy.Attachment2" class="text-truncate">No Image</p>
                    </mat-cell>
                </ng-container>


                <!-- 13Action Column -->
                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let dailydairy" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/dailydairy', 'view', dailydairy.DailydairyId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <!-- <button *ngIf="PageRights.IsEdit && dailydairy.DeletedBy == null" mat-icon-button
                            [routerLink]="['/dailydairy', 'edit', dailydairy.DailydairyId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">edit</mat-icon>
                        </button> -->
                        <!-- <button *ngIf="(PageRights.IsDelete && dailydairy.DeletedBy == null)" mat-icon-button color="warn" (click)="onDeleteDailydairy(dailydairy.DailydairyId)">
                            <mat-icon>delete_outline</mat-icon>
                        </button> -->
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let dailydairy; columns: displayedColumns;" class="dailydairy" matRipple>
                </mat-row>

            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>