<div id="vt-internship-report" class="page-layout carded fullwidth inner-scroll">
  <!-- TOP BACKGROUND -->
  <div class="top-bg accent"></div>
  <!-- / TOP BACKGROUND -->

  <!-- CENTER -->
  <div class="center">
    <!-- HEADER -->
    <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
      <!-- APP TITLE -->
      <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon s-32 mr-16" [@animate]="{ value: '*', params: { delay: '50ms', scale: '0.2' } }">shopping_basket</mat-icon>
        <span class="logo-text h1" [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }">
          INTERNSHIP REPORT
          <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
            <mat-icon [@animate]="{ value: '*', params: { delay: '50ms', scale: '0.2' } }" >refresh</mat-icon>
          </a>
        </span>
      </div>
      <!-- / APP TITLE -->

      <!-- SEARCH -->
      <div class="search-wrapper mx-32 mx-md-0">
        <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>search</mat-icon>
          <input #filter (keyup)="applySearchFilter($event.target.value)" placeholder="Search for a vt-internship-reports"/>
        </div>
      </div>
      <!-- / SEARCH -->

      <!-- ADD BUTTON -->
      <div class="action-buttons">
        <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exporter.exportTable('xlsx', { fileName: 'VTReportingInternships', sheet: 'VTReportingInternships', Props: { Author: 'Lighthouse' }})">
          <mat-icon> cloud_downlqoad</mat-icon>
        </a>
      </div>
      <!-- / ADD BUTTON -->
    </div>
    <!-- / HEADER -->

    <div fxLayout="row" fxLayoutAlign="start center">
      <button mat-raised-button class="fuse-white filter-button" style="margin-bottom: 10px" (click)="isShowFilterContainer = !isShowFilterContainer">
        <span>FILTERS</span>
      </button>
    </div>

    <!-- Filter Section -->
    <div class="filter-content-card" *ngIf="isShowFilterContainer">
      <div class="filter-content">
        <form name="vtReportingInternshipForm" [formGroup]="vtReportingInternshipForm" class="vt-reporting-internship" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
          <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
            <!-- <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100">
              <mat-label> Academic Year</mat-label>
              <mat-selectformControlName="AcademicYearId"name="academicYearId"appearance="fill">
                <mat-option [value]="academicYearItem.Id" *ngFor="let academicYearItem of academicyearList"> {{ academicYearItem.Name }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
              <mat-label> Month</mat-label>
              <mat-select formControlName="MonthId" name="monthId" appearance="fill">
                <mat-option [value]="monthItem.Id" *ngFor="let monthItem of monthList">{{ monthItem.Name }}</mat-option>
              </mat-select>
            </mat-form-field> -->

            <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
              <mat-label>State</mat-label>
              <mat-select formControlName="State" name="state" appearance="fill" (selectionChange)="onChangeState($event.value)">
                <mat-option *ngFor="let StateItem of stateList" [value]="StateItem.Id">
                  {{ StateItem.Name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
              <mat-label>Division</mat-label>
              <mat-select formControlName="DivisionId" name="DivisionId" appearance="fill" (selectionChange)="onChangeDivision($event.value)">
                <mat-option *ngFor="let divisionItem of divisionList" [value]="divisionItem.Id">
                  {{ divisionItem.Name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100"
              class="filter-disabled">
              <mat-label>District</mat-label>
              <mat-select formControlName="DistrictId" name="districtId" appearance="fill">
                <mat-option *ngFor="let districtItem of districtList" [value]="districtItem.Id"> {{ districtItem.Name }}</mat-option>
              </mat-select>
            </mat-form-field> 
            <!-- 
            <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100">
              <mat-label>Sector</mat-label>
              <mat-select formControlName="SectorId" name="sectorId" appearance="fill" (selectionChange)="onChangeSector($event.value)">
                <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">{{ sectorItem.Name }}</mat-option>
              </mat-select>
            </mat-form-field> -->
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
             <!-- <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100">
              <mat-label>Select Job Role</mat-label>
              <mat-select formControlName="JobRoleId" name="jobRoleId" appearance="fill">
                <mat-option [value]="jobRoleItem.Id" *ngFor="let jobRoleItem of jobRoleList">{{ jobRoleItem.Name }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100">
              <mat-label>Class</mat-label>
              <mat-select formControlName="ClassId" name="classId" appearance="fill">
                <mat-option [value]="classItem.Id" *ngFor="let classItem of classList">{{ classItem.Name }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
              <mat-label>Vocational Training Provider</mat-label>
              <mat-select formControlName="VTPId" name="vtpId" appearance="fill">
                <mat-option [value]="vtpItem.Id" *ngFor="let vtpItem of vtpList">{{ vtpItem.Name }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100">
              <mat-label>School Management</mat-label>

              <mat-select formControlName="SchoolManagementId" name="schoolManagementId" appearance="fill">
                <mat-option [value]="schoolManagementItem.Id" *ngFor="let schoolManagementItem of schoolManagementList"> 
                  {{ schoolManagementItem.Name }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->

            <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
              <button mat-raised-button class="fuse-white filter-button" (click)="getVTReportingInternshipReports()">
                <span>SEARCH</span>
              </button>
              <button mat-raised-button class="fuse-white filter-button" (click)="resetReportFilters()">
                <span>CLEAR</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- /Filter Section -->

    <!-- CONTENT CARD -->

    <div class="content-card">
      <mat-table class="vt-internship-report-table responsive-table" #table [dataSource]="tableDataSource" matTableExporter #exporter="matTableExporter" [hiddenColumns]="[0]" matSort fusePerfectScrollbar [fusePerfectScrollbarOptions]="{ suppressScrollX: false }">
        <!-- SrNo Column -->
        <!-- <ng-container matColumnDef="SrNo">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md >Sr No</mat-header-cell>
          <mat-cell *matCellDef="let vtInternshipReport; let RowIndex = index">
            <p class="text-truncate"></p>
          </mat-cell>
        </ng-container> -->

        <!-- SystemStudentUniqueID Column -->
        <ng-container matColumnDef="SystemStudentUniqueID">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>SystemStudentUniqueID</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ studentClass.SystemStudentUniqueID }}</p>
          </mat-cell>
        </ng-container>

        <!-- StateName Column -->
        <ng-container matColumnDef="StateName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student State</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ studentClass.StateName }}</p>
          </mat-cell>
        </ng-container>

        <!-- DistrictName Column -->
        <ng-container matColumnDef="DivisionId">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Division</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ studentClass.DivisionId }}</p>
          </mat-cell>
        </ng-container>

        <!-- DistrictName Column -->
        <ng-container matColumnDef="DistrictCode">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student District</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ studentClass.DistrictCode }}</p>
          </mat-cell>
        </ng-container>

        <!-- BlockId Column -->
        <ng-container matColumnDef="BlockId">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Block</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ studentClass.BlockId }}</p>
          </mat-cell>
        </ng-container>

        <!-- SchoolUniqueId Column -->
        <ng-container matColumnDef="SchoolUniqueId">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>SchoolId</mat-header-cell>
          <mat-cell *matCellDef="let school" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ school.SchoolUniqueId }}</p>
          </mat-cell>
        </ng-container>

        <!-- Udise Column -->
        <ng-container matColumnDef="Udise">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Udise</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ studentClass.Udise }}</p>
          </mat-cell>
        </ng-container>

        <!-- AcademicYear Column -->
        <ng-container matColumnDef="AcademicYear">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Academic Year</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ studentClass.AcademicYear }}</p>
          </mat-cell>
        </ng-container>

        <!-- DateOfCommencement Column -->
        <ng-container matColumnDef="DateOfCommencement">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Date Of Commencement (Internship Progress)</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">
              {{studentClass.DateOfCommencement | date: Constants.ShortDateFormat}}
            </p>
          </mat-cell>
        </ng-container>

        <!-- DateOfCompletion Column -->
        <ng-container matColumnDef="DateOfCompletion">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Date Of Completion (Internship Progress)</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">
              {{studentClass.DateOfCompletion | date: Constants.ShortDateFormat}}
            </p>
          </mat-cell>
        </ng-container>

        <!-- StFirstName Column -->
        <ng-container matColumnDef="StFirstName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Student FirstName</mat-header-cell>
          <mat-cell *matCellDef="let studentClass">
            <p class="text-truncate">{{ studentClass.StFirstName }}</p>
          </mat-cell>
        </ng-container>

        <!-- StMiddleName Column -->
        <ng-container matColumnDef="StMiddleName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Student MiddleName</mat-header-cell>
          <mat-cell *matCellDef="let studentClass">
            <p class="text-truncate">{{ studentClass.StMiddleName }}</p>
          </mat-cell>
        </ng-container>

        <!-- StLastName Column -->
        <ng-container matColumnDef="StLastName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Student LastName</mat-header-cell>
          <mat-cell *matCellDef="let studentClass">
            <p class="text-truncate">{{ studentClass.StLastName }}</p>
          </mat-cell>
        </ng-container>

        <!-- FullName Column -->
        <ng-container matColumnDef="StFullName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Student Full Name</mat-header-cell>
          <mat-cell *matCellDef="let studentClass">
            <p class="text-truncate">{{ studentClass.StFullName }}</p>
          </mat-cell>
        </ng-container>

        <!-- Gender Column -->
        <ng-container matColumnDef="StGender">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Gender</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ studentClass.StGender }}</p>
          </mat-cell>
        </ng-container>

        <!-- DateOfBirth Column -->
        <ng-container matColumnDef="DateOfBirth">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Date Of Birth</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">
              {{ studentClass.DateOfBirth | date: Constants.ShortDateFormat }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- StMobile2 Column -->
        <ng-container matColumnDef="StMobile2">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Whatsapp number</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ studentClass.StMobile2 }}</p>
          </mat-cell>
        </ng-container>

        <!-- StEmailid Column -->
        <ng-container matColumnDef="StEmailid">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Email</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ studentClass.StEmailid }}</p>
          </mat-cell>
        </ng-container>

        <!-- StMobile Column -->
        <ng-container matColumnDef="StMobile">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Phone number</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ studentClass.StMobile }}</p>
          </mat-cell>
        </ng-container>

        <!-- FatherName Column -->
        <ng-container matColumnDef="FatherName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Father Name</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ studentClass.FatherName }}</p>
          </mat-cell>
        </ng-container>

        <!-- MotherName Column -->
        <ng-container matColumnDef="MotherName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Mother Name</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ studentClass.MotherName }}</p>
          </mat-cell>
        </ng-container>

        <!-- Sector Column -->
        <ng-container matColumnDef="Sector">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Sector</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ studentClass.Sector }}</p>
          </mat-cell>
        </ng-container>

        <!-- JobRole Column -->
        <ng-container matColumnDef="JobRole">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Job Role</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ studentClass.JobRole }}</p>
          </mat-cell>
        </ng-container>

        <!-- ClassName Column -->
        <ng-container matColumnDef="ClassName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md >Student Class</mat-header-cell>
          <mat-cell *matCellDef="let studentClass">
            <p class="text-truncate">{{ studentClass.ClassName }}</p>
          </mat-cell>
        </ng-container>

        <!-- Stream Column -->
        <ng-container matColumnDef="Stream">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Stream</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ studentClass.Stream }}</p>
          </mat-cell>
        </ng-container>

        <!-- DevicesAvailable Column -->
        <ng-container matColumnDef="DevicesAvailable">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Devices Available at Student home</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ studentClass.DevicesAvailable }}</p>
          </mat-cell>
        </ng-container>

        <!-- Remarks Column -->
        <ng-container matColumnDef="Remarks">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>VT Remarks for student</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ studentClass.Remarks }}</p>
          </mat-cell>
        </ng-container>

        <!-- SchoolCategoryId Column -->
        <ng-container matColumnDef="SchoolCategoryId">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student School Category</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ studentClass.SchoolCategoryId }}</p>
          </mat-cell>
        </ng-container>

        <!-- SchoolManagementId Column -->
        <ng-container matColumnDef="SchoolManagementId">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student School Management</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ studentClass.SchoolManagementId }}</p>
          </mat-cell>
        </ng-container>

        <!-- StudentRollNumber Column -->
        <ng-container matColumnDef="StudentRollNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Roll Number</mat-header-cell>
          <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ studentClass.StudentRollNumber }}</p>
          </mat-cell>
        </ng-container>

        <!-- SchoolName Column -->
        <ng-container matColumnDef="SchoolName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Student School Name</mat-header-cell>
          <mat-cell *matCellDef="let studentClass">
            <p class="text-truncate">{{ studentClass.SchoolName }}</p>
          </mat-cell>
        </ng-container>

        <!-- Employer Form start -->

        <!-- EmStateCode Column -->
        <ng-container matColumnDef="EmStateCode">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Employer State</mat-header-cell>
          <mat-cell *matCellDef="let employer">
            <p class="text-truncate">{{ employer.EmStateCode }}</p>
          </mat-cell>
        </ng-container>

        <!-- EmDistrictCode Column -->
        <ng-container matColumnDef="EmDistrictCode">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Employer District</mat-header-cell>
          <mat-cell *matCellDef="let employer" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employer.EmDistrictCode }}</p>
          </mat-cell>
        </ng-container>

        <!-- EmBlockName Column -->
        <ng-container matColumnDef="EmBlockName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Employer Block</mat-header-cell>
          <mat-cell *matCellDef="let employer" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employer.EmBlockName }}</p>
          </mat-cell>
        </ng-container>

        <!-- Address Column -->
        <ng-container matColumnDef="Address">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Employer Address</mat-header-cell>
          <mat-cell *matCellDef="let employer" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employer.Address }}</p>
          </mat-cell>
        </ng-container>

        <!-- City Column -->
        <ng-container matColumnDef="EmCity">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Employer City</mat-header-cell>
          <mat-cell *matCellDef="let employer" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employer.EmCity }}</p>
          </mat-cell>
        </ng-container>

        <!-- Pincode Column -->
        <ng-container matColumnDef="EmPincode">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Employer Pincode</mat-header-cell>
          <mat-cell *matCellDef="let employer" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employer.EmPincode }}</p>
          </mat-cell>
        </ng-container>

        <!-- EmployerName Column -->
        <ng-container matColumnDef="EmployerName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Name of employer/shop/organization/company</mat-header-cell>
          <mat-cell *matCellDef="let employer" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employer.EmployerName }}</p>
          </mat-cell>
        </ng-container>

        <!-- BusinessType Column -->
        <ng-container matColumnDef="BusinessType">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Employer Business Type</mat-header-cell>
          <mat-cell *matCellDef="let employer" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employer.BusinessType }}</p>
          </mat-cell>
        </ng-container>

        <!-- EmInternshipType Column -->
        <ng-container matColumnDef="EmInternshipType">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Employer Internship Type</mat-header-cell>
          <mat-cell *matCellDef="let employer" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employer.EmInternshipType }}</p>
          </mat-cell>
        </ng-container>

        <!-- EmployeeCount Column -->
        <ng-container matColumnDef="EmployeeCount">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Employee count</mat-header-cell>
          <mat-cell *matCellDef="let employer" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employer.EmployeeCount }}</p>
          </mat-cell>
        </ng-container>

        <!-- Contact1 Column -->
        <ng-container matColumnDef="ContactFirst">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Employer First name</mat-header-cell>
          <mat-cell *matCellDef="let employer" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employer.ContactFirst }}</p>
          </mat-cell>
        </ng-container>

        <!-- Contact1 Column -->
        <ng-container matColumnDef="ContactMiddle">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Employer Middle name</mat-header-cell>
          <mat-cell *matCellDef="let employer" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employer.ContactMiddle }}</p>
          </mat-cell>
        </ng-container>

        <!-- Contact1 Column -->
        <ng-container matColumnDef="ContactLast">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Employer Last name</mat-header-cell>
          <mat-cell *matCellDef="let employer" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employer.ContactLast }}</p>
          </mat-cell>
        </ng-container>

        <!-- YearofEstablishment Column -->
        <ng-container matColumnDef="YearofEstablishment">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Employer Year of Establishment</mat-header-cell>
          <mat-cell *matCellDef="let employer" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employer.YearofEstablishment }}</p>
          </mat-cell>
        </ng-container>

        <!-- Mobile1 Column -->
        <ng-container matColumnDef="Mobile1">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Employer Mobile number</mat-header-cell>
          <mat-cell *matCellDef="let employer" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employer.Mobile1 }}</p>
          </mat-cell>
        </ng-container>

        <!-- Mobile2 Column -->
        <ng-container matColumnDef="EmMobile2">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Employer Whatsapp number</mat-header-cell>
          <mat-cell *matCellDef="let employer" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employer.EmMobile2 }}</p>
          </mat-cell>
        </ng-container>

        <!-- EmailId1 Column -->
        <ng-container matColumnDef="EmailId1">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Employer Email ID</mat-header-cell>
          <mat-cell *matCellDef="let employer" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employer.EmailId1 }}</p>
          </mat-cell>
        </ng-container>

        <!-- SectorId Column -->
        <ng-container matColumnDef="SectorId">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Employer sector</mat-header-cell>
          <mat-cell *matCellDef="let employer" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employer.SectorId }}</p>
          </mat-cell>
        </ng-container>

        <!-- 28IsConsentApproved Column -->
        <ng-container matColumnDef="IsConsentApproved">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Consent Form signed by Student and Parents/Local Guardian?</mat-header-cell>
          <mat-cell *matCellDef="let internship" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ internship.IsConsentApproved }}</p>
          </mat-cell>
        </ng-container>

        <!-- 26WorkingDays Column -->
        <ng-container matColumnDef="WorkingDays">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Total Days completed in Internship <br />(Internship Progress form)</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.WorkingDays }}</p>
          </mat-cell>
        </ng-container>

        <!-- 27HoursPerDay -->
        <ng-container matColumnDef="HoursPerDay">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Hours Per Day <br />(Internship Progress form)</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.HoursPerDay }}</p>
          </mat-cell>
        </ng-container>

        <!-- 36InternshipImage1 Column -->
        <ng-container matColumnDef="InternshipPhoto1">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Internship Image1</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <a target="_blank" *ngIf="internship.InternshipPhoto1" href="{{ internship.InternshipPhoto1 }}" >{{ internship.InternshipPhoto1 }}</a>
            <p *ngIf="!internship.InternshipPhoto1" class="text-truncate">
              No Image
            </p>
          </mat-cell>
        </ng-container>

        <!--37InternshipImage2 Column -->
        <ng-container matColumnDef="InternshipPhoto2">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Internship Image2</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <a target="_blank" *ngIf="internship.InternshipPhoto2" href="{{ internship.InternshipPhoto2 }}" >{{ internship.InternshipPhoto2 }}</a>
            <p *ngIf="!internship.InternshipPhoto2" class="text-truncate">
              No Image
            </p>
          </mat-cell>
        </ng-container>

        <!-- 38InternshipImage3 Column -->
        <ng-container matColumnDef="InternshipPhoto3">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Internship Image3</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <a target="_blank" *ngIf="internship.InternshipPhoto3" href="{{ internship.InternshipPhoto3 }}" >{{ internship.InternshipPhoto3 }}</a>
            <p *ngIf="!internship.InternshipPhoto3" class="text-truncate">
              No Image
            </p>
          </mat-cell>
        </ng-container>

        <!-- 39InternshipImage4 Column -->
        <ng-container matColumnDef="InternshipPhoto4">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Internship Image4</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <a target="_blank" *ngIf="internship.InternshipPhoto4" href="{{ internship.InternshipPhoto4 }}" >{{ internship.InternshipPhoto4 }}</a>
            <p *ngIf="!internship.InternshipPhoto4" class="text-truncate">
              No Image
            </p>
          </mat-cell>
        </ng-container>

        <!-- 33ReportCreatedByStudent Column -->
        <ng-container matColumnDef="ReportCreatedByStudent">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Internship Report created by student?</mat-header-cell>
          <mat-cell *matCellDef="let internship" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ internship.ReportCreatedByStudent }}</p>
          </mat-cell>
        </ng-container>

        <!-- 30AttendanceMaintained Column -->
        <ng-container matColumnDef="AttendanceMaintained">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Attendance Sheet maintained?</mat-header-cell>
          <mat-cell *matCellDef="let internship" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ internship.AttendanceMaintained }}</p>
          </mat-cell>
        </ng-container>

        <!-- 29LetterSignedByEmployer Column -->
        <ng-container matColumnDef="LetterSignedByEmployer">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Letter of Confirmation Signed by Employer & HoS?</mat-header-cell>
          <mat-cell *matCellDef="let internship" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ internship.LetterSignedByEmployer }}</p>
          </mat-cell>
        </ng-container>

        <!-- 31StudentCompletedInternship Column -->
        <ng-container matColumnDef="StudentCompletedInternship">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Completed Internship?</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">
              {{ internship.StudentCompletedInternship }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- 31DailyDairyMaintained Column -->
        <ng-container matColumnDef="DailyDairyMaintained">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Daily Work Register maintained?</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.DailyDairyMaintained }}</p>
          </mat-cell>
        </ng-container>

        <!-- 31InternshipType Column -->
        <ng-container matColumnDef="InternshipType">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Internship Type <br />(Internship Progress form)</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.InternshipType }}</p>
          </mat-cell>
        </ng-container>

        <!-- SafteyMesuresTaken Column -->
        <ng-container matColumnDef="SafteyMesuresTaken">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Saftey Mesures Taken? <br />(Internship Progress form)</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.SafteyMesuresTaken }}</p>
          </mat-cell>
        </ng-container>

        <!-- OJTCertificatePresent Column -->
        <ng-container matColumnDef="OJTCertificatePresent">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Internship Certificate given by Employer to student? <br />(Internship progress Form)</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.OJTCertificatePresent }}</p>
          </mat-cell>
        </ng-container>

        <!-- ScStateCode Column -->
        <ng-container matColumnDef="ScStateCode">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>School State</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.ScStateCode }}</p>
          </mat-cell>
        </ng-container>

        <!-- ScDistrictCode Column -->
        <ng-container matColumnDef="ScDistrictCode">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>School District</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.ScDistrictCode }}</p>
          </mat-cell>
        </ng-container>

        <!-- ScBlockName Column -->
        <ng-container matColumnDef="ScBlockName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>School Block</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.ScBlockName }}</p>
          </mat-cell>
        </ng-container>

        <!-- ScVillage Column -->
        <ng-container matColumnDef="ScVillage">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>School Village</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.ScVillage }}</p>
          </mat-cell>
        </ng-container>

        <!-- ScPincode Column -->
        <ng-container matColumnDef="ScPincode">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>School Pincode</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.ScPincode }}</p>
          </mat-cell>
        </ng-container>

        <!-- VTFullName Column -->
        <ng-container matColumnDef="VTFullName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>VT FullName</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.VTFullName }}</p>
          </mat-cell>
        </ng-container>

        <!-- VTMobile Column -->
        <ng-container matColumnDef="VTMobile">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>VT Mobile</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.VTMobile }}</p>
          </mat-cell>
        </ng-container>

        <!-- VTEmail Column -->
        <ng-container matColumnDef="VTEmail">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>VT Email</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.VTEmail }}</p>
          </mat-cell>
        </ng-container>

        <!-- VTGender Column -->
        <ng-container matColumnDef="VTGender">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>VT Gender</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.VTGender }}</p>
          </mat-cell>
        </ng-container>

        <!-- VTSector Column -->
        <ng-container matColumnDef="VTSector">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>VT Sector</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.VTSector }}</p>
          </mat-cell>
        </ng-container>

        <!-- VTPName Column -->
        <ng-container matColumnDef="VTPName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>VTP Name</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.VTPName }}</p>
          </mat-cell>
        </ng-container>

        <!-- VCName Column -->
        <ng-container matColumnDef="VCName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>VC Name</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.VCName }}</p>
          </mat-cell>
        </ng-container>

        <!-- StudentFeedback Column -->
        <ng-container matColumnDef="StudentFeedback">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Feedback</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.StudentFeedback }}</p>
          </mat-cell>
        </ng-container>

        <!-- PrepareInternshipReport Column -->
        <ng-container matColumnDef="PrepareInternshipReport">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Internship Project Report Available?t</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">
              {{ internship.PrepareInternshipReport }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- ProjectReportPhoto Column -->
        <ng-container matColumnDef="ProjectReportPhoto">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Project Report Photo</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <a target="_blank" *ngIf="internship.ProjectReportPhoto" href="{{ internship.ProjectReportPhoto }}" >{{ internship.ProjectReportPhoto }}</a>
            <p *ngIf="!internship.ProjectReportPhoto" class="text-truncate">
              No Image
            </p>
          </mat-cell>
        </ng-container>

        <!-- DailydairyPhotos Column -->
        <ng-container matColumnDef="DailydairyPhotos">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Daily Dairy Photos</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="" [innerHTML]="internship.DailydairyPhotos">
            </p>
          </mat-cell>
        </ng-container>

        <!-- TotalInterns Column -->
        <ng-container matColumnDef="TotalInterns">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Total Interns</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.TotalInterns }}</p>
          </mat-cell>
        </ng-container>

        <!-- TotalDayCompleted Column -->
        <ng-container matColumnDef="TotalDayCompleted">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Total Days completed in Internship <br />(Daily Dairy)</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.TotalDayCompleted }}</p>
          </mat-cell>
        </ng-container>

        <!-- TotalHoursWorked Column -->
        <ng-container matColumnDef="TotalHoursWorked">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Total Hours Covered <br />(Daily Dairy)</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.TotalHoursWorked }}</p>
          </mat-cell>
        </ng-container>

        <!-- StudentsEmployerCount Column -->
        <ng-container matColumnDef="StudentsEmployerCount">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>No of Students enrolled with employer</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.StudentsEmployerCount }}</p>
          </mat-cell>
        </ng-container>

        <!-- SupervisorName Column -->
        <ng-container matColumnDef="SupervisorName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Name of Supervisor at workplace <br />(Feedback Form)</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">{{ internship.SupervisorName }}</p>
          </mat-cell>
        </ng-container>

        <!-- ParticipateInOrientation Column -->
        <ng-container matColumnDef="ParticipateInOrientation">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Attended Orientation? <br />(Feedback Form)</mat-header-cell>
          <mat-cell *matCellDef="let internship">
            <p class="text-truncate">
              {{ internship.ParticipateInOrientation }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- Opt_in Column -->
        <ng-container matColumnDef="Opt_in">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide>Opt_in
            </mat-header-cell>
            <mat-cell *matCellDef="let internship" fxHide>
                <p class="text-truncate">{{internship.Opt_in | date: Constants.ImportDateFormat }}</p>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let vtInternshipReport; columns: displayedColumns" class="vt-internship-report" matRipple>
        </mat-row>
      </mat-table>

      <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[1, 5, 10, 25, 100]">
      </mat-paginator>
    </div>
    <!-- / CONTENT CARD -->
  </div>
  <!-- / CENTER -->
</div>

