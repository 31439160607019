<div id="role-transaction" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/role-transactions'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ roleTransactionModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New Role Transaction
                    </div>
                    <div class="subtitle secondary-text">
                        <span>Role Transaction Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-role-transaction-button" [disabled]="roleTransactionForm.invalid"
                *ngIf="PageRights.ActionType ==='new'" (click)="saveOrUpdateRoleTransactionDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-role-transaction-button" 
                *ngIf="PageRights.ActionType ==='edit'" (click)="saveOrUpdateRoleTransactionDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="roleTransactionForm" [formGroup]="roleTransactionForm" class="role-transaction w-100-p" fxLayout="column" fxFlex>
                    <!-- <mat-tab label="Basic Info"> -->
                    <div class="tab-content p-24" fusePerfectScrollbar>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Select Role</mat-label>

                                <mat-select formControlName="RoleId" appearance="outline">
                                    <mat-option [value]="roleItem.Id"
                                        *ngFor="let roleItem of roleList">
                                        {{ roleItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="roleTransactionForm.controls.RoleId.invalid">{{getErrorMessage(roleTransactionForm, 'RoleId')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Select Transaction</mat-label>

                                <mat-select formControlName="TransactionId" appearance="outline">
                                    <mat-option [value]="transactionItem.Id"
                                        *ngFor="let transactionItem of transactionList">
                                        {{ transactionItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="roleTransactionForm.controls.TransactionId.invalid">{{getErrorMessage(roleTransactionForm, 'TransactionId')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <span class="checkbox-space">Rights</span>
                            <mat-checkbox matInput formControlName="Rights" [disabled]="PageRights.IsReadOnly">
                            </mat-checkbox>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <span class="checkbox-space">Can Add</span>
                            <mat-checkbox matInput formControlName="CanAdd" [disabled]="PageRights.IsReadOnly">
                            </mat-checkbox>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <span class="checkbox-space">Can Edit</span>
                            <mat-checkbox matInput formControlName="CanEdit" [disabled]="PageRights.IsReadOnly">
                            </mat-checkbox>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <span class="checkbox-space">Can Delete</span>
                            <mat-checkbox matInput formControlName="CanDelete" [disabled]="PageRights.IsReadOnly">
                            </mat-checkbox>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <span class="checkbox-space">Can View</span>
                            <mat-checkbox matInput formControlName="CanView" [disabled]="PageRights.IsReadOnly">
                            </mat-checkbox>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <span class="checkbox-space">Can Export</span>
                            <mat-checkbox matInput formControlName="CanExport" [disabled]="PageRights.IsReadOnly">
                            </mat-checkbox>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <span class="checkbox-space">List View</span>
                            <mat-checkbox matInput formControlName="ListView" [disabled]="PageRights.IsReadOnly">
                            </mat-checkbox>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <span class="checkbox-space">Basic View</span>
                            <mat-checkbox matInput formControlName="BasicView" [disabled]="PageRights.IsReadOnly">
                            </mat-checkbox>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <span class="checkbox-space">Detail View</span>
                            <mat-checkbox matInput formControlName="DetailView" [disabled]="PageRights.IsReadOnly">
                            </mat-checkbox>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <span class="checkbox-space">Is Public</span>
                            <mat-checkbox matInput formControlName="IsPublic" [disabled]="PageRights.IsReadOnly">
                            </mat-checkbox>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Remarks</mat-label>
                                <input matInput name="remarks" formControlName="Remarks">
                                <mat-error *ngIf="roleTransactionForm.controls.Remarks.invalid">{{getErrorMessage(roleTransactionForm, 'Remarks')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <span class="checkbox-space">Is Active?</span>
                            <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                            </mat-checkbox>
                        </div>
                    </div>
                    <!-- </mat-tab>
                    </mat-tab-group> -->
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>
