import { UserModel } from "./user.model";

export class SearchFilterModel {
    UserId: string;
    UserTypeId: string;
    Name: string;
    CharBy: string;
    Filter: any;
    SortOrder: string;
    PageIndex: number;
    PageSize: number;
    IgnoreCriteria: boolean;

    constructor(userModel?: UserModel) {

        this.UserId = userModel.UserId || '';
        this.UserTypeId = userModel.UserTypeId || '';
        this.Name = null;
        this.CharBy = null;
        this.Filter = {};
        this.SortOrder = 'asc';
        this.PageIndex = 1;
        this.PageSize = 10000;
    }
}
