import { Component, OnInit, NgZone, ViewEncapsulation } from "@angular/core";
import { CommonService } from "app/services/common.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BaseListComponent } from "app/common/base-list/base.list.component";
import { fuseAnimations } from "@fuse/animations";
import { ReportService } from "app/reports/report.service";
import { VTReportingInternshipReportModel } from "./vt-reporting-internship.model";
import { DropdownModel } from "app/models/dropdown.model";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "data-list-view",
  templateUrl: "./vt-reporting-internship.component.html",
  styleUrls: ["./vt-reporting-internship.component.scss"],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class VTReportingInternshipReportComponent
  extends BaseListComponent<VTReportingInternshipReportModel>
  implements OnInit {
  vtReportingInternshipForm: FormGroup;

  academicyearList: [DropdownModel];
  divisionList: [DropdownModel];
  districtList: [DropdownModel];
  stateList: [DropdownModel];
  sectorList: [DropdownModel];
  jobRoleList: [DropdownModel];
  vtpList: [DropdownModel];
  classList: [DropdownModel];
  monthList: [DropdownModel];
  schoolManagementList: [DropdownModel];
  currentUserRole: string;
  currentUserState: string;
  currentDivision: string;
  currentDistrict: string;

  currentAcademicYearId: string;
  isShowFilterContainer = false;
  

  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    public formBuilder: FormBuilder,
    private reportService: ReportService
  ) {
    super(commonService, router, routeParams, snackBar, zone);
  }

  ngOnInit(): void {
    this.reportService.GetDropdownForReports(this.UserModel).subscribe((results) => {
      if (results[0].Success) {
        this.academicyearList = results[0].Results;
      }

      if (results[1].Success) {
        this.divisionList = results[1].Results;
      }

      if (results[2].Success) {
        this.sectorList = results[2].Results;
      }

      if (results[3].Success) {
        this.vtpList = results[3].Results;
      }

      if (results[4].Success) {
        this.classList = results[4].Results;
      }

      if (results[5].Success) {
        this.monthList = results[5].Results;
      }

      if (results[6].Success) {
        this.schoolManagementList = results[6].Results;
      }

      if (results[7].Success) {
        this.stateList = results[7].Results;
      }

      this.currentUserRole = this.UserModel.RoleCode;


      let currentYearItem = this.academicyearList.find(
        (ay) => ay.IsSelected == true
      );
      if (currentYearItem != null) {
        this.currentAcademicYearId = currentYearItem.Id;
        this.vtReportingInternshipForm
          .get("AcademicYearId")
          .setValue(this.currentAcademicYearId);

        if (this.UserModel.RoleCode === "DivEO") {
          this.vtReportingInternshipForm.controls["DivisionId"].setValue(
            this.UserModel.DivisionId
          );
          this.vtReportingInternshipForm.controls["DivisionId"].disable();

          this.onChangeDivision(this.UserModel.DivisionId).then(
            (response) => {
              this.getVTReportingInternshipReports();
            }
          );
        } else if (
          this.UserModel.RoleCode === "DisEO" ||
          this.UserModel.RoleCode === "DisRP"
        ) {
          this.vtReportingInternshipForm.controls["DivisionId"].setValue(
            this.UserModel.DivisionId
          );
          this.vtReportingInternshipForm.controls["DivisionId"].disable();

          this.vtReportingInternshipForm.controls["DistrictId"].setValue(
            this.UserModel.DistrictId
          );
          this.vtReportingInternshipForm.controls["DistrictId"].disable();

          this.onChangeDivision(this.UserModel.DivisionId).then(
            (response) => {
              this.getVTReportingInternshipReports();
            }
          );
        } else if (
          this.UserModel.RoleCode === "BEO" ||
          this.UserModel.RoleCode === "BRP"
        ) {
          this.vtReportingInternshipForm.controls["DivisionId"].setValue(
            this.UserModel.DivisionId
          );
          this.vtReportingInternshipForm.controls["DivisionId"].disable();

          this.vtReportingInternshipForm.controls["DistrictId"].setValue(
            this.UserModel.DistrictId
          );
          this.vtReportingInternshipForm.controls["DistrictId"].disable();

          this.onChangeDivision(this.UserModel.DivisionId).then(
            (response) => {
              this.getVTReportingInternshipReports();
            }
          );
        } else {
          this.getVTReportingInternshipReports();
        }
      }
    });

    this.vtReportingInternshipForm = this.createVTReportingInternshipForm();
  }

  resetReportFilters(): void {
    this.vtReportingInternshipForm.reset();
    this.vtReportingInternshipForm.get("AcademicYearId").setValue(this.currentAcademicYearId);
    this.vtReportingInternshipForm.controls["DivisionId"].setValue(this.UserModel.DivisionId);
    this.vtReportingInternshipForm.controls["DistrictId"].setValue(this.UserModel.DistrictId);

    this.getVTReportingInternshipReports();
  }

  onChangeState(stateId: any) {
    this.commonService.GetMasterDataByType({ DataType: "Divisions", UserId: this.Constants.DefaultStateId, ParentId: stateId, SelectTitle: "Division", })
      .subscribe((response: any) => {
        this.divisionList = response.Results;
      });
  }

  onChangeDivision(divisionId: string): Promise<any> {
    let promise = new Promise((resolve, reject) => {
      this.commonService.GetMasterDataByType({ DataType: "Districts", UserId: this.Constants.DefaultStateId, ParentId: divisionId, SelectTitle: "District", })
        .subscribe(
          (response: any) => {
            this.districtList = response.Results;
            resolve(response.Results);
          },
          (err) => {
            reject(err);
          }
        );
    });

    return promise;
  }

  onChangeSector(sectorId: string): void {
    this.commonService.GetMasterDataByType({ DataType: "JobRoles", ParentId: sectorId, SelectTitle: "Job Role", }).subscribe(
      (response: any) => {
        this.jobRoleList = response.Results;
      });
  }

  //Create VCReportingInternship form and returns {FormGroup}
  createVTReportingInternshipForm(): FormGroup {
    return this.formBuilder.group({
      AcademicYearId: new FormControl(),
      MonthId: new FormControl(),
      DivisionId: new FormControl(),
      DistrictId: new FormControl(),
      SectorId: new FormControl(),
      JobRoleId: new FormControl(),
      ClassId: new FormControl(),
      VTPId: new FormControl(),
      SchoolManagementId: new FormControl(),
      State: new FormControl(),
    });
  }

  getVTReportingInternshipReports(): void {


    if (this.UserModel.RoleCode == "SUR") {
      this.currentUserState = this.vtReportingInternshipForm.get("State").value;
      this.currentDivision = this.vtReportingInternshipForm.get("DivisionId").value;
      this.currentDistrict = this.vtReportingInternshipForm.get("DistrictId").value;
      
    }

    else {
      this.currentUserState = this.UserModel.StateId;
      this.currentDivision = this.vtReportingInternshipForm.get("DivisionId").value;
      this.currentDistrict = this.vtReportingInternshipForm.get("DistrictId").value;
    }
    console.log(this.UserModel.RoleCode);

    var reportParams = {
      UserId: this.UserModel.LoginId,
      AcademicYearId: this.vtReportingInternshipForm.get("AcademicYearId").value,
      // DivisionId: this.vtReportingInternshipForm.get("DivisionId").value,
      // DistrictId: this.vtReportingInternshipForm.get("DistrictId").value,
      SectorId: this.vtReportingInternshipForm.get("SectorId").value,
      JobRoleId: this.vtReportingInternshipForm.get("JobRoleId").value,
      VTPId: this.vtReportingInternshipForm.get("VTPId").value,
      ClassId: this.vtReportingInternshipForm.get("ClassId").value,
      MonthId: this.vtReportingInternshipForm.get("MonthId").value,
      SchoolManagementId: this.vtReportingInternshipForm.get("SchoolManagementId").value,
      State: this.currentUserState,
      DivisionId:this.currentDivision,
      DistrictId:this.currentDistrict,
      
    };

    this.reportService
      .GetVTReportingInternshipReportsByCriteria(reportParams)
      .subscribe(
        (response) => {
          this.displayedColumns = [


            // "SrNo",
            'SystemStudentUniqueID',
            "StateName",
            "DivisionId",
            "DistrictCode",
            "BlockId",
            "SchoolUniqueId",
            "Udise",
            "AcademicYear",
            "StFirstName",
            "StMiddleName",
            "StLastName",
            "StFullName",
            "StGender",
            "DateOfBirth",
            "StMobile2",
            "StMobile",
            "FatherName",
            "MotherName",
            "Sector",
            "JobRole",
            "ClassName",
            "Stream",
            "StEmailid",
            "DevicesAvailable",
            "SchoolCategoryId",
            "SchoolManagementId",
            "StudentRollNumber",
            "SchoolName",
            "Remarks",

            "BusinessType",
            "EmInternshipType",
            "EmployerName",
            "YearofEstablishment",
            "ContactFirst",
            "ContactMiddle",
            "ContactLast",
            "Address",
            "EmStateCode",
            "EmDistrictCode",
            "EmBlockName",
            "EmCity",
            "EmPincode",
            "SectorId",
            "Mobile1",
            "EmMobile2",
            "EmailId1",
            "EmployeeCount",

            "IsConsentApproved",
            "DateOfCommencement",
            "DateOfCompletion",
            "HoursPerDay",
            "InternshipPhoto1",
            "InternshipPhoto2",
            "InternshipPhoto3",
            "InternshipPhoto4",
            "ReportCreatedByStudent",
            "AttendanceMaintained",
            "LetterSignedByEmployer",
            "StudentCompletedInternship",
            "DailyDairyMaintained",

            "InternshipType",
            "WorkingDays",
            "SafteyMesuresTaken",

            "ScStateCode",
            "ScDistrictCode",
            "ScBlockName",
            "ScVillage",
            "ScPincode",

            "VTFullName",
            "VTMobile",
            "VTEmail",
            "VTGender",
            "VTSector",
            "VTPName",
            "VCName",

            "StudentFeedback",
            "PrepareInternshipReport",
            "ProjectReportPhoto",
            "DailydairyPhotos",

            "TotalDayCompleted",
            "TotalHoursWorked",
            "StudentsEmployerCount",

            "SupervisorName",
            "ParticipateInOrientation",
            "OJTCertificatePresent",
            "Opt_in",
          ];

          if (this.UserModel.StateId == 'DL') {
            const schoolUniqueIdIndex = this.displayedColumns.indexOf('SchoolUniqueId');
            if (schoolUniqueIdIndex === -1) {
              this.displayedColumns.splice(5, 0, 'SchoolUniqueId');
            }
          } else {
            const index = this.displayedColumns.indexOf('SchoolUniqueId');
            if (index !== -1) {
              this.displayedColumns.splice(index, 1);
            }
          }

          this.tableDataSource.data = response.Results;
          this.tableDataSource.sort = this.ListSort;
          this.tableDataSource.paginator = this.ListPaginator;
          this.tableDataSource.filteredData = this.tableDataSource.data;

          this.zone.run(() => {
            if (this.tableDataSource.data.length == 0) {
              this.showNoDataFoundSnackBar();
            }
          });
          this.IsLoading = false;
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
