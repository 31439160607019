import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';

@Injectable()
export class PhaseService {
    constructor(private http: BaseService) { }

    getPhases(): Observable<any> {
        return this.http
            .HttpGet(this.http.Services.Phase.GetAll)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetAllByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.Phase.GetAllByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    getPhaseById(phaseId: string) {
        let requestParams = {
            DataId: phaseId
        };

        return this.http
            .HttpPost(this.http.Services.Phase.GetById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    createOrUpdatePhase(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Phase.CreateOrUpdate, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    deletePhaseById(phaseId: string) {
        var phaseParams = {
            DataId: phaseId
        };

        return this.http
            .HttpPost(this.http.Services.Phase.Delete, phaseParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
}
