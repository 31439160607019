export const locale = {
    lang: 'gj',
    data: {
        'VCSCHOOLSECTOR': {
            'VC_SCHOOL_SECTORS': 'વીસી શાળા ક્ષેત્ર',
            'ADD_NEW_VC_SCHOOL_SECTORS': 'ઉમેરો',
            'SEARCH_FOR_A_VC_SCHOOL_SECTORS': 'વીસી શાળા ક્ષેત્ર માટે શોધ કરો',
        }
    }
};

