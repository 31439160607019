export const locale = {
    lang: 'en',
    data: {
        'SCHOOL': {
            'SCHOOLS': 'SCHOOLS',
            'ADD_NEW_SCHOOL': 'ADD',
            'SEARCH_FOR_A_SCHOOL': 'Search for a schools',
        }
    }
};

