<div id="account" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/users'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ accountModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New User
                    </div>
                    <div class="subtitle secondary-text">
                        <span>User Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-account-button" *ngIf="PageRights.ActionType ==='new'"
                (click)="saveOrUpdateAccountDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-account-button" *ngIf="PageRights.ActionType ==='edit'"
                (click)="saveOrUpdateAccountDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="accountForm" [formGroup]="accountForm" class="account w-100-p" fxLayout="column" fxFlex>
                    <div class="tab-content p-24" fusePerfectScrollbar>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Login Id</mat-label>
                                <input matInput name="loginId" formControlName="LoginId" required>
                                <mat-error *ngIf="accountForm.controls.LoginId.invalid">
                                    {{getErrorMessage(accountForm, 'LoginId')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                *ngIf="PageRights.ActionType == 'new'">
                                <mat-label>Password</mat-label>
                                <input matInput name="password" formControlName="Password" type="password" required>
                                <mat-error *ngIf="accountForm.controls.Password.invalid">
                                    {{getErrorMessage(accountForm, 'Password')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>First Name</mat-label>
                                <input matInput name="firstName" formControlName="FirstName" required>
                                <mat-error *ngIf="accountForm.controls.FirstName.invalid">
                                    {{getErrorMessage(accountForm, 'FirstName')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Last Name</mat-label>
                                <input matInput name="lastName" formControlName="LastName" required>
                                <mat-error *ngIf="accountForm.controls.LastName.invalid">
                                    {{getErrorMessage(accountForm, 'LastName')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>User Name</mat-label>
                                <input matInput name="userName" formControlName="UserName" required>
                                <mat-error *ngIf="accountForm.controls.UserName.invalid">
                                    {{getErrorMessage(accountForm, 'UserName')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Select Role</mat-label>

                                <mat-select formControlName="RoleId" appearance="outline"
                                    [disabled]="PageRights.IsReadOnly" required
                                    (selectionChange)="onChangeRoleType($event.value)">
                                    <mat-option [value]="roleItem.Id" *ngFor="let roleItem of roleList">
                                        {{ roleItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="accountForm.controls.RoleId.invalid">
                                    {{getErrorMessage(accountForm, 'RoleId')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Email Id</mat-label>
                                <input matInput name="emailId" formControlName="EmailId" required>
                                <mat-error *ngIf="accountForm.controls.EmailId.invalid">
                                    {{getErrorMessage(accountForm, 'EmailId')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Mobile</mat-label>
                                <input matInput name="mobile" formControlName="Mobile" required digitOnly minlength="10"
                                    maxlength="10">
                                <mat-error *ngIf="accountForm.controls.Mobile.invalid">
                                    {{getErrorMessage(accountForm, 'Mobile')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            
                            <mat-form-field fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100" appearance="outline">
                                <mat-label>State</mat-label>

                                <mat-select formControlName="StateId" name="stateId" [disabled]="PageRights.IsReadOnly"
                                    (selectionChange)="onChangeState($event.value)" required>
                                    <mat-option [value]="stateItem.Id" *ngFor="let stateItem of stateList">
                                        {{ stateItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="accountForm.controls.StateId.invalid">
                                    {{getErrorMessage(accountForm, 'StateId')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100" appearance="outline">
                                <mat-label>Division</mat-label>

                                <mat-select formControlName="DivisionId" name="divisionId"
                                    [disabled]="PageRights.IsReadOnly"
                                    (selectionChange)="onChangeDivision($event.value)">
                                    <mat-option [value]="divisionItem.Id" *ngFor="let divisionItem of divisionList">
                                        {{ divisionItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="accountForm.controls.DivisionId.invalid">
                                    {{getErrorMessage(accountForm, 'DivisionId')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100" appearance="outline">
                                <mat-label>District</mat-label>
                                <mat-select formControlName="DistrictId" name="districtId"
                                    [disabled]="PageRights.IsReadOnly">
                                    <mat-option *ngFor="let districtItem of districtList" [value]="districtItem.Id">
                                        {{districtItem.Name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="accountForm.controls.DistrictId.invalid">
                                    {{getErrorMessage(accountForm, 'DistrictId')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100" appearance="outline">
                                <mat-label>Block</mat-label>
                                <input matInput formControlName="BlockId" name="blockId">
                                <mat-error *ngIf="accountForm.controls.BlockId.invalid">
                                    {{getErrorMessage(accountForm, 'BlockId')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start" *ngIf="PageRights.ActionType =='edit'">
                            <span class="checkbox-space">Is Locked</span>
                            <mat-checkbox matInput formControlName="IsLocked" [disabled]="PageRights.IsReadOnly">
                            </mat-checkbox>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start" *ngIf="PageRights.ActionType =='edit'">
                            <span class="checkbox-space">Is Active?</span>
                            <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                            </mat-checkbox>
                        </div>
                    </div>
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>