// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  hmr: false,
  aws: false,
  lahi: true,
  gj: false,
  mh: false,
  jh: false,
  od: false,
  production: false,
  target: "lahi",
  email: "support.internship@lend-a-hand-india.org",
  version: "2.11.1.07",

  //For Local

  // ApiBaseUrl: "http://localhost:61246/LighthouseServices/",

  // ApiBaseUrl: "http://localhost:6345/LighthouseServices/",

  //For Production
  ApiBaseUrl: "https://basic.pathways.net.in/LighthouseServices/",


  //Build Project => ng build --prod --c/configuration=lahi  lahi | aws | gujarat | mh
  // ng build --prod --c/configuration=lahi
  // ng serve --c/configuration=lahi
  // node --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng serve --c=aws
  // node --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng build --prod --c=aws
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
