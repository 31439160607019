import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { UserModel } from "app/models/user.model";

@Injectable()
export class VTSchoolSectorService {
    constructor(private http: BaseService) { }

    getVTSchoolSectors(): Observable<any> {
        return this.http
            .HttpGet(this.http.Services.VTSchoolSector.GetAll)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetAllByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.VTSchoolSector.GetAllByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    getVTSchoolSectorById(vtSchoolSectorId: string) {
        let requestParams = {
            DataId: vtSchoolSectorId
        };

        return this.http
            .HttpPost(this.http.Services.VTSchoolSector.GetById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    createOrUpdateVTSchoolSector(formData: any) {
        return this.http
            .HttpPost(this.http.Services.VTSchoolSector.CreateOrUpdate, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    deleteVTSchoolSectorById(vtSchoolSectorId: string) {
        var vtSchoolSectorParams = {
            DataId: vtSchoolSectorId
        };

        return this.http
            .HttpPost(this.http.Services.VTSchoolSector.Delete, vtSchoolSectorParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    getDropdownforVTSchoolSector(userModel: UserModel): Observable<any[]> {
        let academicYearRequest = this.http.GetMasterDataByType({ DataType: 'AcademicYears', SelectTitle: 'Academic Year' });
        //let schoolRequest = this.http.GetMasterDataByType({ DataType: 'SchoolsByUserIdByState', userId: userModel.LoginId, ParentId: userModel.UserTypeId, SelectTitle: "School" });
        //let vtRequest = this.http.GetMasterDataByType({ DataType: 'VocationalTrainersByVC', ParentId: userModel.UserTypeId, SelectTitle: 'Vocational Trainer' });
        let stateRequest = this.http.GetMasterDataByType({ DataType: 'States', UserId: userModel.LoginId, ParentId: userModel.StateId, SelectTitle: 'States' });
        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        // return forkJoin([academicYearRequest, schoolRequest, vtRequest, stateRequest]);
        return forkJoin([academicYearRequest, stateRequest]);
    }

    getVocationalTrainerById(vtId: string) {
        var requestParams = {
            DataId: vtId
        };

        return this.http
            .HttpPost(this.http.Services.VocationalTrainer.GetById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }
}