import { FuseUtils } from '@fuse/utils';

export class DailydairyModel {


    DailydairyId: string;
    VTId: string;
    StudentId: string;
    WhatsappMobile: string;
    DateOfActivity: Date
    IsActivityPerformed: string;
    HoursWorked: string;
    ActivitiesPerformed: string;
    LearntToday: string;
    TookSafetyPrecautions: string;
    EmployerTookSafety: string;
    IsSafetyIssues: string;
    SafetyIssues: string;

    Attachment: any;
    Attachment2: any;

    ScreenshotFile: any;
    ScreenshotFile2: any;

    ImageUrlGCS: any;
    ImageUrlGCS2: any;

    RequestType: any;
    IsActive: boolean;

    constructor(dailydairyItem?: any) {
        dailydairyItem = dailydairyItem || {};

        this.DailydairyId = dailydairyItem.DailydairyId || FuseUtils.NewGuid();
        this.VTId = dailydairyItem.VTId || '';
        this.StudentId = dailydairyItem.StudentId || '';
        this.WhatsappMobile = dailydairyItem.WhatsappMobile || '';
        this.DateOfActivity = dailydairyItem.DateOfActivity || '';
        this.IsActivityPerformed = dailydairyItem.IsActivityPerformed || '';
        this.HoursWorked = dailydairyItem.HoursWorked || '';
        this.ActivitiesPerformed = dailydairyItem.ActivitiesPerformed || '';
        this.LearntToday = dailydairyItem.LearntToday || '';
        this.TookSafetyPrecautions = dailydairyItem.TookSafetyPrecautions || '';
        this.EmployerTookSafety = dailydairyItem.EmployerTookSafety || '';
        this.IsSafetyIssues = dailydairyItem.IsSafetyIssues || '';
        this.SafetyIssues = dailydairyItem.SafetyIssues || '';

        this.Attachment = dailydairyItem.Attachment || '';
        this.Attachment2 = dailydairyItem.Attachment2 || '';

        this.ScreenshotFile = dailydairyItem.ScreenshotFile || '';
        this.ScreenshotFile2 = dailydairyItem.ScreenshotFile2 || '';

        this.ImageUrlGCS = dailydairyItem.ImageUrlGCS || '';
        this.ImageUrlGCS2 = dailydairyItem.ImageUrlGCS2 || '';

        this.IsActive = dailydairyItem.IsActive || true;
        this.RequestType = 0; // New
    }
}
