<div id="vt-student-tracking-report" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    VT STUDENT TRACKING
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)"
                        placeholder="Search for a daily attendance tracking">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <a *ngIf="PageRights.IsExport" class="export-excel"
                    (click)="exporter.exportTable('xlsx', {fileName:'VTStudentTracking', sheet: 'VTDailyAttendanceTracking', Props: {Author: 'Lighthouse'}})">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->
        </div>
        <!-- / HEADER -->

        <!-- Filter Section -->
        <div class="filter-content-card">
            <div class="filter-content">
                <form name="vtStudentTrackingForm" [formGroup]="vtStudentTrackingForm"
                    class="vt-student-tracking-report" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
                    fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field fxFlex="35" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
                            <mat-label>From Date</mat-label>
                            <input matInput name="fromDate" formControlName="FromDate" [matDatepicker]="fromDate" [max]="CurrentDate"  [readonly]="true" (click)="fromDate.open()">
                            <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                            <mat-datepicker #fromDate></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field fxFlex="35" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
                            <mat-label>To Date</mat-label>
                            <input matInput name="toDate" formControlName="ToDate" [matDatepicker]="toDate" [max]="CurrentDate"  [readonly]="true" (click)="toDate.open()">
                            <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                            <mat-datepicker #toDate></mat-datepicker>
                        </mat-form-field>

                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="getVTStudentTrackingReports()"><span>SEARCH</span></button>
                        </div>

                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table matTableExporter class="vt-student-tracking-report-table responsive-table" #table
                [dataSource]="tableDataSource" #exporter="matTableExporter" [hiddenColumns]="[0]" matSort
                fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- SrNo Column -->
                <ng-container matColumnDef="SrNo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>SrNo</mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking; let RowIndex = index;">
                        <p class="text-truncate">{{vtStudentTracking.SrNo}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTPName Column -->
                <ng-container matColumnDef="VTPName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VTP Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.VTPName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VCName Column -->
                <ng-container matColumnDef="VCName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VC Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.VCName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VCMobile Column -->
                <ng-container matColumnDef="VCMobile">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VC Mobile
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.VCMobile}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VCEmail Column -->
                <ng-container matColumnDef="VCEmail">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VC Email
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.VCEmail}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTName Column -->
                <ng-container matColumnDef="VTName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VT Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.VTName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTMobile Column -->
                <ng-container matColumnDef="VTMobile">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VT Mobile
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.VTMobile}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTEmail Column -->
                <ng-container matColumnDef="VTEmail">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VT Email
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.VTEmail}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTDateOfJoining Column -->
                <ng-container matColumnDef="VTDateOfJoining">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VT Date Of Joining
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">
                            {{vtStudentTracking.VTDateOfJoining | date: Constants.ShortDateFormat }}</p>
                    </mat-cell>
                </ng-container>

                <!-- HMName Column -->
                <ng-container matColumnDef="HMName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>HM Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.HMName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- HMMobile Column -->
                <ng-container matColumnDef="HMMobile">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>HM Mobile
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.HMMobile}}</p>
                    </mat-cell>
                </ng-container>

                <!-- HMEmail Column -->
                <ng-container matColumnDef="HMEmail">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>HM Email
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.HMEmail}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SchoolManagement Column -->
                <ng-container matColumnDef="SchoolManagement">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School Management
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.SchoolManagement}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DivisionName Column -->
                <ng-container matColumnDef="DivisionName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Division
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.DivisionName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DistrictName Column -->
                <ng-container matColumnDef="DistrictName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>District
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.DistrictName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- BlockName Column -->
                <ng-container matColumnDef="BlockName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Block
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.BlockName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- UDISE Column -->
                <ng-container matColumnDef="UDISE">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>UDISE</mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.UDISE}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SchoolName Column -->
                <ng-container matColumnDef="SchoolName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.SchoolName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SectorName Column -->
                <ng-container matColumnDef="SectorName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Sector
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.SectorName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ReportType Column -->
                <ng-container matColumnDef="ReportType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Report Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.ReportType}}</p>
                    </mat-cell>
                </ng-container>

                <!-- WorkingDayType Column -->
                <ng-container matColumnDef="WorkingDayType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Working Day Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.WorkingDayType}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ClassTaught Column -->
                <ng-container matColumnDef="ClassTaught">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Class Taught
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.ClassTaught}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ClassType Column -->
                <ng-container matColumnDef="ClassType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Class Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.ClassType}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Girls Column -->
                <ng-container matColumnDef="Girls">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Girls
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.Girls}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Boys Column -->
                <ng-container matColumnDef="Boys">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Boys
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.Boys}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ReportingDate Column -->
                <ng-container matColumnDef="ReportingDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Date Of Report
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.ReportingDate | date: Constants.ShortDateFormat}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SubmissionDate Column -->
                <ng-container matColumnDef="SubmissionDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Submission Date
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.SubmissionDate | date: Constants.FullDateFormat}}</p>
                    </mat-cell>
                </ng-container>

                <!-- GeoLocation Column -->
                <ng-container matColumnDef="GeoLocation">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Geo Location
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentTracking">
                        <p class="text-truncate">{{vtStudentTracking.GeoLocation}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Header row first group -->
                <!-- <ng-container matColumnDef="header-row-first-group">
                    <mat-header-cell *matHeaderCellDef [style.text-align]="left" [attr.colspan]="20">
                    </mat-header-cell>
                </ng-container>
                <ng-container matColumnDef="header-row-sec-group">
                    <mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="2">
                        Attendance
                    </mat-header-cell>
                </ng-container>
                <ng-container matColumnDef="header-row-third-group">
                    <mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="3">
                    </mat-header-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="['header-row-first-group','header-row-sec-group','header-row-third-group']"></mat-header-row> -->
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let vtStudentTracking; columns: displayedColumns;"
                    class="vt-Student-tracking-report" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>