import { FuseUtils } from '@fuse/utils';

export class InternshipModel {


    InternshipId: string;

    StudentId: string;
    VTId: string;
    InternshipType: string;
    DateOfCommencement: Date;

    DateOfCompletion: Date;
    WorkingDays: string;
    SupervisorName: string;
    ScreenshotFile: any;
    ScreenshotFile2: any;
    ScreenshotFile3: any;
    ScreenshotFile4: any;
    ScreenshotFile5: any;
    ScreenshotFile6: any;
    ScreenshotFile7: any;
    ScreenshotFile8: any;
    ScreenshotFile9: any;
    ScreenshotFile10: any;
    ScreenshotFile11: any;

    SafteyMesuresTaken: string;
    StudentParticipatedInOrientation: string;
    // ParentConsentFormsSigned: string;
    // TotalDaysSpent: string;
    HoursPerDay: string;
    TotalHours: string;
    StudentCompletedInternship: string;

    DailyDairyMaintained: string;
    StudentFilledFeedbackForm: string;

    EmployerFeedback: string;
    // StudentFeedback: string;
    IsConsentApproved: string;
    LetterSignedByEmployer: string;
    AttendanceMaintained: string;
    WorkRegisterMaintained: string;
    OJTCertificatePresent: string;
    ReportCreatedByStudent: string;

    // InternshipImage1: string;
    // InternshipImage2: string;
    // InternshipImage3: string;
    // InternshipImage4: string;

    Attachment: any;
    Attachment2: any;
    Attachment3: any;
    Attachment4: any;
    Attachment5: any;
    Attachment6: any;
    Attachment7: any;
    Attachment8: any;
    Attachment9: any;
    Attachment10: any;
    Attachment11: any;

    RequestType: any;
    IsActive: boolean;

    constructor(internshipItem?: any) {
        internshipItem = internshipItem || {};

        this.InternshipId = internshipItem.InternshipId || FuseUtils.NewGuid();
        this.VTId = internshipItem.VTId || '';

        this.StudentId = internshipItem.StudentId || '';
        this.InternshipType = internshipItem.InternshipType || '';
        this.DateOfCommencement = internshipItem.DateOfCommencement || '';

        this.DateOfCompletion = internshipItem.DateOfCompletion || '';
        this.WorkingDays = internshipItem.WorkingDays || '';
        this.SupervisorName = internshipItem.SupervisorName || '';

        this.SafteyMesuresTaken = internshipItem.SafteyMesuresTaken || '';
        this.StudentParticipatedInOrientation = internshipItem.StudentParticipatedInOrientation || '';
        // this.ParentConsentFormsSigned = internshipItem.ParentConsentFormsSigned || '';
        // this.TotalDaysSpent = internshipItem.TotalDaysSpent || '';
        this.HoursPerDay = internshipItem.HoursPerDay || '';
        this.TotalHours = internshipItem.TotalHours || '';
        this.StudentCompletedInternship = internshipItem.StudentCompletedInternship || '';

        this.DailyDairyMaintained = internshipItem.DailyDairyMaintained || '';
        this.StudentFilledFeedbackForm = internshipItem.StudentFilledFeedbackForm || '';

        this.EmployerFeedback = internshipItem.EmployerFeedback || '';
        // this.StudentFeedback = internshipItem.StudentFeedback || '';
        this.IsConsentApproved = internshipItem.IsConsentApproved || '';
        this.LetterSignedByEmployer = internshipItem.LetterSignedByEmployer || '';
        this.AttendanceMaintained = internshipItem.AttendanceMaintained || '';
        this.WorkRegisterMaintained = internshipItem.WorkRegisterMaintained || '';
        this.OJTCertificatePresent = internshipItem.OJTCertificatePresent || '';
        this.ReportCreatedByStudent = internshipItem.ReportCreatedByStudent || '';

        // this.InternshipImage1 = internshipItem.InternshipImage1 || '';
        // this.InternshipImage2 = internshipItem.InternshipImage2 || '';
        // this.InternshipImage3 = internshipItem.InternshipImage3 || '';
        // this.InternshipImage4 = internshipItem.InternshipImage4 || '';

        this.ScreenshotFile = internshipItem.ScreenshotFile || '';
        this.ScreenshotFile2 = internshipItem.ScreenshotFile2 || '';
        this.ScreenshotFile3 = internshipItem.ScreenshotFile3 || '';
        this.ScreenshotFile4 = internshipItem.ScreenshotFile4 || '';
        this.ScreenshotFile5 = internshipItem.ScreenshotFile5 || '';
        this.ScreenshotFile6 = internshipItem.ScreenshotFile6 || '';
        this.ScreenshotFile7 = internshipItem.ScreenshotFile7 || '';
        this.ScreenshotFile8 = internshipItem.ScreenshotFile8 || '';
        this.ScreenshotFile9 = internshipItem.ScreenshotFile9 || '';
        this.ScreenshotFile10 = internshipItem.ScreenshotFile8 || '';
        this.ScreenshotFile11 = internshipItem.ScreenshotFile9 || '';

        this.Attachment = internshipItem.Attachment || '';
        this.Attachment2 = internshipItem.Attachment2 || '';
        this.Attachment3 = internshipItem.Attachment3 || '';
        this.Attachment4 = internshipItem.Attachment4 || '';
        this.Attachment5 = internshipItem.Attachment5 || '';
        this.Attachment6 = internshipItem.Attachment6 || '';
        this.Attachment7 = internshipItem.Attachment7 || '';
        this.Attachment8 = internshipItem.Attachment8 || '';
        this.Attachment9 = internshipItem.Attachment9 || '';
        this.Attachment10 = internshipItem.Attachment8 || '';
        this.Attachment11 = internshipItem.Attachment9 || '';

        this.IsActive = internshipItem.IsActive || true;

        this.RequestType = 0; // New
    }
}
