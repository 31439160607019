export const locale = {
    lang: 'gj',
    data: {
        'VC': {
            'VC': 'વ્યાવસાયિક સંકલનકારો',
            'ADD_NEW_VC': 'ઉમેરો',
            'SEARCH_FOR_A_VC': 'વ્યવસાયિક સંકલનકારો માટે શોધ કરો',
        }
    }
};

