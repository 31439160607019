<div id="data-type" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/data-types'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ dataTypeModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New Data Type
                    </div>
                    <div class="subtitle secondary-text">
                        <span>Data Type Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-data-type-button" [disabled]="dataTypeForm.invalid"
                *ngIf="PageRights.ActionType ==='new'" (click)="saveOrUpdateDataTypeDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-data-type-button" 
                *ngIf="PageRights.ActionType ==='edit'" (click)="saveOrUpdateDataTypeDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="dataTypeForm" [formGroup]="dataTypeForm" class="data-type w-100-p" fxLayout="column" fxFlex>
                    <!-- <mat-tab label="Basic Info"> -->
                    <div class="tab-content p-24" fusePerfectScrollbar>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field appearance="outline" class="pr-20" fxFlex="50" fxFlex.lt-md="75"
                            fxFlex.lt-sm="100">
                                <mat-label>Name</mat-label>
                                <input matInput name="name" formControlName="Name">
                                <mat-error *ngIf="dataTypeForm.controls.Name.invalid">{{getErrorMessage(dataTypeForm, 'Name')}}
                                </mat-error>
                            </mat-form-field>
                            
                            <mat-form-field appearance="outline" class="pr-1" fxFlex="50" fxFlex.lt-md="75"
                            fxFlex.lt-sm="100">
                                <mat-label>Description</mat-label>
                                <input matInput name="description" formControlName="Description">
                                <mat-error *ngIf="dataTypeForm.controls.Description.invalid">{{getErrorMessage(dataTypeForm, 'Description')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <span class="checkbox-space">Is Active?</span>
                            <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                            </mat-checkbox>
                        </div>
                    </div>
                    <!-- </mat-tab>
                    </mat-tab-group> -->
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>
