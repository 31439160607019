<div id="vt-school-sector" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/vt-school-sectors'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ vtSchoolSectorModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New VT School Sector
                    </div>
                    <div class="subtitle secondary-text">
                        <span>VT School Sector Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-vt-school-sector-button" 
                *ngIf="PageRights.ActionType ==='new'" (click)="saveOrUpdateVTSchoolSectorDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-vt-school-sector-button"
                *ngIf="PageRights.ActionType ==='edit'" (click)="saveOrUpdateVTSchoolSectorDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="vtSchoolSectorForm" [formGroup]="vtSchoolSectorForm"
                    class="vt-school-sector w-100-p p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
                    fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>
                    <!-- <mat-tab label="Basic Info"> -->
                    <!-- <div class="tab-content p-24" fusePerfectScrollbar> -->

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline"  fxFlex="50" fxFlex.lt-md="75"
                            fxFlex.lt-sm="100">
                            <mat-label>Select Academic Year</mat-label>

                            <mat-select formControlName="AcademicYearId" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="academicYearItem.Id"
                                    *ngFor="let academicYearItem of academicYearList">
                                    {{ academicYearItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtSchoolSectorForm.controls.AcademicYearId.invalid">
                                {{getErrorMessage(vtSchoolSectorForm, 'AcademicYearId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50"
                        fxFlex.lt-sm="100">
                            <mat-label>State</mat-label>
                            <mat-select name="stateCode" formControlName="StateCode" appearance="outline" required
                                [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeState($event.value)">
                                <mat-option [value]="stateItem.Id" *ngFor="let stateItem of stateList">
                                    {{ stateItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtSchoolSectorForm.controls.StateCode.invalid">
                                {{getErrorMessage(vtSchoolSectorForm, 'StateCode')}}</mat-error>
                        </mat-form-field>
                    </div>
                    
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline"  fxFlex="50" fxFlex.lt-md="100"
                            fxFlex.lt-sm="100">
                            <mat-label>Select School</mat-label>

                            <mat-select formControlName="SchoolId" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly" 
                                (selectionChange)="onChangeSchool($event.value)">
                                <mat-option [value]="schoolItem.Id" *ngFor="let schoolItem of schoolList">
                                    {{ schoolItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtSchoolSectorForm.controls.SchoolId.invalid">
                                {{getErrorMessage(vtSchoolSectorForm, 'SchoolId')}}
                            </mat-error>
                        </mat-form-field>
                    </div>    

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline"  fxFlex="50" fxFlex.lt-md="75"
                            fxFlex.lt-sm="100">
                            <mat-label>Select Sector</mat-label>

                            <mat-select formControlName="SectorId" required appearance="outline"
                                (selectionChange)="onChangeSector($event.value)" [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                    {{ sectorItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtSchoolSectorForm.controls.SectorId.invalid">
                                {{getErrorMessage(vtSchoolSectorForm, 'SectorId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline"  fxFlex="100" fxFlex.lt-md="75"
                            fxFlex.lt-sm="100">
                            <mat-label>Select Job Role</mat-label>

                            <mat-select name="jobRoleId" formControlName="JobRoleId" required 
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="jobRoleItem.Id" *ngFor="let jobRoleItem of jobRoleList">
                                    {{ jobRoleItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtSchoolSectorForm.controls.JobRoleId.invalid">
                                {{getErrorMessage(vtSchoolSectorForm, 'JobRoleId')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline"  fxFlex="100" fxFlex.lt-md="75"
                            fxFlex.lt-sm="100">
                            <mat-label>Select Vocational Trainer</mat-label>

                            <mat-select formControlName="VTId" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeVT($event.value)">
                                <mat-option [value]="vtItem.Id" *ngFor="let vtItem of vtList">
                                    {{ vtItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtSchoolSectorForm.controls.VTId.invalid">
                                {{getErrorMessage(vtSchoolSectorForm, 'VTId')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline"  fxFlex="50" fxFlex.lt-md="75"
                            fxFlex.lt-sm="100">
                            <mat-label>Date Of Allocation</mat-label>
                            <input matInput [matDatepicker]="dateOfAllocation" name="dateOfAllocation" required
                                [min]="minAllocationDate" [max]="CurrentDate" formControlName="DateOfAllocation"
                                [disabled]="PageRights.IsReadOnly" [readonly]="true" (click)="dateOfAllocation.open()">
                            <mat-datepicker-toggle matSuffix [for]="dateOfAllocation"></mat-datepicker-toggle>
                            <mat-datepicker #dateOfAllocation></mat-datepicker>
                            <mat-error *ngIf="vtSchoolSectorForm.controls.DateOfAllocation.invalid">
                                {{getErrorMessage(vtSchoolSectorForm, 'DateOfAllocation')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75"
                            fxFlex.lt-sm="100"
                            *ngIf="PageRights.ActionType ==='edit' || PageRights.ActionType ==='view'">
                            <mat-label>Date Of Removal</mat-label>
                            <input matInput [matDatepicker]="picker1" name="dateOfRemoval" [max]="CurrentDate"
                                (dateChange)="onChangeDateEnableDisableCheckBox(vtSchoolSectorForm, $event)"
                                [min]="vtSchoolSectorForm.controls.DateOfAllocation.value"
                                formControlName="DateOfRemoval" [disabled]="PageRights.IsReadOnly"[readonly]="true" (click)="picker1.open()">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                            <mat-error *ngIf="vtSchoolSectorForm.controls.DateOfRemoval.invalid">
                                {{getErrorMessage(vtSchoolSectorForm, 'DateOfRemoval')}}
                            </mat-error>
                        </mat-form-field>
                        <mat-icon matDatepickerToggleIcon
                            (click)="clearDateValueInFormControl(vtSchoolSectorForm, 'DateOfRemoval', $event)"
                            *ngIf="vtSchoolSectorForm.controls.DateOfRemoval.value && PageRights.ActionType !='view'">
                            clear</mat-icon>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">
                        <span class="checkbox-space">Is Active?</span>
                        <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                        </mat-checkbox>
                    </div>
                    <!-- </div> -->
                    <!-- </mat-tab>
                    </mat-tab-group> -->
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>