export const locale = {
    lang: 'gj',
    data: {
        'VT': {
            'VTP': 'વ્યાવસાયિક તાલીમ પ્રદાતાઓ',
            'ADD_NEW_VTP': 'ઉમેરો',
            'SEARCH_FOR_A_VTP': 'વ્યવસાયિક તાલીમ આપનારાઓ માટે શોધ કરો',
        }
    }
};

