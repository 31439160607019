<div id="internship" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/internships'" skipLocationChange="true"
                    replaceUrl="false">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ internshipModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New Internship
                    </div>
                    <div class="subtitle secondary-text">
                        <span>Internship Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-internship-button" *ngIf="PageRights.ActionType === 'new' && showInternshipDetails"
                (click)="saveOrUpdateInternshipDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-internship-button" *ngIf="PageRights.ActionType ==='edit'"
                (click)="saveOrUpdateInternshipDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="internshipForm" [formGroup]="internshipForm" class="internship w-100-p p-24 mr-24"
                    fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>

                    <div *ngIf="this.UserModel.RoleCode === 'VC'">
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Vocational Trainer Name</mat-label>
                            <mat-select formControlName="VTId" name="VTId" appearance="outline" [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeVocationalTrainer($event.value)" required> 
                            <mat-option [value]="VTItem.Id" *ngFor="let VTItem of vocationalTrainerList" > 
                            {{ VTItem.Name }} 
                            </mat-option>
                            </mat-select>
                        </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Select Student</mat-label>
                            <mat-select name="StudentId" formControlName="StudentId" appearance="outline" required
                                [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeStudent($event.value)">
                                <mat-option *ngFor="let studentItem of studentList" [value]="studentItem.Id" >
                                    {{ studentItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                <div *ngIf="this.employerName != null">
                    <div *ngIf="showInternshipDetails">

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50"
                            fxFlex.lt-sm="100">
                            <mat-label>Name of employer</mat-label>
                            <input matInput name="SupervisorName" formControlName="SupervisorName"
                                placeholder="Enter supervisor name" matTooltip="First letter should be capital."
                                matTooltipPosition="above" disabled>
                            <mat-error *ngIf="internshipForm.controls.SupervisorName.invalid">
                                {{getErrorMessage(internshipForm, 'SupervisorName')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Internship type</mat-label>
                            <mat-select name="InternshipType" formControlName="InternshipType" appearance="outline" required
                                [disabled]="PageRights.IsReadOnly" matTooltip="Internship Type"
                                matTooltipPosition="above">
                                <mat-option value="Online">Online</mat-option>
                                <mat-option value="Onsite">Onsite</mat-option>
                                <mat-option value="Internship@school">Internship@school</mat-option>
                                <mat-option value="Hybrid">Hybrid</mat-option>
                            </mat-select>
                            <mat-error *ngIf="internshipForm.controls.InternshipType.invalid">
                                {{getErrorMessage(internshipForm, 'InternshipType')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50"
                        fxFlex.lt-sm="100">
                            <mat-label>Date of Internship commencement</mat-label>
                            <input matInput [matDatepicker]="DateOfCommencement" name="DateOfCommencement" required
                                 formControlName="DateOfCommencement"
                                [disabled]="PageRights.IsReadOnly" [readonly]="true" (click)="DateOfCommencement.open()">
                            <mat-datepicker-toggle matSuffix [for]="DateOfCommencement"></mat-datepicker-toggle>
                            <mat-datepicker #DateOfCommencement></mat-datepicker>
                            <mat-error *ngIf="internshipForm.controls.DateOfCommencement.invalid">
                                {{getErrorMessage(internshipForm, 'DateOfCommencement')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50"
                        fxFlex.lt-sm="100">
                            <mat-label>Date of internship completion</mat-label>
                            <input matInput [matDatepicker]="DateOfCompletion" name="DateOfCompletion" [min]="internshipForm.controls.DateOfCommencement.value" required
                                 formControlName="DateOfCompletion"
                                [disabled]="PageRights.IsReadOnly" [readonly]="true" (click)="DateOfCompletion.open()">
                            <mat-datepicker-toggle matSuffix [for]="DateOfCompletion"></mat-datepicker-toggle>
                            <mat-datepicker #DateOfCompletion></mat-datepicker>
                            <mat-error *ngIf="internshipForm.controls.DateOfCompletion.invalid">
                                {{getErrorMessage(internshipForm, 'DateOfCompletion')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline"  fxFlex="33" fxFlex.lt-md="50"
                            fxFlex.lt-sm="100">
                            <mat-label>Total days of Internship (Working Days)</mat-label>
                            <input matInput name="WorkingDays" formControlName="WorkingDays" required digitOnly placeholder="Enter working days" (change)="onChangeInternshipDaysHours()">
                            <mat-error *ngIf="internshipForm.controls.WorkingDays.invalid">
                                {{getErrorMessage(internshipForm, 'WorkingDays')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline"  fxFlex="33" fxFlex.lt-md="50"
                            fxFlex.lt-sm="100">
                            <mat-label>Hours/per day (only mention digits)</mat-label>
                            <input matInput name="HoursPerDay" formControlName="HoursPerDay" required digitOnly placeholder="Enter Hours Per Day" (change)="onChangeInternshipDaysHours()">
                            <mat-error *ngIf="internshipForm.controls.HoursPerDay.invalid">
                                {{getErrorMessage(internshipForm, 'HoursPerDay')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">  
                        <mat-form-field appearance="outline"  fxFlex="33" fxFlex.lt-md="50"
                            fxFlex.lt-sm="100">
                            <mat-label>Total Hours of Internship</mat-label>
                            <input matInput name="TotalHours" formControlName="TotalHours" required digitOnly placeholder="Total Hours of Internship">
                            <mat-error *ngIf="internshipForm.controls.TotalHours.invalid">
                                {{getErrorMessage(internshipForm, 'TotalHours')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Saftey Mesures taken?</mat-label>
                            <mat-select name="SafteyMesuresTaken" formControlName="SafteyMesuresTaken" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly" matTooltip="Saftey Mesures taken"
                                matTooltipPosition="above">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                            <mat-error *ngIf="internshipForm.controls.SafteyMesuresTaken.invalid">
                                {{getErrorMessage(internshipForm, 'SafteyMesuresTaken')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Student participated in Orientation</mat-label>
                            <mat-select name="StudentParticipatedInOrientation" formControlName="StudentParticipatedInOrientation" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly" matTooltip="Student Participated In Orientation"
                                matTooltipPosition="above">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                            <mat-error *ngIf="internshipForm.controls.StudentParticipatedInOrientation.invalid">
                                {{getErrorMessage(internshipForm, 'StudentParticipatedInOrientation')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Student completed internship</mat-label>
                            <mat-select name="StudentCompletedInternship" formControlName="StudentCompletedInternship" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly" matTooltip="Student completed internship"
                                matTooltipPosition="above">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                            <mat-error *ngIf="internshipForm.controls.StudentCompletedInternship.invalid">
                                {{getErrorMessage(internshipForm, 'StudentCompletedInternship')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Daily dairy maintained?</mat-label>
                            <mat-select name="DailyDairyMaintained" formControlName="DailyDairyMaintained"  appearance="outline"
                                [disabled]="PageRights.IsReadOnly" matTooltip="Daily dairy maintained?"
                                matTooltipPosition="above" required>
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                            <mat-error *ngIf="internshipForm.controls.DailyDairyMaintained.invalid">
                                {{getErrorMessage(internshipForm, 'DailyDairyMaintained')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Student filled feedback form</mat-label>
                            <mat-select name="StudentFilledFeedbackForm" formControlName="StudentFilledFeedbackForm" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly" matTooltip="Student completed internship"
                                matTooltipPosition="above">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                            <mat-error *ngIf="internshipForm.controls.StudentFilledFeedbackForm.invalid">
                                {{getErrorMessage(internshipForm, 'StudentFilledFeedbackForm')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Student Attendance Sheet Maintained</mat-label>
                            <mat-select name="AttendanceMaintained" formControlName="AttendanceMaintained" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly" matTooltip="Attendance Maintained"
                                matTooltipPosition="above">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                            <mat-error *ngIf="internshipForm.controls.AttendanceMaintained.invalid">
                                {{getErrorMessage(internshipForm, 'AttendanceMaintained')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Student Daily Work Register Maintained</mat-label>
                            <mat-select name="WorkRegisterMaintained" formControlName="WorkRegisterMaintained" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly" matTooltip="Work Register Maintained"
                                matTooltipPosition="above">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                            <mat-error *ngIf="internshipForm.controls.WorkRegisterMaintained.invalid">
                                {{getErrorMessage(internshipForm, 'WorkRegisterMaintained')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Certificate given by Employer to student?</mat-label>
                            <mat-select name="OJTCertificatePresent" formControlName="OJTCertificatePresent" appearance="outline"
                                [disabled]="PageRights.IsReadOnly" matTooltip="Certificate given by Employer to student?"
                                matTooltipPosition="above">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                            <mat-error *ngIf="internshipForm.controls.OJTCertificatePresent.invalid">
                                {{getErrorMessage(internshipForm, 'OJTCertificatePresent')}}
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">   
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Consent Form signed by Student and Parents/Local Guardian?</mat-label>
                            <mat-select name="IsConsentApproved" formControlName="IsConsentApproved" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly" matTooltip="Consent Form signed by Student and Parents/Local Guardian?"
                                matTooltipPosition="above">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                            <mat-error *ngIf="internshipForm.controls.IsConsentApproved.invalid">
                                {{getErrorMessage(internshipForm, 'IsConsentApproved')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Letter of Confirmation signed by Employer and HoS</mat-label>
                            <mat-select name="LetterSignedByEmployer" formControlName="LetterSignedByEmployer" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly" matTooltip="Letter of Confirmation signed by Employer and HoS"
                                matTooltipPosition="above">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                            <mat-error *ngIf="internshipForm.controls.LetterSignedByEmployer.invalid">
                                {{getErrorMessage(internshipForm, 'LetterSignedByEmployer')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Report submitted by student</mat-label>
                            <mat-select name="ReportCreatedByStudent" formControlName="ReportCreatedByStudent" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly" matTooltip="Report Created By Student"
                                matTooltipPosition="above">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                            <mat-error *ngIf="internshipForm.controls.ReportCreatedByStudent.invalid">
                                {{getErrorMessage(internshipForm, 'ReportCreatedByStudent')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    
                    <h1><u>Document & Image Uploads</u></h1>
                    
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px" *ngIf="PageRights.ActionType ==='new'">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>Employer Feedback (File & Image)</mat-label>
                            <ngx-mat-file-input name="attachmentFile5" formControlName="ScreenshotFile5"
                                [accept]="AllowedReportExtension" (change)="uploadedScreenshotFile5($event)">
        
                            </ngx-mat-file-input>
                            <mat-icon matSuffix>folder</mat-icon>
                        </mat-form-field>  
                        
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>Project Report1 (File & Image)</mat-label>
                            <ngx-mat-file-input name="attachmentFile10" formControlName="ScreenshotFile10"
                                [accept]="AllowedReportExtension" (change)="uploadedScreenshotFile10($event)">
        
                            </ngx-mat-file-input>
                            <mat-icon matSuffix>folder</mat-icon>
                        </mat-form-field>  

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>Project Report2 (File & Image)</mat-label>
                            <ngx-mat-file-input name="attachmentFile11" formControlName="ScreenshotFile11"
                                [accept]="AllowedReportExtension" (change)="uploadedScreenshotFile11($event)">
        
                            </ngx-mat-file-input>
                            <mat-icon matSuffix>folder</mat-icon>
                        </mat-form-field>  
                    </div>

                    <div fxLayout="column" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px" *ngIf="PageRights.ActionType ==='new'">

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Internship Image (1)</mat-label>
                                <ngx-mat-file-input name="attachmentFile" formControlName="ScreenshotFile"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile($event)" required>
            
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                                <mat-error *ngIf="internshipForm.controls.ScreenshotFile.invalid">
                                    {{getErrorMessage(internshipForm, 'ScreenshotFile')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Internship Image (2)</mat-label>
                                <ngx-mat-file-input name="attachmentFile2" formControlName="ScreenshotFile2"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile2($event)">
            
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                                <mat-error *ngIf="internshipForm.controls.ScreenshotFile2.invalid">
                                    {{getErrorMessage(internshipForm, 'ScreenshotFile2')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Internship Image (3)</mat-label>
                                <ngx-mat-file-input name="attachmentFile3" formControlName="ScreenshotFile3"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile3($event)">
            
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                                <mat-error *ngIf="internshipForm.controls.ScreenshotFile3.invalid">
                                    {{getErrorMessage(internshipForm, 'ScreenshotFile3')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Internship Image (4)</mat-label>
                                <ngx-mat-file-input name="attachmentFile4" formControlName="ScreenshotFile4"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile4($event)">
            
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                                <mat-error *ngIf="internshipForm.controls.ScreenshotFile4.invalid">
                                    {{getErrorMessage(internshipForm, 'ScreenshotFile4')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Daily Dairy Photo (1)</mat-label>
                                <ngx-mat-file-input name="attachmentFile6" formControlName="ScreenshotFile6"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile6($event)">
            
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                                <mat-error *ngIf="internshipForm.controls.ScreenshotFile6.invalid">
                                    {{getErrorMessage(internshipForm, 'ScreenshotFile6')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Daily Dairy Photo (2)</mat-label>
                                <ngx-mat-file-input name="attachmentFile7" formControlName="ScreenshotFile7"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile7($event)">
            
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                                <mat-error *ngIf="internshipForm.controls.ScreenshotFile7.invalid">
                                    {{getErrorMessage(internshipForm, 'ScreenshotFile7')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Daily Dairy Photo (3)</mat-label>
                                <ngx-mat-file-input name="attachmentFile8" formControlName="ScreenshotFile8"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile8($event)">
            
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                                <mat-error *ngIf="internshipForm.controls.ScreenshotFile8.invalid">
                                    {{getErrorMessage(internshipForm, 'ScreenshotFile8')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Daily Dairy Photo (4)</mat-label>
                                <ngx-mat-file-input name="attachmentFile9" formControlName="ScreenshotFile9"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile9($event)">
            
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                                <mat-error *ngIf="internshipForm.controls.ScreenshotFile9.invalid">
                                    {{getErrorMessage(internshipForm, 'ScreenshotFile9')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>

                    <div fxLayout="column" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px" *ngIf="PageRights.ActionType ==='edit'">
                        
                        <div fxLayout="column" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <h3 style="margin-bottom:5px; margin-top: 0;display: flex;">
                                Employer Feedback: 
                                <a *ngIf="AttachmentURL5" target="_blank" href="{{AttachmentURL5}}"> View Uploaded File & Image</a>
                            </h3>
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Upload new (File & Image)</mat-label>
                                <ngx-mat-file-input name="attachmentFile5" formControlName="ScreenshotFile5"
                                    [accept]="AllowedReportExtension" (change)="uploadedScreenshotFile5($event)">
            
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                            </mat-form-field>   
                        </div>

                        <div fxLayout="column" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <h3 style="margin-bottom:5px; margin-top: 0;display: flex;">
                                Project Report (1): 
                                <a *ngIf="AttachmentURL10" target="_blank" href="{{AttachmentURL10}}"> View Uploaded File & Image</a>
                            </h3>
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Upload new (File & Image)</mat-label>
                                <ngx-mat-file-input name="attachmentFile10" formControlName="ScreenshotFile10"
                                    [accept]="AllowedReportExtension" (change)="uploadedScreenshotFile10($event)">
            
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                            </mat-form-field>   
                        </div>

                        <div fxLayout="column" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <h3 style="margin-bottom:5px; margin-top: 0;display: flex;">
                                Project Report (2): 
                                <a *ngIf="AttachmentURL11" target="_blank" href="{{AttachmentURL11}}"> View Uploaded File & Image</a>
                            </h3>
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Upload new (File & Image)</mat-label>
                                <ngx-mat-file-input name="attachmentFile11" formControlName="ScreenshotFile11"
                                    [accept]="AllowedReportExtension" (change)="uploadedScreenshotFile11($event)">
            
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                            </mat-form-field>   
                        </div>
                    </div>

                    <div fxLayout="column" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px" *ngIf="PageRights.ActionType ==='edit'">

                        <div fxLayout="column" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <h3 style="margin-bottom:5px; margin-top: 0;display: flex;">
                                Internship Image (1):
                                <a *ngIf="AttachmentURL" target="_blank" href="{{AttachmentURL}}"> View Uploaded Image</a>
                            </h3>
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Upload new image</mat-label>
                                <ngx-mat-file-input name="attachmentFile" formControlName="ScreenshotFile"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile($event)">
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                            </mat-form-field>
                        </div>

                        <div fxLayout="column" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <h3 style="margin-bottom:5px; margin-top: 0;display: flex;">
                                Internship Image (2):
                                <a *ngIf="AttachmentURL2" target="_blank" href="{{AttachmentURL2}}"> View Uploaded Image</a>
                            </h3>

                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Upload new image</mat-label>
                                <ngx-mat-file-input name="attachmentFile2" formControlName="ScreenshotFile2"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile2($event)">
            
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                                <mat-error *ngIf="internshipForm.controls.ScreenshotFile2.invalid">
                                    {{getErrorMessage(internshipForm, 'ScreenshotFile2')}}
                                </mat-error>
                            </mat-form-field>
                        </div>   
                        
                        <div fxLayout="column" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <h3 style="margin-bottom:5px; margin-top: 0;display: flex;">
                                Internship Image (3):
                                <a *ngIf="AttachmentURL3" target="_blank" href="{{AttachmentURL3}}"> View Uploaded Image</a>
                            </h3>

                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Upload new image</mat-label>
                                <ngx-mat-file-input name="attachmentFile3" formControlName="ScreenshotFile3"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile3($event)">
            
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                                <mat-error *ngIf="internshipForm.controls.ScreenshotFile3.invalid">
                                    {{getErrorMessage(internshipForm, 'ScreenshotFile3')}}
                                </mat-error>
                            </mat-form-field>
                        </div>    

                        <div fxLayout="column" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <h3 style="margin-bottom:5px; margin-top: 0;display: flex;">
                                Internship Image (4):
                                <a *ngIf="AttachmentURL4" target="_blank" href="{{AttachmentURL4}}"> View Uploaded Image</a>
                            </h3>

                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Upload new image</mat-label>
                                <ngx-mat-file-input name="attachmentFile4" formControlName="ScreenshotFile4"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile4($event)">
            
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                                <mat-error *ngIf="internshipForm.controls.ScreenshotFile4.invalid">
                                    {{getErrorMessage(internshipForm, 'ScreenshotFile4')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        

                        <div fxLayout="column" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <h3 style="margin-bottom:5px; margin-top: 0;display: flex;">
                                Daily Dairy Photo (1): 
                                <a *ngIf="AttachmentURL6" target="_blank" href="{{AttachmentURL6}}"> View Uploaded Image</a>
                            </h3>

                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Upload new image</mat-label>
                                <ngx-mat-file-input name="attachmentFile6" formControlName="ScreenshotFile6"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile6($event)">
            
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                                <mat-error *ngIf="internshipForm.controls.ScreenshotFile6.invalid">
                                    {{getErrorMessage(internshipForm, 'ScreenshotFile6')}}
                                </mat-error>
                            </mat-form-field>
                        </div> 

                        <div fxLayout="column" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <h3 style="margin-bottom:5px; margin-top: 0;display: flex;">
                                Daily Dairy Photo (2): 
                                <a *ngIf="AttachmentURL7" target="_blank" href="{{AttachmentURL7}}"> View Uploaded Image</a>
                            </h3>

                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Upload new image</mat-label>
                                <ngx-mat-file-input name="attachmentFile7" formControlName="ScreenshotFile7"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile7($event)">
            
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                                <mat-error *ngIf="internshipForm.controls.ScreenshotFile7.invalid">
                                    {{getErrorMessage(internshipForm, 'ScreenshotFile7')}}
                                </mat-error>
                            </mat-form-field>
                        </div> 

                        <div fxLayout="column" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <h3 style="margin-bottom:5px; margin-top: 0;display: flex;">
                                Daily Dairy Photo (3):  
                                <a *ngIf="AttachmentURL8" target="_blank" href="{{AttachmentURL8}}"> View Uploaded Image</a>
                            </h3>

                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Upload new image</mat-label>
                                <ngx-mat-file-input name="attachmentFile8" formControlName="ScreenshotFile8"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile8($event)">
            
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                                <mat-error *ngIf="internshipForm.controls.ScreenshotFile8.invalid">
                                    {{getErrorMessage(internshipForm, 'ScreenshotFile8')}}
                                </mat-error>
                            </mat-form-field>
                        </div> 

                        <div fxLayout="column" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <h3 style="margin-bottom:5px; margin-top: 0;display: flex;">
                                Daily Dairy Photo (4): 
                                <a *ngIf="AttachmentURL9" target="_blank" href="{{AttachmentURL9}}"> View Uploaded Image</a>
                            </h3>

                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Upload new image</mat-label>
                                <ngx-mat-file-input name="attachmentFile9" formControlName="ScreenshotFile9"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile9($event)">
            
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                                <mat-error *ngIf="internshipForm.controls.ScreenshotFile9.invalid">
                                    {{getErrorMessage(internshipForm, 'ScreenshotFile9')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        </div> 

                    </div>
                </div>
                
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>