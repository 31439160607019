import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { UserModel } from 'app/models/user.model';


@Injectable()
export class DailydairyService {
    constructor(private http: BaseService) { }

    getDailydairy(): Observable<any> {
        return this.http
            .HttpGet(this.http.Services.Dailydairy.GetAll)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetAllByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.Dailydairy.GetAllByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    getDailydairyById(dailydairyId: string) {
        let requestParams = {
            DataId: dailydairyId
        };

        return this.http
            .HttpPost(this.http.Services.Dailydairy.GetById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    createOrUpdateDailydairy(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Dailydairy.CreateOrUpdate, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    deleteDailydairyById(dailydairyId: string) {
        var dailydairyParams = {
            DataId: dailydairyId
        };

        return this.http
            .HttpPost(this.http.Services.Dailydairy.Delete, dailydairyParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    getStateDivisions(userModel: UserModel): Observable<any[]> {
        let studentsRequest = this.http.GetMasterDataByType({ DataType: 'StudentsByVt', ParentId: userModel.UserTypeId, SelectTitle: 'Students' });
        let vocationalTrainerListRequest = this.http.GetMasterDataByType({ dataType : 'VTListForUserID', ParentId: userModel.UserTypeId, SelectTitle: 'Vocational Trainer' });

        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([studentsRequest,vocationalTrainerListRequest]);
    }
}
