export const locale = {
    lang: 'gj',
    data: {
        'HEADMASTER': {
            'HEAD_MASTERS': 'હેડ માસ્ટર્સ',
            'ADD_NEW_HEAD_MASTER': 'ઉમેરો',
            'SEARCH_FOR_A_HEAD_MASTER': 'હેડ માસ્ટર્સની શોધ કરો',
        }
    }
};

