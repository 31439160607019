export const locale = {
    lang: 'gj',
    data: {
        'VT': {
            'VT': 'વ્યાવસાયિક ટ્રેનર્સ',
            'ADD_NEW_VT': 'ઉમેરો',
            'SEARCH_FOR_A_VT': 'એક વ્યાવસાયિક ટ્રેનર્સ માટે શોધ કરો',
        }
    }
};

