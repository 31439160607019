<div id="internshipfeedback" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    INTERNSHIP FEEDBACK
                    <a [routerLink]="['./']" skipLocationChange="true" replaceUrl="false"
                        class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)"
                        placeholder="Search for a internship feedback">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <!-- <button *ngIf="PageRights.IsAdd" mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false"
                    class="add-internshipfeedback-button fuse-white mt-24 mt-md-0">
                    <span>ADD</span>
                </button> -->
                <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exporter.exportTable('xlsx', {fileName:'InternshipfeedbackFullReport', sheet: 'InternshipfeedbackFullReport', Props: {Author: 'Lighthouse'}})">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

                <mat-table matTableExporter class="internshipfeedback-table responsive-table" #table [dataSource]="tableDataSource" #exporter="matTableExporter" [hiddenColumns]="[32]"
                matSort fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">
                
                <!-- 1DateOfActivityColumn -->
                <ng-container matColumnDef="DateOfActivity">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Feedback submission date
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.DateOfActivity | date: Constants.ShortDateFormat}} </p>
                    </mat-cell>
                </ng-container>

                <!-- 2FullName Column -->
                <ng-container matColumnDef="FullName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.FullName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 3WhatsappMobile Column -->
                <ng-container matColumnDef="WhatsappMobile">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Mobile(WhatsApp)
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.WhatsappMobile}}</p>
                    </mat-cell>
                </ng-container>


                <!-- 1WasOrientationOrganized Column -->
                <ng-container matColumnDef="WasOrientationOrganized">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Was internship orientation organized for you either online or offline?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.WasOrientationOrganized}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 2ParticipateInOrientation Column -->
                <ng-container matColumnDef="ParticipateInOrientation">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Did you participate in the internship orientation? 
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.ParticipateInOrientation}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 3EnjoyOrientation Column -->
                <ng-container matColumnDef="EnjoyOrientation">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Did you enjoy the orientation session?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.EnjoyOrientation}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 4EnjoyOrientationOption Column -->
                <ng-container matColumnDef="EnjoyOrientationOption">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>What did you enjoy the most during the orientation session? 
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.EnjoyOrientationOption}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 5NotEnjoyOrientationText Column -->
                <ng-container matColumnDef="NotEnjoyOrientationText">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Can you tell us why you could not enjoy the session?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.NotEnjoyOrientationText}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 6ImprovementInOrientationOption Column -->
                <ng-container matColumnDef="ImprovementInOrientationOption">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>How to improve the orientation session according to you?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.ImprovementInOrientationOption}}</p>
                    </mat-cell>
                </ng-container>


                <!-- 7ImprovementInOrientationText Column -->
                <ng-container matColumnDef="ImprovementInOrientationText">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Please share specific suggestions
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback">
                        <p class="text-truncate">{{internshipfeedback.ImprovementInOrientationText}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 8InternshipStartAt -->
                <ng-container matColumnDef="InternshipStartAt">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>When did you start your internship?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.InternshipStartAt | date: Constants.ShortDateFormat}} </p>
                    </mat-cell>
                </ng-container>

                <!-- 9InternshipEndtAt -->
                <ng-container matColumnDef="InternshipEndtAt">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>When did you finish your internship?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.InternshipEndtAt | date: Constants.ShortDateFormat}} </p>
                    </mat-cell>
                </ng-container>


                <!-- 10Supervisor Column -->
                <ng-container matColumnDef="Supervisor">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Please add the name of your Supervisor
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.Supervisor}}</p>
                    </mat-cell>
                </ng-container>


                <!-- 11SatisfiedWithSupervisor Column -->
                <ng-container matColumnDef="SatisfiedWithSupervisor">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Were you satisfied with the way your employer/supervisor supported you during the entire internship period?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.SatisfiedWithSupervisor}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 12ThingsLikedInSupervisor Column -->
                <ng-container matColumnDef="ThingsLikedInSupervisor">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>What all things you liked about your employer/supervisor?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.ThingsLikedInSupervisor}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 13ThingsLikedInSupervisorText Column -->
                <ng-container matColumnDef="ThingsLikedInSupervisorText">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Please specify what you like the most about your employer.
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.ThingsLikedInSupervisorText}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 14ImprovementInSupervisor Column -->
                <ng-container matColumnDef="ImprovementInSupervisor">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>What needs to be improved from the employer's end according to you?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.ImprovementInSupervisor}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 15ImprovementInSupervisorText Column -->
                <ng-container matColumnDef="ImprovementInSupervisorText">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Please specify what else needs to be improved from the employer's end?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.ImprovementInSupervisorText}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 16VTActivities Column -->
                <ng-container matColumnDef="VTActivities">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Which of the following activities did your Vocational trainer do during your internship
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.VTActivities}}</p>
                    </mat-cell>
                </ng-container>


                <!-- 17SupervisorMaintainAttendance Column -->
                <ng-container matColumnDef="SupervisorMaintainAttendance">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Did your employer/supervisor maintain your daily attendance register / sheet?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.SupervisorMaintainAttendance}}</p>
                    </mat-cell>
                </ng-container>


                <!-- 18OverallInternshipExperience Column -->
                <ng-container matColumnDef="OverallInternshipExperience">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>How was your overall internship experience?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.OverallInternshipExperience}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 19FaceChallenge Column -->
                <ng-container matColumnDef="FaceChallenge">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Please share with us the challenges you faced during internship?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.FaceChallenge}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 20FaceChallengeText Column -->
                <ng-container matColumnDef="FaceChallengeText">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Please specify the challenges.
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.FaceChallengeText}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 21SkillsDevelopInInternship Column -->
                <ng-container matColumnDef="SkillsDevelopInInternship">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>What skills did you develop through your internship?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.SkillsDevelopInInternship}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 22SkillsDevelopInInternshipText Column -->
                <ng-container matColumnDef="SkillsDevelopInInternshipText">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Please specify the skills you developed
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.SkillsDevelopInInternshipText}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 23LearnInInternship Column -->
                <ng-container matColumnDef="LearnInInternship">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>What did you learn through your internship?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.LearnInInternship}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 24LearnInInternshipText Column -->
                <ng-container matColumnDef="LearnInInternshipText">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Please specify about the things you learnt
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.LearnInInternshipText}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 25OverallFeedbackInternship Column -->
                <ng-container matColumnDef="OverallFeedbackInternship">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>What has been your overall take away from the Internships?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.OverallFeedbackInternship}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 26PrepareInternshipReport Column -->
                <ng-container matColumnDef="PrepareInternshipReport">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Did you prepare your internship project report?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{internshipfeedback.PrepareInternshipReport}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 27InternshipfeedbackImage1 Column -->
                <ng-container matColumnDef="Attachment">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Pic of yourself while working on activities</mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback">
                        <a target="_blank" *ngIf="internshipfeedback.Attachment" href="{{internshipfeedback.Attachment}}">{{internshipfeedback.Attachment}}</a>
                        <p *ngIf="!internshipfeedback.Attachment" class="text-truncate">No Image</p>
                    </mat-cell>
                </ng-container>

                <!-- 28InternshipfeedbackImage2 Column -->
                <ng-container matColumnDef="Attachment2">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Another pic of yourself while working on activities</mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback">
                        <a target="_blank" *ngIf="internshipfeedback.Attachment2" href="{{internshipfeedback.Attachment2}}">{{internshipfeedback.Attachment2}}</a>
                        <p *ngIf="!internshipfeedback.Attachment2" class="text-truncate">No Image</p>
                    </mat-cell>
                </ng-container>

                <!-- 29InternshipfeedbackImage3 Column -->
                <ng-container matColumnDef="Attachment3">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Internship project report picture</mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback">
                        <a target="_blank" *ngIf="internshipfeedback.Attachment3" href="{{internshipfeedback.Attachment3}}">{{internshipfeedback.Attachment3}}</a>
                        <p *ngIf="!internshipfeedback.Attachment3" class="text-truncate">No Image</p>
                    </mat-cell>
                </ng-container>


                <!-- 31Action Column -->
                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let internshipfeedback" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/internshipfeedback', 'view', internshipfeedback.InternshipfeedbackId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <!-- <button *ngIf="PageRights.IsEdit && internshipfeedback.DeletedBy == null" mat-icon-button
                            [routerLink]="['/internshipfeedback', 'edit', internshipfeedback.InternshipfeedbackId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">edit</mat-icon>
                        </button> -->
                        <!-- <button *ngIf="(PageRights.IsDelete && internshipfeedback.DeletedBy == null)" mat-icon-button color="warn" (click)="onDeleteInternshipfeedback(internshipfeedback.InternshipfeedbackId)">
                            <mat-icon>delete_outline</mat-icon>
                        </button> -->
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let internshipfeedback; columns: displayedColumns;" class="internshipfeedback" matRipple>
                </mat-row>

            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>