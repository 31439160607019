import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';

@Injectable()
export class SchoolVEInchargeService {
    constructor(private http: BaseService) { }

    getSchoolVEIncharges(): Observable<any> {
        return this.http
            .HttpGet(this.http.Services.SchoolVEIncharge.GetAll)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetAllByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.SchoolVEIncharge.GetAllByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    getSchoolVEInchargeById(veiId: string) {
        let requestParams = {
            DataId: veiId
        };

        return this.http
            .HttpPost(this.http.Services.SchoolVEIncharge.GetById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    createOrUpdateSchoolVEIncharge(formData: any) {
        return this.http
            .HttpPost(this.http.Services.SchoolVEIncharge.CreateOrUpdate, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    deleteSchoolVEInchargeById(veiId: string) {
        var schoolVEInchargeParams = {
            DataId: veiId
        };

        return this.http
            .HttpPost(this.http.Services.SchoolVEIncharge.Delete, schoolVEInchargeParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
}
