export const locale = {
    lang: 'en',
    data: {
        'VCSCHOOLSECTOR': {
            'VC_SCHOOL_SECTORS': 'VC SCHOOL SECTORS',
            'ADD_NEW_VC_SCHOOL_SECTORS': 'ADD',
            'SEARCH_FOR_A_VC_SCHOOL_SECTORS': 'Search for a vc school sectors',
        }
    }
};

