<div id="student-class" class="page-layout carded fullwidth inner-scroll">
    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->
    <!-- CENTER -->
    <div class="center">
        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center" >
            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">
                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/student-registrations'" >
                    <mat-icon>arrow_back</mat-icon>
                </button>
                <div fxLayout="column" fxLayoutAlign="start start" [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }" >
                <div class="h2" *ngIf="PageRights.PageType === 'Edit'">
                    {{ studentClassModel.FullName }}
                </div>
                <div class="h2" *ngIf="PageRights.PageType === 'New'">
                    New Student Registration
                </div>
                <div class="subtitle secondary-text">
                    <span>Student Registration Details</span>
                </div>
            </div>
        </div>
        <!-- / APP TITLE -->
        <button mat-raised-button class="add-student-class-button" *ngIf="PageRights.ActionType === 'new'" (click)="saveOrUpdateStudentClassDetails()">
        <span>ADD</span>
        </button>
        <button mat-raised-button class="save-student-class-button" *ngIf="PageRights.ActionType === 'edit'" (click)="saveOrUpdateStudentClassDetails()">
        <span>SAVE</span>
        </button>
    </div>
    <!-- / HEADER -->
    <!-- CONTENT CARD -->
    <div class="content-card">
        <!-- CONTENT -->
        <div class="content">
            <form name="studentClassForm" [formGroup]="studentClassForm" class="student-class w-100-p p-24 mr-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex fusePerfectScrollbar >
                <!-- <mat-tab label="Basic Info"> -->
                <!-- <div class="tab-content p-24" fusePerfectScrollbar> -->
                <h1 style="margin-top: 0;"><u>Student Details</u></h1>
                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px" >
                    <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="75" fxFlex.lt-sm="100" *ngIf="this.UserModel.RoleCode === 'VC'">
                        <mat-label>Vocational Trainer Name</mat-label>
                        <mat-select formControlName="VTId" name="VTId" appearance="outline" [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeVocationalTrainer($event.value)" required> 
                        <mat-option [value]="VTItem.Id" *ngFor="let VTItem of vocationalTrainerList" > 
                        {{ VTItem.Name }} 
                        </mat-option>
                        </mat-select> 
                        <mat-error *ngIf="studentClassForm.controls.VTId.invalid">
                            {{ getErrorMessage(studentClassForm, "VTId") }} 
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100" >
                        <mat-label>First Name</mat-label>
                        <input matInput name="firstName" formControlName="FirstName" required placeholder="Ex. Rakesh" matTooltip="First letter should be capital." matTooltipPosition="above" />
                        <mat-error *ngIf="studentClassForm.controls.FirstName.invalid">
                            {{ getErrorMessage(studentClassForm, "FirstName") }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100" >
                        <mat-label>Middle Name</mat-label>
                        <input matInput name="middleName" formControlName="MiddleName" placeholder="Ex. Kumar" matTooltip="First letter should be capital." matTooltipPosition="above" />
                        <mat-error *ngIf="studentClassForm.controls.MiddleName.invalid">
                            {{ getErrorMessage(studentClassForm, "MiddleName") }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100" >
                        <mat-label>Last Name</mat-label>
                        <input matInput name="lastName" formControlName="LastName" placeholder="Ex. Nayak" matTooltip="First letter should be capital." matTooltipPosition="above" /> 
                        <mat-error *ngIf="studentClassForm.controls.LastName.invalid">
                            {{ getErrorMessage(studentClassForm, "LastName") }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px" >
                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100" >
                        <mat-label>Date Of Birth</mat-label>
                        <input matInput [matDatepicker]="dateOfBirth" name="dateOfBirth" required [max]="" formControlName="DateOfBirth" [disabled]="PageRights.IsReadOnly" [readonly]="true" (click)="dateOfBirth.open()" />
                        <mat-datepicker-toggle matSuffix [for]="dateOfBirth" ></mat-datepicker-toggle>
                        <mat-datepicker #dateOfBirth></mat-datepicker>
                        <mat-error *ngIf="studentClassForm.controls.DateOfBirth.invalid">
                            {{ getErrorMessage(studentClassForm, "DateOfBirth") }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100" >
                        <mat-label>Gender</mat-label>
                        <mat-select name="gender" formControlName="Gender" required appearance="outline" [disabled]="PageRights.IsReadOnly" >
                            <mat-option [value]="genderItem.Id"*ngFor="let genderItem of genderList">
                            {{ genderItem.Name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="studentClassForm.controls.Gender.invalid">
                            {{ getErrorMessage(studentClassForm, "Gender") }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100" >
                        <mat-label>Mobile Number</mat-label>
                        <input matInput name="mobile" formControlName="Mobile" required digitOnly minlength="12" maxlength="12" placeholder="Ex. 919800098000" />
                        <mat-error *ngIf="studentClassForm.controls.Mobile.invalid">
                            Should be of 12 digit. Please add the valid country code,
                            91XXXXXXXXXX
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px" >
                    <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100" >
                        <mat-label>Whatsapp number</mat-label>
                        <input matInput name="mobile2" formControlName="Mobile2" digitOnly minlength="12" maxlength="12" placeholder="Ex. 919800098000" />
                        <mat-error *ngIf="studentClassForm.controls.Mobile2.invalid">
                            Should be of 12 digit. Please add the valid country code,
                            91XXXXXXXXXX
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100" >
                        <mat-label>Mother's Name</mat-label>
                        <input matInput name="MotherName" formControlName="MotherName" required placeholder="Ex. Sunita" matTooltip="First letter should be capital." matTooltipPosition="above" />
                        <mat-error *ngIf="studentClassForm.controls.MotherName.invalid">
                            {{ getErrorMessage(studentClassForm, "MotherName") }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100" >
                        <mat-label>Father's Name</mat-label>
                        <input matInput name="FatherName" formControlName="FatherName" required placeholder="Ex. Rakesh" matTooltip="First letter should be capital." matTooltipPosition="above" />
                        <mat-error *ngIf="studentClassForm.controls.FatherName.invalid">
                            {{ getErrorMessage(studentClassForm, "FatherName") }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                        <mat-label>Academic Year</mat-label>
                        <mat-select formControlName="AcademicYearId" appearance="outline" required [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeAcademicYear($event.value)"> 
                        <mat-option [value]="academicYearItem.Id" *ngFor="let academicYearItem of academicYearList"> 
                        {{ academicYearItem.Name }} 
                        </mat-option>
                        </mat-select>
                        <mat-error *ngIf="studentClassForm.controls.AcademicYearId.invalid">
                            {{ getErrorMessage(studentClassForm, "AcademicYearId") }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100" >
                        <mat-label>Class</mat-label>
                        <mat-select formControlName="ClassId" required appearance="outline" [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeClass($event.value)" >
                        <mat-option [value]="classItem.Id" *ngFor="let classItem of classList">
                        {{ classItem.Name }}
                        </mat-option>
                        </mat-select>
                        <mat-error *ngIf="studentClassForm.controls.ClassId.invalid">
                            {{ getErrorMessage(studentClassForm, "ClassId") }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100" >
                        <mat-label>Section</mat-label>
                        <mat-select formControlName="SectionId" appearance="outline" [disabled]="PageRights.IsReadOnly" required >
                            <mat-option [value]="sectionItem.Id" *ngFor="let sectionItem of sectionList" >
                            {{ sectionItem.Name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="studentClassForm.controls.SectionId.invalid">
                            {{ getErrorMessage(studentClassForm, "SectionId") }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px" >
                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100" >
                        <mat-label>Email ID</mat-label>
                        <input matInput name="Emailid" formControlName="Emailid" />
                        <mat-error *ngIf="studentClassForm.controls.Emailid.invalid">
                            {{ getErrorMessage(studentClassForm, "Emailid") }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100" >
                        <mat-label>Student Roll No</mat-label>
                        <input matInput name="StudentRollNumber" formControlName="StudentRollNumber" placeholder="" matTooltip="" digitOnly matTooltipPosition="above" />
                        <mat-error *ngIf="studentClassForm.controls.StudentRollNumber.invalid">
                            {{ getErrorMessage(studentClassForm, "StudentRollNumber") }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100" >
                        <mat-label>Stream</mat-label>
                        <mat-select name="Stream" formControlName="Stream" appearance="outline" [disabled]="PageRights.IsReadOnly" matTooltip="Stream" matTooltipPosition="above" >
                            <mat-option value="Arts">Arts</mat-option>
                            <mat-option value="Commerce">Commerce</mat-option>
                            <mat-option value="Science : PCM">Science : PCM</mat-option>
                            <mat-option value="Science : PCB">Science : PCB</mat-option>
                            <mat-option value="Science : PCMB">Science : PCMB</mat-option>
                        </mat-select>
                        <mat-error *ngIf="studentClassForm.controls.Stream.invalid">
                            {{ getErrorMessage(studentClassForm, "Stream") }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <h1><u>School Details</u></h1>
                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px" >
                    <mat-form-field appearance="outline" fxFlex="75" fxFlex.lt-md="75" fxFlex.lt-sm="100" >
                        <mat-label>School Name</mat-label>
                        <mat-select formControlName="SchoolId" name="SchoolId" appearance="outline" [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeSchool($event.value)" required > 
                        <mat-option [value]="SchoolItem.Id" *ngFor="let SchoolItem of schoolList" > 
                        {{ SchoolItem.Name }} 
                        </mat-option> 
                        </mat-select> 
                        <mat-error *ngIf="studentClassForm.controls.SchoolId.invalid">
                            {{ getErrorMessage(studentClassForm, "SchoolId") }} 
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100" >
                        <mat-label>UDISE</mat-label>
                        <input matInput name="Udise" formControlName="Udise" required placeholder="" matTooltip="" matTooltipPosition="above" [value]="" />
                        <mat-error *ngIf="studentClassForm.controls.Udise.invalid">
                            {{ getErrorMessage(studentClassForm, "Udise") }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px" >
                    <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="75" fxFlex.lt-sm="100" >
                        <mat-label>School Category</mat-label>
                        <mat-select name="schoolCategoryId" formControlName="SchoolCategoryId" [disabled]="PageRights.IsReadOnly" >
                            <mat-option [value]="SchoolCategoryItem.Id" *ngFor="let SchoolCategoryItem of schoolCategoryList" >
                            {{ SchoolCategoryItem.Name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="studentClassForm.controls.SchoolCategoryId.invalid">
                            {{ getErrorMessage(studentClassForm, "SchoolCategoryId") }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100" >
                        <mat-label>School Management</mat-label>
                        <mat-select formControlName="SchoolManagementId" name="schoolManagementId" appearance="outline" [disabled]="PageRights.IsReadOnly" >
                            <mat-option [value]="schoolManagementItem.Id" *ngFor="let schoolManagementItem of schoolManagementList" >
                            {{ schoolManagementItem.Name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="studentClassForm.controls.SchoolManagementId.invalid" >
                            {{ getErrorMessage(studentClassForm, "SchoolManagementId") }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="25" fxFlex.lt-sm="100">
                        <mat-label>State</mat-label>
                        <mat-select name="stateName" formControlName="StateName" required [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeState($event.value)" > 
                        <mat-option *ngFor="let stateItem of stateList" [value]="stateItem.Id" [value2]="stateItem.Name" > 
                        {{ stateItem.Name }}
                        </mat-option>
                        </mat-select>
                        <mat-error *ngIf="studentClassForm.controls.StateName.invalid">
                            {{ getErrorMessage(studentClassForm, "StateName") }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px" >
                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="25" fxFlex.lt-sm="100" >
                        <mat-label>Division</mat-label>
                        <mat-select formControlName="DivisionId" name="divisionId" appearance="outline" (selectionChange)="onChangeDivision($event.value)" [disabled]="PageRights.IsReadOnly" required >
                        <mat-option [value]="DivisionItem.Id" *ngFor="let DivisionItem of divisionList" >
                        {{ DivisionItem.Name }}
                        </mat-option>
                        </mat-select>
                        <mat-error *ngIf="studentClassForm.controls.DivisionId.invalid">
                            {{ getErrorMessage(studentClassForm, "DivisionId") }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="25" fxFlex.lt-sm="100" >
                        <mat-label>District</mat-label>
                        <mat-select name="districtCode" formControlName="DistrictCode" required [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeDistrict($event.value)" >
                        <mat-option *ngFor="let districtItem of districtList" [value]="districtItem.Id" >
                        {{ districtItem.Name }}
                        </mat-option>
                        </mat-select>
                        <mat-error *ngIf="studentClassForm.controls.DistrictCode.invalid">
                            {{ getErrorMessage(studentClassForm, "DistrictCode") }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="75" fxFlex.lt-sm="100" >
                        <mat-label>Block</mat-label>
                        <mat-select formControlName="BlockId" name="BlockId" appearance="outline" [disabled]="PageRights.IsReadOnly" required > 
                            <mat-option [value]="blockItem.Id" *ngFor="let blockItem of blockList" >
                            {{ blockItem.Name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="studentClassForm.controls.BlockId.invalid">
                            {{ getErrorMessage(studentClassForm, "BlockId") }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px" >
                    <!-- <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100" >
                        <mat-label>Sector</mat-label>
                        <input matInput name="Sector" formControlName="Sector" placeholder="Sector" matTooltip="First letter should be capital." matTooltipPosition="above" />
                        <mat-error *ngIf="studentClassForm.controls.Sector.invalid">
                            {{ getErrorMessage(studentClassForm, "Sector") }}
                        </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100" >
                        <mat-label>Job Role</mat-label>
                        <input matInput name="JobRole" formControlName="JobRole" placeholder="" matTooltip="First letter should be capital." matTooltipPosition="above" />
                        <mat-error *ngIf="studentClassForm.controls.JobRole.invalid">
                            {{ getErrorMessage(studentClassForm, "JobRole") }}
                        </mat-error>
                        </mat-form-field> -->
                </div>
                <h1><u>Internship Details</u></h1>
                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px" >
                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100" >
                        <mat-label>Date of student's enrollement</mat-label>
                        <input matInput [matDatepicker]="dateOfEnrollment" name="dateOfEnrollment" required [max]="" formControlName="DateOfEnrollment" [disabled]="PageRights.IsReadOnly" [readonly]="true" (click)="dateOfEnrollment.open()" />
                        <mat-datepicker-toggle matSuffix [for]="dateOfEnrollment" ></mat-datepicker-toggle>
                        <mat-datepicker #dateOfEnrollment></mat-datepicker>
                        <mat-error *ngIf="studentClassForm.controls.DateOfEnrollment.invalid" >
                            {{ getErrorMessage(studentClassForm, "DateOfEnrollment") }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100" >
                        <mat-label>Have student done internship earlier?</mat-label>
                        <mat-select name="DoneInternshipEarlier" formControlName="DoneInternshipEarlier" appearance="outline" [disabled]="PageRights.IsReadOnly" matTooltip="Have student done internship earlier" matTooltipPosition="above" >
                            <mat-option value="Yes">Yes</mat-option>
                            <mat-option value="No">No</mat-option>
                        </mat-select>
                        <mat-error *ngIf="studentClassForm.controls.DoneInternshipEarlier.invalid" >
                            {{ getErrorMessage(studentClassForm, "DoneInternshipEarlier") }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100" >
                        <mat-label >What are the devices available with the Student or his family
                        </mat-label>
                        <mat-select name="DevicesAvailable" formControlName="DevicesAvailable" multiple appearance="outline" [disabled]="PageRights.IsReadOnly" >
                            <mat-option value="Smartphone">Smartphone</mat-option>
                            <mat-option value="Laptop/Computer">Laptop/Computer</mat-option>
                            <mat-option value="Tablet">Tablet</mat-option>
                            <mat-option value="None of these">None of these</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px" >
                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100" >
                        <mat-label>Is student ready for internship?</mat-label>
                        <mat-select name="InternshipAgreed" formControlName="InternshipAgreed" appearance="outline" [disabled]="PageRights.IsReadOnly" matTooltip="Internship Agreed" matTooltipPosition="above" >
                            <mat-option value="Yes">Yes</mat-option>
                            <mat-option value="No">No</mat-option>
                        </mat-select>
                        <mat-error *ngIf="studentClassForm.controls.InternshipAgreed.invalid" >
                            {{ getErrorMessage(studentClassForm, "InternshipAgreed") }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100" >
                        <mat-label>Planned Start Date</mat-label>
                        <input matInput [matDatepicker]="PlannedStartDate" name="PlannedStartDate" formControlName="PlannedStartDate" [max]="" [disabled]="PageRights.IsReadOnly" [readonly]="true" (click)="PlannedStartDate.open()" />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="PlannedStartDate"
                            ></mat-datepicker-toggle>
                        <mat-datepicker #PlannedStartDate></mat-datepicker>
                        <mat-error *ngIf="studentClassForm.controls.PlannedStartDate.invalid" >
                            {{ getErrorMessage(studentClassForm, "PlannedStartDate") }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100" >
                        <mat-label>Planned End Date</mat-label>
                        <input matInput [matDatepicker]="PlannedEndDate" name="PlannedEndDate" [max]="" formControlName="PlannedEndDate" [disabled]="PageRights.IsReadOnly" [readonly]="true" (click)="PlannedEndDate.open()" />
                        <mat-datepicker-toggle matSuffix [for]="PlannedEndDate" ></mat-datepicker-toggle>
                        <mat-datepicker #PlannedEndDate></mat-datepicker>
                        <mat-error *ngIf="studentClassForm.controls.PlannedEndDate.invalid" >
                            {{ getErrorMessage(studentClassForm, "PlannedEndDate") }}
                        </mat-error>
                    </mat-form-field>
                    
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px" >
                        
                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="25" fxFlex.lt-sm="100" >
                        <mat-label>Employer Division</mat-label>
                        <mat-select formControlName="EmployerDivisionId" name="EmployerDivisionId" appearance="outline" (selectionChange)="onChangeEmployerDivision($event.value)" [disabled]="PageRights.IsReadOnly">
                            <mat-option [value]="EmployerDivisionItem.Id" *ngFor="let EmployerDivisionItem of divisionListForEmployer" >
                                {{ EmployerDivisionItem.Name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="studentClassForm.controls.EmployerDivisionId.invalid">
                            {{ getErrorMessage(studentClassForm, "EmployerDivisionId") }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="25" fxFlex.lt-sm="100" >
                        <mat-label>Employer District</mat-label>
                        <mat-select name="EmployerDistrictCode" formControlName="EmployerDistrictCode" [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeEmployerDistrict($event.value)" >
                            <mat-option *ngFor="let employerDistrictItem of districtListForEmployer" [value]="employerDistrictItem.Id" >
                                {{ employerDistrictItem.Name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="studentClassForm.controls.EmployerDistrictCode.invalid">
                            {{ getErrorMessage(studentClassForm, "EmployerDistrictCode") }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="75" fxFlex.lt-sm="100" >
                        <mat-label>Employer Block</mat-label>
                        <mat-select formControlName="EmployerBlockId" name="EmployerBlockId" appearance="outline" [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeEmployerBlock($event.value)" >
                                <mat-option [value]="employerBlockItem.Id" *ngFor="let employerBlockItem of blockListForEmployer" >
                                    {{ employerBlockItem.Name }}
                                </mat-option>
                        </mat-select>
                        <mat-error *ngIf="studentClassForm.controls.EmployerBlockId.invalid">
                            {{ getErrorMessage(studentClassForm, "EmployerBlockId") }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px" >
                    <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="25" fxFlex.lt-sm="100">
                        <mat-label>Employer Name</mat-label>
                        <mat-select formControlName="EmployerId" appearance="outline" [disabled]="PageRights.IsReadOnly" #SelectEmployer>
                            <mat-select-filter class="select-filter" *ngIf="SelectEmployer.focused" [array]="EmployerList" (filteredReturn)="filteredEmployerItems($event.value)" [displayMember]="'Name'"></mat-select-filter>
                            <mat-option [value]="employerItem.Id" *ngFor="let employerItem of filteredEmployerItems">
                            {{ employerItem.Name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="studentClassForm.controls.EmployerId.invalid">
                            {{getErrorMessage(studentClassForm, 'EmployerId')}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                        <mat-label>Remarks</mat-label>
                        <input matInput name="Remarks" formControlName="Remarks" placeholder="" matTooltip="" matTooltipPosition="above"/>
                        <mat-error *ngIf="studentClassForm.controls.Remarks.invalid">
                            {{ getErrorMessage(studentClassForm, "Remarks") }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <span class="checkbox-space">Is Active?</span>
                <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                </mat-checkbox>
                <!-- <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px" *ngIf="PageRights.ActionType === 'edit' || PageRights.ActionType === 'view' ">
                    <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)" fxFlex.lt-sm="100%">
                        <mat-label>Date Of Dropout</mat-label>
                        <input matInput [matDatepicker]="dateOfDropout" name="dateOfDropout" [max]="CurrentDate" (dateChange)="onChangeDateEnableDisableCheckBox(studentClassForm, $event)" [min]="studentClassForm.controls.DateOfEnrollment.value" formControlName="DateOfDropout" [disabled]="PageRights.IsReadOnly"/>
                        <mat-datepicker-toggle matSuffix [for]="dateOfDropout"
                            ></mat-datepicker-toggle>
                        <mat-datepicker #dateOfDropout></mat-datepicker>
                        <mat-error *ngIf="studentClassForm.controls.DateOfDropout.invalid">
                            {{ getErrorMessage(studentClassForm, "DateOfDropout") }}
                        </mat-error>
                    </mat-form-field>
                    <mat-icon matDatepickerToggleIcon (click)="clearDateValueInFormControl(studentClassForm, 'DateOfDropout', $event)" *ngIf="studentClassForm.controls.DateOfDropout.value &&PageRights.ActionType != 'view'">
                        clear
                      </mat-icon>
                    <mat-form-field appearance="outline" fxFlex="0 1 calc(65%)" fxFlex.lt-md="75" fxFlex.lt-sm="100" *ngIf="studentClassForm.controls.DateOfDropout.value" >
                        <mat-label>Dropout Reason</mat-label>
                        <input matInput name="dropoutReason" formControlName="DropoutReason" [required]=" studentClassForm.controls.DateOfDropout.value != null || studentClassForm.controls.DateOfDropout.value != '' " [disabled]=" studentClassForm.controls.DateOfDropout.value == null || studentClassForm.controls.DateOfDropout.value == '' " />
                        <mat-error
                            *ngIf="studentClassForm.controls.DropoutReason.invalid"
                            >
                            {{ getErrorMessage(studentClassForm, "DropoutReason") }}
                        </mat-error>
                    </mat-form-field>
                    </div> -->
                <!-- </div> -->
                <!-- </mat-tab>
                    </mat-tab-group> -->
            </form>
        </div>
        <!-- / CONTENT -->
    </div>
    <!-- / CONTENT CARD -->
</div>
<!-- / CENTER -->
</div>