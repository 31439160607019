import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { ReportService } from 'app/reports/report.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { VCDailyAttendanceTrackingModel } from './vc-daily-attendance-tracking.model';

@Component({
  selector: 'data-list-view',
  templateUrl: './vc-daily-attendance-tracking.component.html',
  styleUrls: ['./vc-daily-attendance-tracking.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class VCDailyAttendanceTrackingComponent extends BaseListComponent<VCDailyAttendanceTrackingModel> implements OnInit {
  dailyAttendanceTrackingForm: FormGroup;
  isShowFilterContainer = false;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    public formBuilder: FormBuilder,
    private reportService: ReportService) {
    super(commonService, router, routeParams, snackBar, zone);
  }

  ngOnInit(): void {

    this.dailyAttendanceTrackingForm = this.createVCDailyAttendanceTrackingForm();
  }

  //Create VCDailyAttendanceTracking form and returns {FormGroup}
  createVCDailyAttendanceTrackingForm(): FormGroup {
    return this.formBuilder.group({
      FromDate: new FormControl('', Validators.required),
      ToDate: new FormControl('', Validators.required)
    });
  }

  getVCDailyAttendanceTrackingReports(): void {
    if (!this.dailyAttendanceTrackingForm.valid) {
      this.validateAllFormFields(this.dailyAttendanceTrackingForm);  
      return;
    }
    
    var reportParams = {
      UserId: this.UserModel.LoginId,
      FromDate: this.DateFormatPipe.transform(this.dailyAttendanceTrackingForm.get('FromDate').value, this.Constants.ServerDateFormat),
      ToDate: this.DateFormatPipe.transform(this.dailyAttendanceTrackingForm.get('ToDate').value, this.Constants.ServerDateFormat)
    };

    this.reportService.GetVCDailyAttendanceTrackingByCriteria(reportParams).subscribe(response => {
      this.displayedColumns = ['VTPName', 'VCName', 'VCMobile', 'VCEmail', 'VCDateOfJoining', 'UDISE', 'SchoolName', 'SectorName', 'ReportType', 'DateOfReport', 'ActualSubmissionDateTime', 'GeoLocation'];

      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }
}
