import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant'
import { AccountService } from '../account.service';
import { AccountModel } from '../account.model';
import { DropdownModel } from 'app/models/dropdown.model';

@Component({
  selector: 'account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateAccountComponent extends BaseComponent<AccountModel> implements OnInit {
  accountForm: FormGroup;
  accountModel: AccountModel;
  roleList: DropdownModel[];

  roleNotAdmin = ['980200d7-de58-4140-ab62-845e5eec08d1', '259b3087-4e2a-435a-accc-cf8980ffbcca', 'cd6b4973-f87f-4887-bff2-6146447d11df'];
  stateList: [DropdownModel];
  divisionList: [DropdownModel];
  districtList: [DropdownModel];
  blockList: [DropdownModel];
  clusterList: [DropdownModel];

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default account Model
    this.accountModel = new AccountModel();
    this.roleList = <DropdownModel[]>[];
  }

  ngOnInit(): void {
    this.accountService.getUserDropdowns(this.UserModel).subscribe((results) => {
      if (results[0].Success) {
        //  980200d7-de58-4140-ab62-845e5eec08d1	VC	Vocational Coordinator
        //  259b3087-4e2a-435a-accc-cf8980ffbcca	VT	Vocational Trainer
        //  cd6b4973-f87f-4887-bff2-6146447d11df	HM	Head Master	 

        if (this.UserModel.RoleCode == "SUR") {
          this.roleList = results[0].Results;
        }
        else {
          results[0].Results.forEach(roleItem => {
            if (this.roleNotAdmin.indexOf(roleItem.Id) == -1) {
              this.roleList.push(roleItem);
            }
          });
        }
      }

      if (results[1].Success) {
        this.stateList = results[1].Results;
        this.onChangeState(this.UserModel.DefaultStateId);
      }

      this.route.paramMap.subscribe(params => {
        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.accountModel = new AccountModel();

          } else {
            var accountId: string = params.get('accountId')

            this.accountService.getAccountById(accountId)
              .subscribe((response: any) => {
                this.accountModel = response.Result;

                if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                  this.accountModel.RequestType = this.Constants.PageType.Edit;
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.accountModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;

                  // Set all roles for view VT/VC/HM role
                  this.roleList = results[0].Results;
                }

                this.onChangeState(this.accountModel.StateId);
                this.onChangeDivision(this.accountModel.DivisionId);
                //this.onChangeDistrict(this.accountModel.DistrictId);
                //this.onChangeBlock(this.accountModel.BlockId);
                this.onChangeRoleType(this.accountModel.RoleId);

                this.accountForm = this.createAccountForm();
              });
          }
        }
      });
    });

    this.accountForm = this.createAccountForm();
  }

  onChangeState(stateId: string): void {
    this.commonService.GetMasterDataByType({ DataType: 'Divisions', ParentId: stateId, SelectTitle: 'Division' }).subscribe((response: any) => {
      if (response.Success) {
        this.divisionList = response.Results;
      }
    });
  }

  onChangeDivision(divisionId: string): void {
    this.commonService.GetMasterDataByType({ DataType: 'Districts', ParentId: divisionId, SelectTitle: 'District' }).subscribe((response: any) => {
      if (response.Success) {
        this.districtList = response.Results;
      }
    });
  }

  onChangeDistrict(districtId: string): void {
    this.commonService.GetMasterDataByType({ DataType: 'Blocks', ParentId: districtId, SelectTitle: 'District' }).subscribe((response: any) => {
      if (response.Success) {
        this.blockList = response.Results;
      }
    });
  }

  onChangeBlock(blockId: any) {
    this.commonService.GetMasterDataByType({ DataType: 'Clusters', UserId: this.UserModel.DefaultStateId, ParentId: blockId, SelectTitle: 'Cluster' }).subscribe((response: any) => {
      this.clusterList = response.Results;
    });
  }

  onChangeRoleType(roleId) {
    if (roleId == this.Constants.DistrictEducationOfficer || roleId == this.Constants.DistrictResourcePerson) //District Education Officer 
    {
      // this.accountForm.controls["StateId"].setValidators([Validators.required]);
      // this.accountForm.controls["DivisionId"].setValidators([Validators.required]);
      // this.accountForm.controls["DistrictId"].setValidators([Validators.required]);
      // this.accountForm.controls["BlockId"].clearValidators();
    }
    else if (roleId == this.Constants.DivisionEducationOfficer) //Division Education Officer
    {
      // this.accountForm.controls["StateId"].setValidators([Validators.required]);
      // this.accountForm.controls["DivisionId"].setValidators([Validators.required]);
      // this.accountForm.controls["DistrictId"].clearValidators();
      // this.accountForm.controls["BlockId"].clearValidators();
    }
    else if (roleId == this.Constants.BlockEducationOfficer) //Block Education Officer
    {
      // this.accountForm.controls["StateId"].setValidators([Validators.required]);
      // this.accountForm.controls["DivisionId"].setValidators([Validators.required]);
      // this.accountForm.controls["DistrictId"].setValidators([Validators.required]);
      // this.accountForm.controls["BlockId"].setValidators([Validators.required, Validators.maxLength(150)]);
    }
    else if (roleId == this.Constants.BlockResoursePerson) //Block Education Officer
    {
      // this.accountForm.controls["StateId"].setValidators([Validators.required]);
      // this.accountForm.controls["DivisionId"].setValidators([Validators.required]);
      // this.accountForm.controls["DistrictId"].setValidators([Validators.required]);
      // this.accountForm.controls["BlockId"].setValidators([Validators.required, Validators.maxLength(150)]);
    }
    else {
      // this.accountForm.controls["StateId"].clearValidators();
      // this.accountForm.controls["DivisionId"].clearValidators();
      // this.accountForm.controls["DistrictId"].clearValidators();
      // this.accountForm.controls["BlockId"].clearValidators();
    }

    // this.accountForm.controls["StateId"].updateValueAndValidity();
    // this.accountForm.controls["DivisionId"].updateValueAndValidity();
    // this.accountForm.controls["DistrictId"].updateValueAndValidity();
    // this.accountForm.controls["BlockId"].updateValueAndValidity();
  }

  saveOrUpdateAccountDetails() {
    if (!this.accountForm.valid) {
      this.validateAllFormFields(this.accountForm);
      return;
    }
    this.setValueFromFormGroup(this.accountForm, this.accountModel);
    this.accountModel.StateId = this.accountForm.get('StateId').value;

    this.accountService.createOrUpdateAccount(this.accountModel)
      .subscribe((accountResp: any) => {

        if (accountResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.Account.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(accountResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('Account deletion errors =>', error);
      });
  }

  //Create account form and returns {FormGroup}
  createAccountForm(): FormGroup {
    return this.formBuilder.group({
      AccountId: new FormControl(this.accountModel.AccountId),
      LoginId: new FormControl({ value: this.accountModel.LoginId, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.maxLength(100), Validators.pattern(this.Constants.Regex.Email)]),
      Password: new FormControl({ value: this.accountModel.Password, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.maxLength(35), Validators.pattern(this.Constants.Regex.Password)]),
      UserId: new FormControl({ value: this.accountModel.UserId, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(40)]),
      UserName: new FormControl({ value: this.accountModel.UserName, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(100)),
      FirstName: new FormControl({ value: this.accountModel.FirstName, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(35), Validators.pattern(this.Constants.Regex.FirstCharsCapital)]),
      LastName: new FormControl({ value: this.accountModel.LastName, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(35), Validators.pattern(this.Constants.Regex.FirstCharsCapital)]),
      RoleId: new FormControl({ value: this.accountModel.RoleId, disabled: this.PageRights.IsReadOnly }),
      Designation: new FormControl({ value: this.accountModel.Designation, disabled: this.PageRights.IsReadOnly }),
      EmailId: new FormControl({ value: this.accountModel.EmailId, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(100), Validators.pattern(this.Constants.Regex.Email)]),
      Mobile: new FormControl({ value: this.accountModel.Mobile, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.Constants.Regex.MobileNumber)]),
      StateId: new FormControl({ value: this.accountModel.StateId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      DivisionId: new FormControl({ value: this.accountModel.DivisionId, disabled: this.PageRights.IsReadOnly }),
      DistrictId: new FormControl({ value: this.accountModel.DistrictId, disabled: this.PageRights.IsReadOnly }),
      BlockId: new FormControl({ value: this.accountModel.BlockId, disabled: this.PageRights.IsReadOnly }),
      ClusterId: new FormControl({ value: this.accountModel.ClusterId, disabled: this.PageRights.IsReadOnly }),
      IsLocked: new FormControl({ value: this.accountModel.IsLocked, disabled: this.PageRights.IsReadOnly }),
      IsActive: new FormControl({ value: this.accountModel.IsActive, disabled: this.PageRights.IsReadOnly }),
    });
  }
}
