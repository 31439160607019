<div id="internship" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    INTERNSHIPS
                    <a [routerLink]="['./']" skipLocationChange="true" replaceUrl="false"
                        class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)"
                        placeholder="Search for a internships">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <button *ngIf="PageRights.IsAdd" mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false"
                    class="add-internship-button fuse-white mt-24 mt-md-0">
                    <span>ADD</span>
                </button>
                <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exporter.exportTable('xlsx', {fileName:'InternshipFullReport', sheet: 'InternshipFullReport', Props: {Author: 'Lighthouse'}})">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

                <mat-table matTableExporter class="internship-table responsive-table" #table [dataSource]="tableDataSource" #exporter="matTableExporter" [hiddenColumns]="[34, 46]"
                matSort fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- 0StateName Column -->
                <ng-container matColumnDef="StateName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>State
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.StateName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 1DistrictCode Column -->
                <ng-container matColumnDef="DistrictCode">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>District
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.DistrictCode}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 2BlockId Column -->
                <ng-container matColumnDef="BlockId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Block
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.BlockId}}</p>
                    </mat-cell>
                </ng-container>

                <!--3 PinCode Column -->
                <ng-container matColumnDef="PinCode">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>PinCode
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.PinCode}}</p>
                    </mat-cell>
                </ng-container>


                <!-- 4SchoolId Column -->
                <ng-container matColumnDef="SchoolId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>SchoolId
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.SchoolId}}</p>
                    </mat-cell>
                </ng-container>


                <!-- 5SchoolName Column -->
                <ng-container matColumnDef="SchoolName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.SchoolName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 6SchoolCategoryId Column -->
                <ng-container matColumnDef="SchoolCategoryId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>School category
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.SchoolCategoryId}}</p>
                    </mat-cell>
                </ng-container>


                <!-- 7Udise Column -->
                <ng-container matColumnDef="Udise">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Udise
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.Udise}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 8Sector Column -->
                <ng-container matColumnDef="Sector">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Sector
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.Sector}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 9JobRole Column -->
                <ng-container matColumnDef="JobRole">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Job Role
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.JobRole}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 10VTPName Column -->
                <ng-container matColumnDef="VTPName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VTPName</mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <p class="text-truncate">{{internship.VTPName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 11VCName Column -->
                <ng-container matColumnDef="VCName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VCName</mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <p class="text-truncate">{{internship.VCName}}</p>
                    </mat-cell>
                </ng-container>


                <!-- 12VTName Column -->
                <ng-container matColumnDef="VTName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VTName</mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <p class="text-truncate">{{internship.VTName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 13VTContactNumber Column -->
                <ng-container matColumnDef="VTContactNumber">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VTContactNumber</mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <p class="text-truncate">{{internship.VTContactNumber}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 14EmployerName Column -->
                <ng-container matColumnDef="EmployerName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow>Name of employer/shop/organization/company
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <p class="text-truncate">{{internship.EmployerName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 15TypeOfBusiness Column -->
                <ng-container matColumnDef="TypeOfBusiness">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow>Type Of Business
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <p class="text-truncate">{{internship.TypeOfBusiness}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 16EmployerContactName Column -->
                <ng-container matColumnDef="EmployerContactName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow>Employer Contact Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <p class="text-truncate">{{internship.EmployerContactName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 17EmployerDesignation Column -->
                <ng-container matColumnDef="EmployerDesignation">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow>Employer Designation
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <p class="text-truncate">{{internship.EmployerDesignation}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 18EmployerContactPhone Column -->
                <ng-container matColumnDef="EmployerContactPhone">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow>Employer Contact Phone
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <p class="text-truncate">{{internship.EmployerContactPhone}}</p>
                    </mat-cell>
                </ng-container>


                <!-- 19StudentRollNumber Column -->
                <ng-container matColumnDef="StudentRollNumber">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Roll number
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.StudentRollNumber}}</p>
                    </mat-cell>
                </ng-container>


                <!--20 FullName Column -->
                <ng-container matColumnDef="FullName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Student name</mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <p class="text-truncate">{{internship.FullName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 21Gender Column -->
                <ng-container matColumnDef="Gender">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Gender
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.Gender}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 22ClassName Column -->
                <ng-container matColumnDef="ClassName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Class</mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.ClassName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 23Whatsapp number Column -->
                <ng-container matColumnDef="Mobile2">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Whatsapp number
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.Mobile2}}</p>
                    </mat-cell>
                </ng-container>


                <!-- 24DateOfCommencement Column -->
                <ng-container matColumnDef="DateOfCommencement">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Date Of Commencement
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <p class="text-truncate">{{internship.DateOfCommencement | date: Constants.ShortDateFormat }}</p>
                    </mat-cell>
                </ng-container>

                <!-- 25DateOfCompletion Column -->
                <ng-container matColumnDef="DateOfCompletion">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Date Of Completion
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <p class="text-truncate">{{internship.DateOfCompletion | date: Constants.ShortDateFormat }}</p>
                    </mat-cell>
                </ng-container>

                <!-- 26WorkingDays Column -->
                <ng-container matColumnDef="WorkingDays">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Total Days completed in Internship</mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <p class="text-truncate">{{internship.WorkingDays}}</p>
                    </mat-cell>
                </ng-container>


                <!-- 27TotalHoursCovered -->
                <ng-container matColumnDef="TotalHoursCovered">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Total Hours Covered</mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <p class="text-truncate">{{internship.WorkingDays * internship.HoursPerDay}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 28IsConsentApproved Column -->
                <ng-container matColumnDef="IsConsentApproved">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Consent Form signed by Student and Parents/Local Guardian?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <p class="text-truncate">{{internship.IsConsentApproved}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 29LetterSignedByEmployer Column -->
                <ng-container matColumnDef="LetterSignedByEmployer">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Letter of Confirmation Signed by Employer & HoS?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <p class="text-truncate">{{internship.LetterSignedByEmployer}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 30AttendanceMaintained Column -->
                <ng-container matColumnDef="AttendanceMaintained">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Attendance Sheet maintained?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <p class="text-truncate">{{internship.AttendanceMaintained}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 31DailyDairyMaintained Column -->
                <ng-container matColumnDef="DailyDairyMaintained">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Daily Work Register maintained?</mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <p class="text-truncate">{{internship.DailyDairyMaintained}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 32OJTCertificatePresent Column -->
                <ng-container matColumnDef="OJTCertificatePresent">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Internship Certificate given by Employer to student?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <p class="text-truncate">{{internship.OJTCertificatePresent}}</p>
                    </mat-cell>
                </ng-container>
            
                <!-- 33ReportCreatedByStudent Column -->
                <ng-container matColumnDef="ReportCreatedByStudent">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Internship Report created by student?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <p class="text-truncate">{{internship.ReportCreatedByStudent}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 34EmployerFeedback Column -->
                <ng-container matColumnDef="Attachment5">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Employer Feedback</mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <a target="_blank" *ngIf="internship.Attachment5" routerLink]="{{internship.Attachment5}}" href="{{internship.Attachment5}}">{{internship.Attachment5}}</a>
                        <p *ngIf="!internship.Attachment5" class="text-truncate">No Image</p>
                    </mat-cell>
                </ng-container>

                <!-- 44InternshipImage4 Column -->
                <ng-container matColumnDef="Attachment10">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Project Report1</mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <a target="_blank" *ngIf="internship.Attachment10" href="{{internship.Attachment10}}">{{internship.Attachment10}}</a>
                        <p *ngIf="!internship.Attachment10" class="text-truncate">No Image</p>
                    </mat-cell>
                </ng-container>
                
                
                <!-- 45InternshipImage4 Column -->
                <ng-container matColumnDef="Attachment11">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Project Report2</mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <a target="_blank" *ngIf="internship.Attachment11" href="{{internship.Attachment11}}">{{internship.Attachment11}}</a>
                        <p *ngIf="!internship.Attachment11" class="text-truncate">No Image</p>
                    </mat-cell>
                </ng-container>


                <!-- 35Remarks Column -->
                <ng-container matColumnDef="Remarks">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Remarks
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <p class="text-truncate">{{internship.Remarks}}</p>
                    </mat-cell>
                </ng-container>

                <!-- 36InternshipImage1 Column -->
                <ng-container matColumnDef="Attachment">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Internship Image1</mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <a target="_blank" *ngIf="internship.Attachment" href="{{internship.Attachment}}">{{internship.Attachment}}</a>
                        <p *ngIf="!internship.Attachment" class="text-truncate">No Image</p>
                    </mat-cell>
                </ng-container>

                <!--37InternshipImage2 Column -->
                <ng-container matColumnDef="Attachment2">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Internship Image2</mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <a target="_blank" *ngIf="internship.Attachment2" href="{{internship.Attachment2}}">{{internship.Attachment2}}</a>
                        <p *ngIf="!internship.Attachment2" class="text-truncate">No Image</p>
                    </mat-cell>
                </ng-container>

                <!-- 38InternshipImage3 Column -->
                <ng-container matColumnDef="Attachment3">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Internship Image3</mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <a target="_blank" *ngIf="internship.Attachment3" href="{{internship.Attachment3}}">{{internship.Attachment3}}</a>
                        <p *ngIf="!internship.Attachment3" class="text-truncate">No Image</p>
                    </mat-cell>
                </ng-container>

                <!-- 39InternshipImage4 Column -->
                <ng-container matColumnDef="Attachment4">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Internship Image4</mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <a target="_blank" *ngIf="internship.Attachment4" href="{{internship.Attachment4}}">{{internship.Attachment4}}</a>
                        <p *ngIf="!internship.Attachment4" class="text-truncate">No Image</p>
                    </mat-cell>
                </ng-container>

                <!-- 40InternshipImage4 Column -->
                <ng-container matColumnDef="Attachment6">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Daily Dairy photo1</mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <a target="_blank" *ngIf="internship.Attachment6" href="{{internship.Attachment6}}">{{internship.Attachment6}}</a>
                        <p *ngIf="!internship.Attachment6" class="text-truncate">No Image</p>
                    </mat-cell>
                </ng-container>

                <!-- 41InternshipImage4 Column -->
                <ng-container matColumnDef="Attachment7">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Daily Dairy photo2</mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <a target="_blank" *ngIf="internship.Attachment7" href="{{internship.Attachment7}}">{{internship.Attachment7}}</a>
                        <p *ngIf="!internship.Attachment7" class="text-truncate">No Image</p>
                    </mat-cell>
                </ng-container>

                <!-- 42InternshipImage4 Column -->
                <ng-container matColumnDef="Attachment8">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Daily Dairy photo3</mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <a target="_blank" *ngIf="internship.Attachment8" href="{{internship.Attachment8}}">{{internship.Attachment8}}</a>
                        <p *ngIf="!internship.Attachment8" class="text-truncate">No Image</p>
                    </mat-cell>
                </ng-container>

                <!-- 43InternshipImage4 Column -->
                <ng-container matColumnDef="Attachment9">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Daily Dairy photo4</mat-header-cell>
                    <mat-cell *matCellDef="let internship">
                        <a target="_blank" *ngIf="internship.Attachment9" href="{{internship.Attachment9}}">{{internship.Attachment9}}</a>
                        <p *ngIf="!internship.Attachment9" class="text-truncate">No Image</p>
                    </mat-cell>
                </ng-container>

                
                <!-- 46Action Column -->
                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let internship" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/internships', 'view', internship.InternshipId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsEdit && internship.DeletedBy == null" mat-icon-button
                            [routerLink]="['/internships', 'edit', internship.InternshipId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let internship; columns: displayedColumns;" class="internship" matRipple>
                </mat-row>

            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>