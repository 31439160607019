import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant'
import { DailydairyService } from '../dailydairy.service';
import { DailydairyModel } from '../dailydairy.model';
import { DropdownModel } from 'app/models/dropdown.model';
import { FileUploadModel } from 'app/models/file.upload.model';
import { FuseUtils } from '@fuse/utils';

@Component({
  selector: 'dailydairy',
  templateUrl: './create-dailydairy.component.html',
  styleUrls: ['./create-dailydairy.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateDailydairyComponent extends BaseComponent<DailydairyModel> implements OnInit {
  dailydairyForm: FormGroup;
  dailydairyModel: DailydairyModel;

  studentList: DropdownModel[];
  vocationalTrainerList: DropdownModel[];
  AttachmentURL: string;
  AttachmentURL2: string;

  ImageUrlGCSTag: string;
  ImageUrlGCS2Tag: string;

  mobile2: string;
  attachmentFile: FileUploadModel;
  attachmentFile2: FileUploadModel;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private dailydairyService: DailydairyService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);
    this.attachmentFile = new FileUploadModel();
    this.attachmentFile2 = new FileUploadModel();

    // Set the default dailydairy Model
    this.dailydairyModel = new DailydairyModel();
  }

  ngOnInit(): void {


    this.dailydairyService.getStateDivisions(this.UserModel).subscribe(results => {

      if (results[0].Success) {
        this.studentList = results[0].Results;
      }

      if (results[1].Success) {
        this.vocationalTrainerList = results[1].Results;
      }

      this.route.paramMap.subscribe(params => {
        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.dailydairyModel = new DailydairyModel();

          } else {
            var dailydairyId: string = params.get('dailydairyId')

            this.dailydairyService.getDailydairyById(dailydairyId)
              .subscribe((response: any) => {
                this.dailydairyModel = response.Result;

                if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                  this.dailydairyModel.RequestType = this.Constants.PageType.Edit;
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.dailydairyModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }
                this.AttachmentURL = this.dailydairyModel.Attachment;
                this.AttachmentURL2 = this.dailydairyModel.Attachment2;

                this.ImageUrlGCSTag = this.dailydairyModel.ImageUrlGCS;
                this.ImageUrlGCS2Tag = this.dailydairyModel.ImageUrlGCS2;

                this.dailydairyForm = this.createDailydairyForm();
              });
          }
        }
      });
    });

    this.dailydairyForm = this.createDailydairyForm();
  }

  uploadedScreenshotFile(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedImageExtensions.indexOf(fileExtn) == -1) {
        this.dailydairyForm.get('ScreenshotFile').setValue(null);
        this.dialogService.openShowDialog("Please upload Image file only.");
        return;
      }


      this.getUploadedFileData(event, this.Constants.DocumentType.DailydairyScreenshot).then((response: FileUploadModel) => {
        this.attachmentFile = response;
      });
    }
  }

  uploadedScreenshotFile2(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedImageExtensions.indexOf(fileExtn) == -1) {
        this.dailydairyForm.get('ScreenshotFile2').setValue(null);
        this.dialogService.openShowDialog("Please upload Image file only.");
        return;
      }


      this.getUploadedFileData(event, this.Constants.DocumentType.DailydairyScreenshot).then((response: FileUploadModel) => {
        this.attachmentFile2 = response;
      });
    }
  }

  onChangeVocationalTrainer(VTId) {
    if (this.UserModel.RoleCode === 'VC') {
      this.commonService.GetMasterDataByType({ DataType : 'StudentsByVt', ParentId: VTId, SelectTitle: 'Select Student' })
      .subscribe((response: any) => {
        if(response.Success){
          this.studentList = response.Results;
        }
      });
    }
  }

  onChangeStudent(StudentId: any) {
    this.commonService.GetMasterDataByType({ DataType: 'StudentMobile', ParentId: StudentId, SelectTitle: 'StudentMobile' }).subscribe((response: any) => {

      if (response.Results[1]) {
        this.mobile2 = response.Results[1].Description;
        this.dailydairyForm.get('WhatsappMobile').setValue(this.mobile2);
      } else {
        this.dailydairyForm.get('WhatsappMobile').setValue('');
      }

    });
  }

  saveOrUpdateDailydairyDetails() {
    if (!this.dailydairyForm.valid) {
      this.validateAllFormFields(this.dailydairyForm);
      return;
    }

    this.setValueFromFormGroup(this.dailydairyForm, this.dailydairyModel);

    if (this.attachmentFile.Base64Data != '') {
      this.dailydairyModel.ScreenshotFile = new FileUploadModel({
        UserId: FuseUtils.NewGuid(),
        ContentId: null,
        FilePath: null,
        ContentType: this.attachmentFile.ContentType,
        FileName: this.attachmentFile.FileName,
        FileType: this.attachmentFile.FileType,
        FileSize: this.attachmentFile.FileSize,
        Base64Data: this.attachmentFile.Base64Data
      });
    }

    if (this.attachmentFile2.Base64Data != '') {
      this.dailydairyModel.ScreenshotFile2 = new FileUploadModel({
        UserId: FuseUtils.NewGuid(),
        ContentId: null,
        FilePath: null,
        ContentType: this.attachmentFile2.ContentType,
        FileName: this.attachmentFile2.FileName,
        FileType: this.attachmentFile2.FileType,
        FileSize: this.attachmentFile2.FileSize,
        Base64Data: this.attachmentFile2.Base64Data
      });
    }

    var IsActivityPerformed = this.dailydairyForm.get("IsActivityPerformed").value;

    if (IsActivityPerformed == 'No') {
      this.dailydairyModel.HoursWorked = "0";
    }

    this.dailydairyService.createOrUpdateDailydairy(this.dailydairyModel)
      .subscribe((dailydairyResp: any) => {

        if (dailydairyResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.Dailydairy.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(dailydairyResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('Dailydairy deletion errors =>', error);
      });
  }

  //Create Dailydairy form and returns {FormGroup}
  createDailydairyForm(): FormGroup {
    return this.formBuilder.group({
      DailydairyId: new FormControl(this.dailydairyModel.DailydairyId),

      StudentId: new FormControl({ value: this.dailydairyModel.StudentId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      WhatsappMobile: new FormControl({ value: this.dailydairyModel.WhatsappMobile, disabled: true }),
      IsActivityPerformed: new FormControl({ value: this.dailydairyModel.IsActivityPerformed, disabled: this.PageRights.IsReadOnly }),
      HoursWorked: new FormControl({ value: this.dailydairyModel.HoursWorked, disabled: this.PageRights.IsReadOnly }),
      DateOfActivity: new FormControl({ value: this.getDateValue(this.dailydairyModel.DateOfActivity), disabled: this.PageRights.IsReadOnly }),

      ActivitiesPerformed: new FormControl({ value: this.dailydairyModel.ActivitiesPerformed, disabled: this.PageRights.IsReadOnly }),
      LearntToday: new FormControl({ value: this.dailydairyModel.LearntToday, disabled: this.PageRights.IsReadOnly }),

      TookSafetyPrecautions: new FormControl({ value: this.dailydairyModel.TookSafetyPrecautions, disabled: this.PageRights.IsReadOnly }),
      EmployerTookSafety: new FormControl({ value: this.dailydairyModel.EmployerTookSafety, disabled: this.PageRights.IsReadOnly }),

      IsSafetyIssues: new FormControl({ value: this.dailydairyModel.IsSafetyIssues, disabled: this.PageRights.IsReadOnly }),
      SafetyIssues: new FormControl({ value: this.dailydairyModel.SafetyIssues, disabled: this.PageRights.IsReadOnly }),

      ScreenshotFile: new FormControl({ value: this.dailydairyModel.ScreenshotFile, disabled: this.PageRights.IsReadOnly }),
      ScreenshotFile2: new FormControl({ value: this.dailydairyModel.ScreenshotFile2, disabled: this.PageRights.IsReadOnly }),

      // ImageUrlGCS: new FormControl({ value: this.dailydairyModel.ImageUrlGCS, disabled: this.PageRights.IsReadOnly }),
      // ImageUrlGCS2: new FormControl({ value: this.dailydairyModel.ImageUrlGCS2, disabled: this.PageRights.IsReadOnly }),
    });
  }
}
