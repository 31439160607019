import { FuseUtils } from '@fuse/utils';

export class AccountModel {
    AccountId: string;
    LoginId: string;
    Password: string;
    UserId: string;
    UserName: string;
    FirstName: string;
    LastName: string;
    RoleId: string;
    Designation: string;
    EmailId: string;
    Mobile: string;
    StateId: string;
    DivisionId: string;
    DistrictId: string;
    BlockId: string;
    ClusterId: string;
    AccountType: string;
    PasswordUpdateDate: any;
    PasswordExpiredOn: any;
    PowerBIAuthToken: any;
    LastLoginDate: any;
    InvalidAttempt: any;
    IsPasswordReset: boolean;
    PasswordResetToken: string;
    AuthToken: string;
    TokenExpiredOn: any;
    IsLocked: boolean;
    IsActive: boolean;
    RequestType: any;

    constructor(accountItem?: any) {
        accountItem = accountItem || {};

        this.AccountId = accountItem.AccountId || FuseUtils.NewGuid();
        this.LoginId = accountItem.LoginId || '';
        this.Password = accountItem.Password || '';
        this.UserId = accountItem.UserId || '';
        this.UserName = accountItem.UserName || '';
        this.FirstName = accountItem.FirstName || '';
        this.LastName = accountItem.LastName || '';
        this.RoleId = accountItem.RoleId || '';
        this.Designation = accountItem.Designation || '';
        this.EmailId = accountItem.EmailId || '';
        this.Mobile = accountItem.Mobile || '';
        this.StateId = accountItem.StateId || '';
        this.DivisionId = accountItem.DivisionId || '';
        this.DistrictId = accountItem.DistrictId || '';
        this.BlockId = accountItem.BlockId || '';
        this.ClusterId = accountItem.ClusterId || '';
        this.AccountType = accountItem.AccountType || '';
        this.PasswordUpdateDate = accountItem.PasswordUpdateDate || '';
        this.PasswordExpiredOn = accountItem.PasswordExpiredOn || '';
        this.PowerBIAuthToken = accountItem.PowerBIAuthToken || '';
        this.LastLoginDate = accountItem.LastLoginDate || '';
        this.InvalidAttempt = accountItem.InvalidAttempt || '';
        this.IsPasswordReset = accountItem.IsPasswordReset || true;
        this.PasswordResetToken = accountItem.PasswordResetToken || '';
        this.AuthToken = accountItem.AuthToken || '';
        this.TokenExpiredOn = accountItem.TokenExpiredOn || '';
        this.IsLocked = accountItem.IsLocked || false;
        this.IsActive = accountItem.IsActive || true;
        this.RequestType = 0; // New
    }
}
