<div id="vocational-coordinator" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    {{'VC.VC' | translate }}
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)" placeholder="{{'VC.SEARCH_FOR_A_VC' | translate }}">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
            <button *ngIf="PageRights.IsAdd" mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false" class="add-vocational-coordinator-button fuse-white mt-24 mt-md-0">
                <span>{{'VC.ADD_NEW_VC' | translate}}</span>
            </button>

            <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exporter.exportTable('xlsx', {fileName:'VocationalCoordinators', sheet: 'VocationalCoordinators', Props: {Author: 'Lighthouse'}})">
                <mat-icon> cloud_download</mat-icon>
            </a>
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table matTableExporter class="vocational-coordinator-table responsive-table" #table #exporter="matTableExporter" [hiddenColumns]="[6,7]"
            [dataSource]="tableDataSource" matSort fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">
                
                 <!-- NatureOfAppointment Column -->
                 <ng-container matColumnDef="NatureOfAppointment">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Nature Of Appointment</mat-header-cell>
                    <mat-cell *matCellDef="let vocationalCoordinator" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vocationalCoordinator.NatureOfAppointment}}</p>
                    </mat-cell>
                </ng-container>

                <!-- FullName Column -->
                <ng-container matColumnDef="FullName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Full Name</mat-header-cell>
                    <mat-cell *matCellDef="let vocationalCoordinator">
                        <p class="text-truncate">{{vocationalCoordinator.FullName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Mobile Column -->
                <ng-container matColumnDef="Mobile">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>First Mobile Number</mat-header-cell>
                    <mat-cell *matCellDef="let vocationalCoordinator" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vocationalCoordinator.Mobile}}</p>
                    </mat-cell>
                </ng-container>

                <!-- EmailId Column -->
                <ng-container matColumnDef="EmailId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Email</mat-header-cell>
                    <mat-cell *matCellDef="let vocationalCoordinator" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vocationalCoordinator.EmailId}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Gender Column -->
                <ng-container matColumnDef="Gender">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Gender</mat-header-cell>
                    <mat-cell *matCellDef="let vocationalCoordinator">
                        <p class="text-truncate">{{vocationalCoordinator.Gender}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DateOfJoining Column -->
                <ng-container matColumnDef="DateOfJoining">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Date Of Joining</mat-header-cell>
                    <mat-cell *matCellDef="let vocationalCoordinator" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vocationalCoordinator.DateOfJoining | date: Constants.ShortDateFormat }}</p>
                    </mat-cell>
                </ng-container>

                <!-- IsActive Column -->
                <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Is Active?</mat-header-cell>
                    <mat-cell *matCellDef="let vocationalCoordinator" fxHide fxShow.gt-xs>
                        <mat-icon *ngIf="vocationalCoordinator.IsActive" class="active-icon green-600 s-16">check</mat-icon>
                        <mat-icon *ngIf="!vocationalCoordinator.IsActive" class="active-icon red-500 s-16">close</mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let vocationalCoordinator" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                        [routerLink]="['/vocational-coordinators', 'view', vocationalCoordinator.VCId]" skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsEdit" mat-icon-button
                        [routerLink]="['/vocational-coordinators', 'edit', vocationalCoordinator.VCId]" skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>
                        <button mat-icon-button color="warn" (click)="onDeleteVocationalCoordinator(vocationalCoordinator.VCId)"
                            *ngIf="PageRights.IsDelete">
                            <mat-icon>delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let vocationalCoordinator; columns: displayedColumns;" class="vocational-coordinator" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
