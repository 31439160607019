<div id="dailydairy" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/dailydairy'" skipLocationChange="true"
                    replaceUrl="false">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ dailydairyModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New Daily Dairy
                    </div>
                    <div class="subtitle secondary-text">
                        <span>Daily Dairy Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-dailydairy-button" *ngIf="PageRights.ActionType ==='new'"
                (click)="saveOrUpdateDailydairyDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-dailydairy-button" *ngIf="PageRights.ActionType ==='edit'"
                (click)="saveOrUpdateDailydairyDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="dailydairyForm" [formGroup]="dailydairyForm" class="dailydairy w-100-p p-24 mr-24"
                    fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        
                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="75" fxFlex.lt-sm="100" *ngIf="this.UserModel.RoleCode === 'VC'">
                            <mat-label>Vocational Trainer Name</mat-label>
                            <mat-select formControlName="VTId" name="VTId" appearance="outline" [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeVocationalTrainer($event.value)" required> 
                            <mat-option [value]="VTItem.Id" *ngFor="let VTItem of vocationalTrainerList" > 
                            {{ VTItem.Name }} 
                            </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Select Student</mat-label>
                            <mat-select name="StudentId" formControlName="StudentId" appearance="outline" required
                                [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeStudent($event.value)">
                                <mat-option *ngFor="let studentItem of studentList" [value]="studentItem.Id" >
                                    {{ studentItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline"  fxFlex="34" fxFlex.lt-md="50"
                        fxFlex.lt-sm="100">
                            <mat-label>Mobile(WhatsApp)</mat-label>
                            <input matInput name="WhatsappMobile" formControlName="WhatsappMobile" digitOnly minlength="12"
                                maxlength="12" placeholder="Ex. 919800098000" disabled>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50"
                        fxFlex.lt-sm="100">
                            <mat-label>Date of Activity</mat-label>
                            <input matInput [matDatepicker]="DateOfActivity" name="DateOfActivity" required
                                 formControlName="DateOfActivity" [max]="CurrentDate"
                                [disabled]="PageRights.IsReadOnly" [readonly]="true" (click)="DateOfActivity.open()">
                            <mat-datepicker-toggle matSuffix [for]="DateOfActivity"></mat-datepicker-toggle>
                            <mat-datepicker #DateOfActivity></mat-datepicker>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Did you perform any task/activity at your internship today?</mat-label>
                            <mat-select name="IsActivityPerformed" formControlName="IsActivityPerformed" appearance="outline" required
                                [disabled]="PageRights.IsReadOnly" matTooltip=""
                                matTooltipPosition="above">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100" *ngIf="dailydairyForm.controls.IsActivityPerformed.value == 'Yes'"> 
                            <mat-label>How many hours did you work today?</mat-label>
                            <mat-select name="HoursWorked" formControlName="HoursWorked" appearance="outline" required
                                [disabled]="PageRights.IsReadOnly" matTooltip="Dailydairy Type"
                                matTooltipPosition="above">
                                <mat-option value="1">1 Hr</mat-option>
                                <mat-option value="2">2 Hr</mat-option>
                                <mat-option value="3">3 Hr</mat-option>
                                <mat-option value="4">4 Hr</mat-option>
                                <mat-option value="5">5 Hr</mat-option>
                                <mat-option value="6">6 Hr</mat-option>
                                <mat-option value="7">7 Hr</mat-option>
                                <mat-option value="8">8 Hr</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div> 

                    <div *ngIf="dailydairyForm.controls.IsActivityPerformed.value == 'Yes'">
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">

                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="50"
                            fxFlex.lt-sm="100">
                                <mat-label>Please share the specific activities you performed today</mat-label>
                                <input matInput name="ActivitiesPerformed" formControlName="ActivitiesPerformed" required
                                    placeholder="" matTooltip=""
                                    matTooltipPosition="above">
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="50"
                            fxFlex.lt-sm="100">
                                <mat-label>Please share what all you learnt today!</mat-label>
                                <input matInput name="LearntToday" formControlName="LearntToday" required
                                    placeholder="" matTooltip=""
                                    matTooltipPosition="above">
                            </mat-form-field>

                        </div> 

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">

                            <mat-form-field appearance="outline" fxFlex="60" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Did you take safety precautions suggested by the employer/Teacher?</mat-label>
                                <mat-select name="TookSafetyPrecautions" formControlName="TookSafetyPrecautions" appearance="outline" required
                                    [disabled]="PageRights.IsReadOnly" matTooltip=""
                                    matTooltipPosition="above">
                                    <mat-option value="Yes">Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="40" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Did the employer take care of your safety?</mat-label>
                                <mat-select name="EmployerTookSafety" formControlName="EmployerTookSafety" appearance="outline" required
                                    [disabled]="PageRights.IsReadOnly" matTooltip=""
                                    matTooltipPosition="above">
                                    <mat-option value="Yes">Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div> 

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">

                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Were there any safety issues?</mat-label>
                                <mat-select name="IsSafetyIssues" formControlName="IsSafetyIssues" appearance="outline" required
                                    [disabled]="PageRights.IsReadOnly" matTooltip=""
                                    matTooltipPosition="above">
                                    <mat-option value="Yes">Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> 
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">

                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100" 
                            *ngIf="dailydairyForm.controls.IsSafetyIssues.value == 'Yes'">
                                <mat-label>If there were any safety issues please mention them here</mat-label>
                                <input matInput name="SafetyIssues" formControlName="SafetyIssues"
                                    placeholder="" matTooltip=""
                                    matTooltipPosition="above">
                            </mat-form-field>
                        </div>

                        <div fxLayout="column" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px" *ngIf="PageRights.ActionType ==='new' ">

                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Please upload today’s daily dairy picture</mat-label>
                                <ngx-mat-file-input name="attachmentFile" formControlName="ScreenshotFile"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile($event)" required>
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                            </mat-form-field>
                            
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Add any other picture and videos from your workplace or any work done today</mat-label>
                                <ngx-mat-file-input name="attachmentFile2" formControlName="ScreenshotFile2"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile2($event)">
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                            </mat-form-field>
                        </div>

                        <div fxLayout="column" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px" *ngIf=" PageRights.ActionType ==='edit'">
                            
                            <ng-template #showUploadUrl>
                                <a *ngIf="AttachmentURL" target="_blank" href="{{AttachmentURL}}"> View Uploaded Image</a>
                            </ng-template>
                            <ng-template #ShowGcsUrl>
                                <a *ngIf="ImageUrlGCSTag" target="_blank" href="{{ImageUrlGCSTag}}"> View Uploaded Image</a>
                            </ng-template>

                            <div fxLayout="column" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <h3 style="margin-bottom:5px; margin-top: 0;display: flex;">
                                    Please upload today’s daily dairy picture:  
                                    <div *ngIf="AttachmentURL; then showUploadUrl; else ShowGcsUrl"></div>
                                </h3>
                                <mat-form-field appearance="outline" fxFlex="20" fxFlex.lt-md="30" fxFlex.lt-sm="30">
                                    <mat-label>Upload new image</mat-label>
                                    <ngx-mat-file-input name="attachmentFile" formControlName="ScreenshotFile"
                                        [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile($event)">
                                    </ngx-mat-file-input>
                                    <mat-icon matSuffix>folder</mat-icon>
                                </mat-form-field>
                            </div>

                            <ng-template #showUploadUrl2>
                                <a *ngIf="AttachmentURL2" target="_blank" href="{{AttachmentURL2}}"> View Uploaded Image</a>
                            </ng-template>
                            <ng-template #ShowGcsUrl2>
                                <a *ngIf="ImageUrlGCS2Tag" target="_blank" href="{{ImageUrlGCS2Tag}}"> View Uploaded Image</a>
                            </ng-template>

                            <div fxLayout="column" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <h3 style="margin-bottom:5px; margin-top: 0; display: flex;">
                                    Add any other picture and videos from your workplace or any work done today: 
                                    <div *ngIf="AttachmentURL2; then showUploadUrl2; else ShowGcsUrl2"></div>
                                </h3>
                                <mat-form-field appearance="outline" fxFlex="20" fxFlex.lt-md="30" fxFlex.lt-sm="30">
                                    <mat-label>Upload new image</mat-label>
                                    <ngx-mat-file-input name="attachmentFile2" formControlName="ScreenshotFile2"
                                        [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile2($event)">
                                    </ngx-mat-file-input>
                                    <mat-icon matSuffix>folder</mat-icon>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>