<div id="broadcast-message" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <!-- {{ 'HEADMASTER.HEAD_MASTERS' | translate}} -->
                    BROADCAST MESSAGES
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)" placeholder="Search for a Broadcast Message ">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
            <button mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false" class="add-broadcast-message-button fuse-white mt-24 mt-md-0">
                <span>ADD</span>
            </button>

            <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exporter.exportTable('xlsx', {fileName:'HeadMaster', sheet: 'HeadMaster', Props: {Author: 'Lighthouse'}})">
                <mat-icon> cloud_download</mat-icon>
            </a>
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table matTableExporter class="broadcast-message-table responsive-table" #table [dataSource]="tableDataSource" #exporter="matTableExporter" [hiddenColumns]="[6,7]"
            matSort fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">
                
                <!-- MessageText Column -->
                <ng-container matColumnDef="MessageText">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Message Text</mat-header-cell>
                    <mat-cell *matCellDef="let broadcastMessage">
                        <p class="text-truncate">{{broadcastMessage.MessageText}}</p>
                    </mat-cell>
                </ng-container>

                <!-- FromDate Column -->
                <ng-container matColumnDef="FromDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>From Date</mat-header-cell>
                    <mat-cell *matCellDef="let broadcastMessage">
                        <p class="text-truncate">{{broadcastMessage.FromDate | date: Constants.ShortDateFormat}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ToDate Column -->
                <ng-container matColumnDef="ToDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>To Date</mat-header-cell>
                    <mat-cell *matCellDef="let broadcastMessage" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{broadcastMessage.ToDate | date: Constants.ShortDateFormat}}</p>
                    </mat-cell>
                </ng-container>
 
                <!-- ApplicableFor Column -->
                <ng-container matColumnDef="ApplicableFor">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Applicable For</mat-header-cell>
                    <mat-cell *matCellDef="let broadcastMessage" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{broadcastMessage.ApplicableFor}}</p>
                    </mat-cell>
                </ng-container>
 
                <!-- IsActive Column -->
                <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Is Active?</mat-header-cell>
                    <mat-cell *matCellDef="let broadcastMessage" fxHide fxShow.gt-xs>
                        <mat-icon *ngIf="broadcastMessage.IsActive" class="active-icon green-600 s-16">check</mat-icon>
                        <mat-icon *ngIf="!broadcastMessage.IsActive" class="active-icon red-500 s-16">close</mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let broadcastMessage" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                        [routerLink]="['/broadcast-messages', 'view', broadcastMessage.BroadcastMessageId]" skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsEdit" mat-icon-button
                        [routerLink]="['/broadcast-messages', 'edit', broadcastMessage.BroadcastMessageId]" skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>
                        <button mat-icon-button color="warn" (click)="onDeleteBroadcastMessage(broadcastMessage.BroadcastMessageId)"
                            *ngIf="PageRights.IsDelete">
                            <mat-icon>delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let broadcastMessage; columns: displayedColumns;" class="broadcast-message" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
