<div id="forgot-password-history" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    FORGOT PASSWORD HISTORIES
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)" placeholder="Search for a forgot password histories">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <button mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false" class="add-forgot-password-history-button fuse-white mt-24 mt-md-0">
                <span>ADD</span>
            </button>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="forgot-password-history-table" #table [dataSource]="tableDataSource" matSort fusePerfectScrollbar>

                <!-- ForgotPasswordId Column -->
                <ng-container matColumnDef="ForgotPasswordId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>ForgotPasswordId</mat-header-cell>
                    <mat-cell *matCellDef="let forgotPasswordHistory">
                        <p class="text-truncate">{{forgotPasswordHistory.ForgotPasswordId}}</p>
                    </mat-cell>
                </ng-container>

                <!-- EmailId Column -->
                <ng-container matColumnDef="EmailId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>EmailId</mat-header-cell>
                    <mat-cell *matCellDef="let forgotPasswordHistory">
                        <p class="text-truncate">{{forgotPasswordHistory.EmailId}}</p>
                    </mat-cell>
                </ng-container>

                <!-- PasswordResetUrl Column -->
                <ng-container matColumnDef="PasswordResetUrl">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>PasswordResetUrl</mat-header-cell>
                    <mat-cell *matCellDef="let forgotPasswordHistory">
                        <p class="text-truncate">{{forgotPasswordHistory.PasswordResetUrl}}</p>
                    </mat-cell>
                </ng-container>

                <!-- UserIPAddress Column -->
                <ng-container matColumnDef="UserIPAddress">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>UserIPAddress</mat-header-cell>
                    <mat-cell *matCellDef="let forgotPasswordHistory">
                        <p class="text-truncate">{{forgotPasswordHistory.UserIPAddress}}</p>
                    </mat-cell>
                </ng-container>

                <!-- RequestDate Column -->
                <ng-container matColumnDef="RequestDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>RequestDate</mat-header-cell>
                    <mat-cell *matCellDef="let forgotPasswordHistory">
                        <p class="text-truncate">{{forgotPasswordHistory.RequestDate}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ResetPasswordDate Column -->
                <ng-container matColumnDef="ResetPasswordDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>ResetPasswordDate</mat-header-cell>
                    <mat-cell *matCellDef="let forgotPasswordHistory">
                        <p class="text-truncate">{{forgotPasswordHistory.ResetPasswordDate}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions">
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let forgotPasswordHistory" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                        [routerLink]="['/forgot-password-histories', 'view', forgotPasswordHistory.ForgotPasswordId]" skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsEdit" mat-icon-button
                        [routerLink]="['/forgot-password-histories', 'edit', forgotPasswordHistory.ForgotPasswordId]" skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>
                        <button mat-icon-button color="warn" (click)="onDeleteForgotPasswordHistory(forgotPasswordHistory.ForgotPasswordId)"
                            *ngIf="PageRights.IsDelete">
                            <mat-icon>delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let forgotPasswordHistory; columns: displayedColumns;" class="forgot-password-history" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
