<div id="vt-student-exit-survey-detail" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/vt-student-exit-survey-details'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ vtStudentExitSurveyDetailModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New VT Student Exit Survey Detail
                    </div>
                    <div class="subtitle secondary-text">
                        <span>VT Student Exit Survey Detail Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-vt-student-exit-survey-detail-button"
                *ngIf="PageRights.ActionType ==='new'" (click)="saveOrUpdateVTStudentExitSurveyDetailDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-vt-student-exit-survey-detail-button"
                *ngIf="PageRights.ActionType ==='edit'" (click)="saveOrUpdateVTStudentExitSurveyDetailDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="vtStudentExitSurveyDetailForm" [formGroup]="vtStudentExitSurveyDetailForm"
                    class="vt-student-exit-survey-detail w-100-p p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
                    fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>
                    <!-- <mat-tab label="Basic Info"> -->

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Date</mat-label>
                            <input matInput name="date" formControlName="Date" [matDatepicker]="date" required
                                [disabled]="PageRights.IsReadOnly" [max]="CurrentDate" [readonly]="true" (click)="date.open()">
                            <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                            <mat-datepicker #date></mat-datepicker>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.Date.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'Date')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Name Of The Interviewer</mat-label>
                            <input matInput name="interviewerName" formControlName="InterviewerName" required
                                placeholder="Name should be in Capital Letters.">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.InterviewerName.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'InterviewerName')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Designation</mat-label>
                            <input matInput name="designation" formControlName="Designation">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.Designation.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'Designation')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- Part A: General Information -->
                    <mat-divider></mat-divider>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <div class="subtitle-general-information" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <span>Part A: General Information</span>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Name of completed Job Role</mat-label>
                            <input matInput name="completedJobRoleName" formControlName="CompletedJobRoleName" required>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.CompletedJobRoleName.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'CompletedJobRoleName')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" 
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>From Year</mat-label>
                            <input matInput name="fromYear" formControlName="FromYear" [matDatepicker]="fromYear"
                                [max]="CurrentDate" required [disabled]="PageRights.IsReadOnly" [readonly]="true" (click)="fromYear.open()">
                            <mat-datepicker-toggle matSuffix [for]="fromYear"></mat-datepicker-toggle>
                            <mat-datepicker #fromYear></mat-datepicker>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.FromYear.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'FromYear')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>To Year</mat-label>
                            <input matInput name="toYear" formControlName="ToYear" [matDatepicker]="toYear" required
                                [max]="CurrentDate" [disabled]="PageRights.IsReadOnly" [readonly]="true" (click)="toYear.open()">
                            <mat-datepicker-toggle matSuffix [for]="toYear"></mat-datepicker-toggle>
                            <mat-datepicker #toYear></mat-datepicker>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.ToYear.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'ToYear')}}
                            </mat-error>
                        </mat-form-field>
                    </div> 

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Roll No./ Seat No. of the Student provided by Board</mat-label>
                            <input matInput name="rollNo" formControlName="RollNo" digitOnly required>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.RollNo.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'RollNo')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Year</mat-label>
                            <input matInput name="year" formControlName="Year" [matDatepicker]="year" required
                                [disabled]="PageRights.IsReadOnly" [readonly]="true" (click)="year.open()">
                            <mat-datepicker-toggle matSuffix [for]="year"></mat-datepicker-toggle>
                            <mat-datepicker #year></mat-datepicker>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.Year.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'Year')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Student First Name</mat-label>
                            <input matInput name="studentFirstName" formControlName="StudentFirstName">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.StudentFirstName.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'StudentFirstName')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Student Middle Name</mat-label>
                            <input matInput name="studentMiddleName" formControlName="StudentMiddleName">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.StudentMiddleName.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'StudentMiddleName')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Student Last Name</mat-label>
                            <input matInput name="StudentLastName" formControlName="StudentLastName">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.StudentLastName.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'StudentLastName')}}
                            </mat-error>
                        </mat-form-field>
                    </div> 

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Gender</mat-label>
                            <mat-select name="gender" formControlName="Gender" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="genderItem.Id" *ngFor="let genderItem of genderList">
                                    {{ genderItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.Gender.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'Gender')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Date Of Birth</mat-label>
                            <input matInput [matDatepicker]="dateOfBirth" name="dateOfBirth" required
                                formControlName="DateOfBirth" [max]="MinimumAgeChildrenDate" 
                                (dateChange)="onChangeDateCalculateAge($event)"
                                [disabled]="PageRights.IsReadOnly" [readonly]="true" (click)="dateOfBirth.open()">
                            <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
                            <mat-datepicker #dateOfBirth></mat-datepicker>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.DateOfBirth.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'DateOfBirth')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Age</mat-label>
                            <input matInput name="age" formControlName="Age" required
                                digitOnly>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.Age.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'Age')}}
                            </mat-error>
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Mobile No of Student</mat-label>
                            <input matInput name="studentMobileNumber" formControlName="StudentMobileNumber" required
                                digitOnly minlength="10" maxlength="10" placeholder="Ex. 9800098000">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.StudentMobileNumber.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'StudentMobileNumber')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Student First Relative Name</mat-label>
                            <input matInput name="studentFirstRelativeName" formControlName="StudentFirstRelativeName"
                                required>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.StudentFirstRelativeName.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'StudentFirstRelativeName')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Student First Relative Relation</mat-label>
                            <input matInput name="StudentFirstRelativeRelation"
                                formControlName="StudentFirstRelativeRelation" required>
                            <mat-error
                                *ngIf="vtStudentExitSurveyDetailForm.controls.StudentFirstRelativeRelation.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'StudentFirstRelativeRelation')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Student First Relative Mobile</mat-label>
                            <input matInput name="StudentFirstRelativeMobile"
                                formControlName="StudentFirstRelativeMobile" digitOnly minlength="10" maxlength="10"
                                placeholder="Ex. 9800098000">
                            <mat-error
                                *ngIf="vtStudentExitSurveyDetailForm.controls.StudentFirstRelativeMobile.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'StudentFirstRelativeMobile')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Student Second Relative Name</mat-label>
                            <input matInput name="studentSecondRelativeName" formControlName="StudentSecondRelativeName"
                                required>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.StudentSecondRelativeName.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'StudentSecondRelativeName')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Student Second Relative Relation</mat-label>
                            <input matInput name="StudentSecondRelativeRelation"
                                formControlName="StudentSecondRelativeRelation" required>
                            <mat-error
                                *ngIf="vtStudentExitSurveyDetailForm.controls.StudentSecondRelativeRelation.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'StudentSecondRelativeRelation')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Student Second Relative Mobile</mat-label>
                            <input matInput name="StudentSecondRelativeMobile"
                                formControlName="StudentSecondRelativeMobile" digitOnly minlength="10" maxlength="10"
                                placeholder="Ex. 9800098000">
                            <mat-error
                                *ngIf="vtStudentExitSurveyDetailForm.controls.StudentSecondRelativeMobile.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'StudentSecondRelativeMobile')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>How many family members you have sharing the same kitchen</mat-label>
                            <input matInput name="familyMemberCount" formControlName="FamilyMemberCount" digitOnly>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.FamilyMemberCount.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'FamilyMemberCount')}}
                            </mat-error>
                        </mat-form-field>

                            <mat-label>Does your family members migrate in any time of the year?</mat-label>
                            <mat-radio-group aria-labelledby="Does your family members migrate in any time of the year?" 
                            name="familyMemberMigrate" formControlName="FamilyMemberMigrate" required 
                            appearance="outline" fxFlex="22"fxFlex.lt-md="75" fxFlex.lt-sm="100" 
                            fxLayoutGap="20px" class="radio-space-tc"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-radio-button value="Yes">Yes</mat-radio-button>
                                <mat-radio-button value="No">No</mat-radio-button>
                            </mat-radio-group>
                            <!-- <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.FamilyMemberMigrate.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'FamilyMemberMigrate')}}
                            </mat-error> -->
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Reasons of migration</mat-label>
                            <mat-select name="reasonOfMigration" formControlName="ReasonOfMigration" required
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="migrationReasonItem.Id" *ngFor="let migrationReasonItem of migrationReasonList">
                                    {{ migrationReasonItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.ReasonOfMigration.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'ReasonOfMigration')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Pleasemention</mat-label>
                            <input matInput name="pleasemention" formControlName="Pleasemention">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.Pleasemention.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'Pleasemention')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>What is the occupation of your Father</mat-label>
                            <mat-select name="fatherOccupation" formControlName="FatherOccupation" required
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="parentsOccupationItem.Id" *ngFor="let parentsOccupationItem of parentsOccupationList">
                                    {{ parentsOccupationItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.FatherOccupation.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'FatherOccupation')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Father Occupation Details</mat-label>
                            <input matInput name="fatherOccupationDetails" formControlName="FatherOccupationDetails">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.FatherOccupationDetails.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'FatherOccupationDetails')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>What is the occupation of your Mother</mat-label>
                            <mat-select name="motherOccupation" formControlName="MotherOccupation" required
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="parentsOccupationItem.Id" *ngFor="let parentsOccupationItem of parentsOccupationList">
                                    {{ parentsOccupationItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.MotherOccupation.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'MotherOccupation')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Mother Occupation Details</mat-label>
                            <input matInput name="motherOccupationDetails" formControlName="MotherOccupationDetails">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.MotherOccupationDetails.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'MotherOccupationDetails')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                            <mat-label>Do you have knowledge about registration in employment exchange?</mat-label>
                            <mat-radio-group 
                            name="employementExchangeKnowledge" formControlName="EmployementExchangeKnowledge" required 
                            appearance="outline" fxFlex="20"fxFlex.lt-md="75" fxFlex.lt-sm="100" 
                            fxLayoutGap="20px" class="radio-space-tc"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-radio-button value="Yes">Yes</mat-radio-button>
                                <mat-radio-button value="No">No</mat-radio-button>
                            </mat-radio-group>
                            <!-- <mat-error
                                *ngIf="vtStudentExitSurveyDetailForm.controls.EmployementExchangeKnowledge.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'EmployementExchangeKnowledge')}}
                            </mat-error> -->
                        
                        
                            <mat-label>Have you enrolled yourself with Employment Office?</mat-label>
                            <mat-radio-group 
                            name="enrolledInEmploymentOffice" formControlName="EnrolledInEmploymentOffice" required 
                            appearance="outline" fxFlex="25"fxFlex.lt-md="75" fxFlex.lt-sm="100" 
                            fxLayoutGap="20px" class="radio-space-tc"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-radio-button value="Yes">Yes</mat-radio-button>
                                <mat-radio-button value="No">No</mat-radio-button>
                            </mat-radio-group>
                            <!-- <mat-error
                                *ngIf="vtStudentExitSurveyDetailForm.controls.EnrolledInEmploymentOffice.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'EnrolledInEmploymentOffice')}}
                            </mat-error> -->
                    </div>
                    <!-- Part B: Present Employment/ Self- Employment -->
                    <mat-divider style="margin-top:1%"></mat-divider>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <div class="subtitle-general-information" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <span>Part B: Present Employment/ Self- Employment</span>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Current Employment Status</mat-label>
                            <mat-select name="currentEmployementStatus" formControlName="CurrentEmployementStatus" required
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="currentEmployementStatusItem.Id" *ngFor="let currentEmployementStatusItem of currentEmployementStatusList">
                                    {{ currentEmployementStatusItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.CurrentEmployementStatus.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'CurrentEmployementStatus')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.CurrentEmployementStatus.value == '278'">
                            <mat-label>Please mention where? </mat-label>
                            <input matInput name="employementOrganisation" formControlName="EmployementOrganisation">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.EmployementOrganisation.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'EmployementOrganisation')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                            <mat-label>Nature of Work</mat-label>
                            <mat-radio-group name="natureOfWork" formControlName="NatureOfWork" required 
                            appearance="outline" fxFlex="38"fxFlex.lt-md="75" fxFlex.lt-sm="100" 
                            fxLayoutGap="20px" class="radio-space-tc"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-radio-button value="PartTime">Part Time</mat-radio-button>
                                <mat-radio-button value="FullTime">Full Time</mat-radio-button>
                            </mat-radio-group>
                            <!-- <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.NatureOfWork.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'NatureOfWork')}}
                            </mat-error> -->

                        
                            <mat-label class="margin-left">Whether employment/ Self-employment in your vocational education sector?
                            </mat-label>
                                <mat-radio-group name="employementInVocationalEducationSector"
                                formControlName="EmployementInVocationalEducationSector" required 
                            appearance="outline" fxFlex="14"fxFlex.lt-md="75" fxFlex.lt-sm="100" 
                            fxLayoutGap="20px" class="radio-space-tc"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-radio-button value="Yes">Yes</mat-radio-button>
                                <mat-radio-button value="No">No</mat-radio-button>
                            </mat-radio-group>
                            <!-- <mat-error
                                *ngIf="vtStudentExitSurveyDetailForm.controls.EmployementInVocationalEducationSector.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm,
                                'EmployementInVocationalEducationSector')}}
                            </mat-error> -->

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.EmployementInVocationalEducationSector.value == 'Yes'">
                            <mat-label>Please mention the details of work </mat-label>
                            <input matInput name="workDetails" formControlName="WorkDetails">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.WorkDetails.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'WorkDetails')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                    <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.EmployementInVocationalEducationSector.value == 'Yes'">
                            <mat-label>Please mention the details of work </mat-label>
                            <input matInput name="workDetails" formControlName="WorkDetails">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.WorkDetails.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'WorkDetails')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Present earnings in per month in </mat-label>
                            <input matInput name="earningsPerMonth" formControlName="EarningsPerMonth">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.EarningsPerMonth.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'EarningsPerMonth')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- Part C: Information related to near future plans of the student -->
                    <mat-divider></mat-divider>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <div class="subtitle-general-information" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <span>Part C: Information related to near future plans of the student</span>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                            <mat-label>Will you continue your studies </mat-label>
                            <mat-radio-group name="continueStudies" formControlName="ContinueStudies" required 
                        appearance="outline" fxFlex="38"fxFlex.lt-md="75" fxFlex.lt-sm="100" 
                        fxLayoutGap="20px" class="radio-space-tc"
                            [disabled]="PageRights.IsReadOnly">
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                            <mat-radio-button value="PartTime">Part Time</mat-radio-button>
                            <mat-radio-button value="No">No</mat-radio-button>
                        </mat-radio-group>
                            <!-- <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.ContinueStudies.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'ContinueStudies')}}
                            </mat-error> -->
                    </div>
                    <!-- In case of ‘Yes’ and ‘Part Time Education’ -->
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.ContinueStudies.value == 'Yes' || 
                        vtStudentExitSurveyDetailForm.controls.ContinueStudies.value == 'PartTime'">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Which course will you pursue? </mat-label>
                            <mat-select name="coursePursue" formControlName="CoursePursue" required
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="courseItem.Id" *ngFor="let courseItem of courseList">
                                    {{ courseItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.CoursePursue.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'CoursePursue')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Please mention the details of the course you want to pursue </mat-label>
                            <input matInput name="courseDetails" formControlName="CourseDetails">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.CourseDetails.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'CourseDetails')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.ContinueStudies.value == 'Yes' || 
                        vtStudentExitSurveyDetailForm.controls.ContinueStudies.value == 'PartTime'">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Why have you selected this course post 12th class for yourself? </mat-label>
                            <mat-select name="courseSelectionReason" formControlName="CourseSelectionReason" required
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="courseSelectionReasonItem.Id" *ngFor="let courseSelectionReasonItem of courseSelectionReasonList">
                                    {{ courseSelectionReasonItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.CourseSelectionReason.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'CourseSelectionReason')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Please mention Details: </mat-label>
                            <input matInput name="reasonDetails" formControlName="ReasonDetails">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.ReasonDetails.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'ReasonDetails')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.ContinueStudies.value == 'Yes' || 
                        vtStudentExitSurveyDetailForm.controls.ContinueStudies.value == 'PartTime'">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Post completion of the course what are you planning to do?</mat-label>
                                <mat-select name="postCourseCompletionPlanning" formControlName="PostCourseCompletionPlanning" required
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="postCourseCpmletionPlanningItem.Id" *ngFor="let postCourseCpmletionPlanningItem of postCourseCpmletionPlanningList">
                                    {{ postCourseCpmletionPlanningItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="vtStudentExitSurveyDetailForm.controls.PostCourseCompletionPlanning.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'PostCourseCompletionPlanning')}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.PostCourseCompletionPlanning.value == '292'">
                            <mat-label>Targeted employer </mat-label>
                            <input matInput name="targetedEmployer" formControlName="TargetedEmployer">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.TargetedEmployer.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'TargetedEmployer')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.PostCourseCompletionPlanning.value == '293'">
                            <mat-label>How will you get capital?</mat-label>
                            <input matInput name="capitalSource" formControlName="CapitalSource">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.CapitalSource.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'CapitalSource')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.PostCourseCompletionPlanning.value == '294'">
                            <mat-label>Detail</mat-label>
                            <input matInput name="otherDetails" formControlName="OtherDetails">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.OtherDetails.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'OtherDetails')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.ContinueStudies.value == 'Yes' || 
                        vtStudentExitSurveyDetailForm.controls.ContinueStudies.value == 'PartTime'">
                        
                    </div> -->

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.ContinueStudies.value == 'Yes' || 
                        vtStudentExitSurveyDetailForm.controls.ContinueStudies.value == 'PartTime'">
                            <mat-label>Will any of your learnings from vocational education will help you to
                                successfully complete this course? </mat-label>
                            <mat-radio-group name="veHelpCourseCompletion" formControlName="VEHelpCourseCompletion" required 
                        appearance="outline" fxFlex="38"fxFlex.lt-md="75" fxFlex.lt-sm="100" 
                        fxLayoutGap="20px" class="radio-space-tc"
                            [disabled]="PageRights.IsReadOnly">
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                            <mat-radio-button value="PartTime">Part Time</mat-radio-button>
                            <mat-radio-button value="No">No</mat-radio-button>
                        </mat-radio-group>
                            <!-- <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.VEHelpCourseCompletion.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'VEHelpCourseCompletion')}}
                            </mat-error> -->

                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.ContinueStudies.value == 'Yes' || 
                        vtStudentExitSurveyDetailForm.controls.ContinueStudies.value == 'PartTime'">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Any other information:</mat-label>
                            <input matInput name="otherInformation" formControlName="OtherInformation">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.OtherInformation.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'OtherInformation')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- In case of ‘NO’then: -->
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.ContinueStudies.value == 'No'">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Why will you discontinue your further education?</mat-label>
                                <mat-select name="discontinueEducationReason" formControlName="DiscontinueEducationReason" required
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="educationDiscontinueReasonItem.Id" *ngFor="let educationDiscontinueReasonItem of educationDiscontinueReasonList">
                                    {{ educationDiscontinueReasonItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="vtStudentExitSurveyDetailForm.controls.DiscontinueEducationReason.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'DiscontinueEducationReason')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px" 
                        *ngIf="vtStudentExitSurveyDetailForm.controls.ContinueStudies.value == 'No'">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>What are your plans?</mat-label>
                            <mat-select name="plans" formControlName="Plans" required
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="plansItem.Id" *ngFor="let plansItem of plansList">
                                    {{ plansItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.Plans.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'Plans')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Details: </mat-label>
                            <input matInput name="plansDetails" formControlName="PlansDetails">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.PlansDetails.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'PlansDetails')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px" 
                        *ngIf="vtStudentExitSurveyDetailForm.controls.ContinueStudies.value == 'No'">
                            <mat-label>Whether your plans are related with your Vocational Education Sector?
                            </mat-label>
                            <mat-radio-group name="plansRelatedToVESector" formControlName="PlansRelatedToVESector" required 
                            appearance="outline" fxFlex="10"fxFlex.lt-md="75" fxFlex.lt-sm="100" 
                            fxLayoutGap="20px" class="radio-space-tc"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-radio-button value="Yes">Yes</mat-radio-button>
                                <mat-radio-button value="No">No</mat-radio-button>
                            </mat-radio-group>
                            <!-- <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.PlansRelatedToVESector.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'PlansRelatedToVESector')}}
                            </mat-error> -->

                            <mat-label class="margin-left-VEHelpsToTakeDecision">Whether Vocational Education is helping you to take decision? </mat-label>
                            <mat-radio-group name="veHelpsToTakeDecision" formControlName="VEHelpsToTakeDecision" required 
                            appearance="outline" fxFlex="20"fxFlex.lt-md="75" fxFlex.lt-sm="100" 
                            fxLayoutGap="20px" class="radio-space-tc"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-radio-button value="Yes">Yes</mat-radio-button>
                                <mat-radio-button value="No">No</mat-radio-button>
                            </mat-radio-group>
                            <!-- <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.VEHelpsToTakeDecision.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'VEHelpsToTakeDecision')}}
                            </mat-error> -->
                    </div>
                    <!-- common in both -->
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px" 
                        *ngIf="vtStudentExitSurveyDetailForm.controls.ContinueStudies.value == 'Yes'|| 
                        vtStudentExitSurveyDetailForm.controls.ContinueStudies.value == 'PartTime' || 
                        vtStudentExitSurveyDetailForm.controls.ContinueStudies.value == 'No'">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Expected income (Monthly Rs.): </mat-label>
                            <input matInput name="expectedIncome" formControlName="ExpectedIncome">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.ExpectedIncome.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'ExpectedIncome')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <span class="checkbox-space">Is Active?</span>
                        <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                        </mat-checkbox>
                    </div> -->
                    <!-- </mat-tab>
    </mat-tab-group> -->
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>