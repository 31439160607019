import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant'
import { InternshipfeedbackService } from '../internshipfeedback.service';
import { InternshipfeedbackModel } from '../internshipfeedback.model';
import { DropdownModel } from 'app/models/dropdown.model';
import { FileUploadModel } from 'app/models/file.upload.model';
import { FuseUtils } from '@fuse/utils';

@Component({
  selector: 'internshipfeedback',
  templateUrl: './create-internshipfeedback.component.html',
  styleUrls: ['./create-internshipfeedback.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateInternshipfeedbackComponent extends BaseComponent<InternshipfeedbackModel> implements OnInit {
  internshipfeedbackForm: FormGroup;
  internshipfeedbackModel: InternshipfeedbackModel;
  vocationalTrainerList: DropdownModel[];
  studentList: DropdownModel[];

  AttachmentURL: string;
  AttachmentURL2: string;
  AttachmentURL3: string;

  mobile2: string;

  attachmentFile: FileUploadModel;
  attachmentFile2: FileUploadModel;
  attachmentFile3: FileUploadModel;

  ImageUrlGCSTag: string;
  ImageUrlGCS2Tag: string;
  ImageUrlGCS3Tag: string;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private internshipfeedbackService: InternshipfeedbackService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);
    this.attachmentFile = new FileUploadModel();
    this.attachmentFile2 = new FileUploadModel();
    this.attachmentFile3 = new FileUploadModel();

    // Set the default internshipfeedback Model
    this.internshipfeedbackModel = new InternshipfeedbackModel();
  }

  ngOnInit(): void {


    this.internshipfeedbackService.getStateDivisions(this.UserModel).subscribe(results => {

      if (results[0].Success) {
        this.vocationalTrainerList = results[0].Results;
      }

      if (results[1].Success) {
        this.studentList = results[1].Results;
      }

      this.route.paramMap.subscribe(params => {
        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.internshipfeedbackModel = new InternshipfeedbackModel();

          } else {
            var internshipfeedbackId: string = params.get('internshipfeedbackId')

            this.internshipfeedbackService.getInternshipfeedbackById(internshipfeedbackId)
              .subscribe((response: any) => {
                this.internshipfeedbackModel = response.Result;
                this.internshipfeedbackModel.EnjoyOrientationOption = response.Result.EnjoyOrientationOption.split(', ');
                this.internshipfeedbackModel.ImprovementInOrientationOption = response.Result.ImprovementInOrientationOption.split(', ');
                this.internshipfeedbackModel.ThingsLikedInSupervisor = response.Result.ThingsLikedInSupervisor.split(', ');
                this.internshipfeedbackModel.ImprovementInSupervisor = response.Result.ImprovementInSupervisor.split(', ');
                this.internshipfeedbackModel.VTActivities = response.Result.VTActivities.split(', ');
                this.internshipfeedbackModel.OverallInternshipExperience = response.Result.OverallInternshipExperience.split(', ');
                this.internshipfeedbackModel.FaceChallenge = response.Result.FaceChallenge.split(', ');
                this.internshipfeedbackModel.SkillsDevelopInInternship = response.Result.SkillsDevelopInInternship.split(', ');
                this.internshipfeedbackModel.LearnInInternship = response.Result.LearnInInternship.split(', ');


                if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                  this.internshipfeedbackModel.RequestType = this.Constants.PageType.Edit;
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.internshipfeedbackModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }
                this.AttachmentURL = this.internshipfeedbackModel.Attachment;
                this.AttachmentURL2 = this.internshipfeedbackModel.Attachment2;
                this.AttachmentURL3 = this.internshipfeedbackModel.Attachment3;

                this.ImageUrlGCSTag = this.internshipfeedbackModel.ImageUrlGCS;
                this.ImageUrlGCS2Tag = this.internshipfeedbackModel.ImageUrlGCS2;
                this.ImageUrlGCS3Tag = this.internshipfeedbackModel.ImageUrlGCS3;

                this.internshipfeedbackForm = this.createInternshipfeedbackForm();
              });
          }
        }
      });
    });

    this.internshipfeedbackForm = this.createInternshipfeedbackForm();
  }

  uploadedScreenshotFile(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedImageExtensions.indexOf(fileExtn) == -1) {
        this.internshipfeedbackForm.get('ScreenshotFile').setValue(null);
        this.dialogService.openShowDialog("Please upload Image file only.");
        return;
      }


      this.getUploadedFileData(event, this.Constants.DocumentType.InternshipfeedbackScreenshot).then((response: FileUploadModel) => {
        this.attachmentFile = response;
      });
    }
  }

  uploadedScreenshotFile2(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedImageExtensions.indexOf(fileExtn) == -1) {
        this.internshipfeedbackForm.get('ScreenshotFile2').setValue(null);
        this.dialogService.openShowDialog("Please upload Image file only.");
        return;
      }


      this.getUploadedFileData(event, this.Constants.DocumentType.InternshipfeedbackScreenshot).then((response: FileUploadModel) => {
        this.attachmentFile2 = response;
      });
    }
  }

  uploadedScreenshotFile3(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedImageExtensions.indexOf(fileExtn) == -1) {
        this.internshipfeedbackForm.get('ScreenshotFile3').setValue(null);
        this.dialogService.openShowDialog("Please upload Image file only.");
        return;
      }


      this.getUploadedFileData(event, this.Constants.DocumentType.InternshipfeedbackScreenshot).then((response: FileUploadModel) => {
        this.attachmentFile3 = response;
      });
    }
  }

  onChangeVocationalTrainer(VTId) {
    if (this.UserModel.RoleCode === 'VC') {
      this.commonService.GetMasterDataByType({ DataType : 'StudentsByVt', ParentId: VTId, SelectTitle: 'Select Student' })
      .subscribe((response: any) => {
        if(response.Success){
          this.studentList = response.Results;
        }
      });
    }
  }

  onChangeStudent(StudentId: any) {
    this.commonService.GetMasterDataByType({ DataType: 'StudentMobile', ParentId: StudentId, SelectTitle: 'StudentMobile' }).subscribe((response: any) => {

      if (response.Results[1]) {
        this.mobile2 = response.Results[1].Description;
        this.internshipfeedbackForm.get('WhatsappMobile').setValue(this.mobile2);
      } else {
        this.internshipfeedbackForm.get('WhatsappMobile').setValue('');
      }

    });
  }

  saveOrUpdateInternshipfeedbackDetails() {
    if (!this.internshipfeedbackForm.valid) {
      this.validateAllFormFields(this.internshipfeedbackForm);
      return;
    }

    var EnjoyOrientationOption = this.internshipfeedbackForm.get('EnjoyOrientationOption').value;
    var ImprovementInOrientationOption = this.internshipfeedbackForm.get('ImprovementInOrientationOption').value;
    var ThingsLikedInSupervisor = this.internshipfeedbackForm.get('ThingsLikedInSupervisor').value;
    var ImprovementInSupervisor = this.internshipfeedbackForm.get('ImprovementInSupervisor').value;
    var VTActivities = this.internshipfeedbackForm.get('VTActivities').value;
    var OverallInternshipExperience = this.internshipfeedbackForm.get('OverallInternshipExperience').value;
    var FaceChallenge = this.internshipfeedbackForm.get('FaceChallenge').value;
    var SkillsDevelopInInternship = this.internshipfeedbackForm.get('SkillsDevelopInInternship').value;
    var LearnInInternship = this.internshipfeedbackForm.get('LearnInInternship').value;

    this.setValueFromFormGroup(this.internshipfeedbackForm, this.internshipfeedbackModel);

    if (EnjoyOrientationOption) {
      this.internshipfeedbackModel.EnjoyOrientationOption = EnjoyOrientationOption.join(', ');
    }
    if (ImprovementInOrientationOption) {
      this.internshipfeedbackModel.ImprovementInOrientationOption = ImprovementInOrientationOption.join(', ');
    }
    if (ThingsLikedInSupervisor) {
      this.internshipfeedbackModel.ThingsLikedInSupervisor = ThingsLikedInSupervisor.join(', ');
    }
    if (ImprovementInSupervisor) {
      this.internshipfeedbackModel.ImprovementInSupervisor = ImprovementInSupervisor.join(', ');
    }
    if (VTActivities) {
      this.internshipfeedbackModel.VTActivities = VTActivities.join(', ');
    }
    if (OverallInternshipExperience) {
      this.internshipfeedbackModel.OverallInternshipExperience = OverallInternshipExperience.join(', ');
    }
    if (FaceChallenge) {
      this.internshipfeedbackModel.FaceChallenge = FaceChallenge.join(', ');
    }
    if (SkillsDevelopInInternship) {
      this.internshipfeedbackModel.SkillsDevelopInInternship = SkillsDevelopInInternship.join(', ');
    }
    if (LearnInInternship) {
      this.internshipfeedbackModel.LearnInInternship = LearnInInternship.join(', ');
    }

    if (this.attachmentFile.Base64Data != '') {
      this.internshipfeedbackModel.ScreenshotFile = new FileUploadModel({
        UserId: FuseUtils.NewGuid(),
        ContentId: null,
        FilePath: null,
        ContentType: this.attachmentFile.ContentType,
        FileName: this.attachmentFile.FileName,
        FileType: this.attachmentFile.FileType,
        FileSize: this.attachmentFile.FileSize,
        Base64Data: this.attachmentFile.Base64Data
      });
    }

    if (this.attachmentFile2.Base64Data != '') {
      this.internshipfeedbackModel.ScreenshotFile2 = new FileUploadModel({
        UserId: FuseUtils.NewGuid(),
        ContentId: null,
        FilePath: null,
        ContentType: this.attachmentFile2.ContentType,
        FileName: this.attachmentFile2.FileName,
        FileType: this.attachmentFile2.FileType,
        FileSize: this.attachmentFile2.FileSize,
        Base64Data: this.attachmentFile2.Base64Data
      });
    }

    if (this.attachmentFile3.Base64Data != '') {
      this.internshipfeedbackModel.ScreenshotFile3 = new FileUploadModel({
        UserId: FuseUtils.NewGuid(),
        ContentId: null,
        FilePath: null,
        ContentType: this.attachmentFile3.ContentType,
        FileName: this.attachmentFile3.FileName,
        FileType: this.attachmentFile3.FileType,
        FileSize: this.attachmentFile3.FileSize,
        Base64Data: this.attachmentFile3.Base64Data
      });
    }

    this.internshipfeedbackService.createOrUpdateInternshipfeedback(this.internshipfeedbackModel)
      .subscribe((internshipfeedbackResp: any) => {

        if (internshipfeedbackResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.Internshipfeedback.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(internshipfeedbackResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('Internshipfeedback deletion errors =>', error);
      });
  }

  //Create Internshipfeedback form and returns {FormGroup}
  createInternshipfeedbackForm(): FormGroup {
    return this.formBuilder.group({
      InternshipfeedbackId: new FormControl(this.internshipfeedbackModel.InternshipfeedbackId),

      StudentId: new FormControl({ value: this.internshipfeedbackModel.StudentId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      WhatsappMobile: new FormControl({ value: this.internshipfeedbackModel.WhatsappMobile, disabled: this.PageRights.IsReadOnly }),
      DateOfActivity: new FormControl({ value: this.getDateValue(this.internshipfeedbackModel.DateOfActivity), disabled: this.PageRights.IsReadOnly }),

      WasOrientationOrganized: new FormControl({ value: this.internshipfeedbackModel.WasOrientationOrganized, disabled: this.PageRights.IsReadOnly }),
      ParticipateInOrientation: new FormControl({ value: this.internshipfeedbackModel.ParticipateInOrientation, disabled: this.PageRights.IsReadOnly }),
      EnjoyOrientation: new FormControl({ value: this.internshipfeedbackModel.EnjoyOrientation, disabled: this.PageRights.IsReadOnly }),
      EnjoyOrientationOption: new FormControl({ value: this.internshipfeedbackModel.EnjoyOrientationOption, disabled: this.PageRights.IsReadOnly }),
      NotEnjoyOrientationText: new FormControl({ value: this.internshipfeedbackModel.NotEnjoyOrientationText, disabled: this.PageRights.IsReadOnly }),
      ImprovementInOrientationOption: new FormControl({ value: this.internshipfeedbackModel.ImprovementInOrientationOption, disabled: this.PageRights.IsReadOnly }),
      ImprovementInOrientationText: new FormControl({ value: this.internshipfeedbackModel.ImprovementInOrientationText, disabled: this.PageRights.IsReadOnly }),
      InternshipStartAt: new FormControl({ value: this.getDateValue(this.internshipfeedbackModel.InternshipStartAt), disabled: this.PageRights.IsReadOnly }),
      InternshipEndtAt: new FormControl({ value: this.getDateValue(this.internshipfeedbackModel.InternshipEndtAt), disabled: this.PageRights.IsReadOnly }),
      Supervisor: new FormControl({ value: this.internshipfeedbackModel.Supervisor, disabled: this.PageRights.IsReadOnly }),
      SatisfiedWithSupervisor: new FormControl({ value: this.internshipfeedbackModel.SatisfiedWithSupervisor, disabled: this.PageRights.IsReadOnly }),
      ThingsLikedInSupervisor: new FormControl({ value: this.internshipfeedbackModel.ThingsLikedInSupervisor, disabled: this.PageRights.IsReadOnly }),
      ThingsLikedInSupervisorText: new FormControl({ value: this.internshipfeedbackModel.ThingsLikedInSupervisorText, disabled: this.PageRights.IsReadOnly }),
      ImprovementInSupervisor: new FormControl({ value: this.internshipfeedbackModel.ImprovementInSupervisor, disabled: this.PageRights.IsReadOnly }),
      ImprovementInSupervisorText: new FormControl({ value: this.internshipfeedbackModel.ImprovementInSupervisorText, disabled: this.PageRights.IsReadOnly }),
      VTActivities: new FormControl({ value: this.internshipfeedbackModel.VTActivities, disabled: this.PageRights.IsReadOnly }),
      SupervisorMaintainAttendance: new FormControl({ value: this.internshipfeedbackModel.SupervisorMaintainAttendance, disabled: this.PageRights.IsReadOnly }),
      OverallInternshipExperience: new FormControl({ value: this.internshipfeedbackModel.OverallInternshipExperience, disabled: this.PageRights.IsReadOnly }),
      FaceChallenge: new FormControl({ value: this.internshipfeedbackModel.FaceChallenge, disabled: this.PageRights.IsReadOnly }),
      FaceChallengeText: new FormControl({ value: this.internshipfeedbackModel.FaceChallengeText, disabled: this.PageRights.IsReadOnly }),
      SkillsDevelopInInternship: new FormControl({ value: this.internshipfeedbackModel.SkillsDevelopInInternship, disabled: this.PageRights.IsReadOnly }),
      SkillsDevelopInInternshipText: new FormControl({ value: this.internshipfeedbackModel.SkillsDevelopInInternshipText, disabled: this.PageRights.IsReadOnly }),
      LearnInInternship: new FormControl({ value: this.internshipfeedbackModel.LearnInInternship, disabled: this.PageRights.IsReadOnly }),
      LearnInInternshipText: new FormControl({ value: this.internshipfeedbackModel.LearnInInternshipText, disabled: this.PageRights.IsReadOnly }),
      OverallFeedbackInternship: new FormControl({ value: this.internshipfeedbackModel.OverallFeedbackInternship, disabled: this.PageRights.IsReadOnly }),
      PrepareInternshipReport: new FormControl({ value: this.internshipfeedbackModel.PrepareInternshipReport, disabled: this.PageRights.IsReadOnly }),

      ScreenshotFile: new FormControl({ value: this.internshipfeedbackModel.ScreenshotFile, disabled: this.PageRights.IsReadOnly }),
      ScreenshotFile2: new FormControl({ value: this.internshipfeedbackModel.ScreenshotFile2, disabled: this.PageRights.IsReadOnly }),
      ScreenshotFile3: new FormControl({ value: this.internshipfeedbackModel.ScreenshotFile3, disabled: this.PageRights.IsReadOnly }),
    });
  }
}
