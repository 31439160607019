<div id="course-material" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    COURSE MATERIALS
                    <a [routerLink]="['./']" skipLocationChange="true" replaceUrl="false" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)" placeholder="Search for a course materials">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <button mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false" class="add-course-material-button fuse-white mt-24 mt-md-0">
                <span>ADD</span>
            </button>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="course-material-table responsive-table" #table [dataSource]="tableDataSource" matSort fusePerfectScrollbar>

                <!-- AcademicYear Column -->
                <ng-container matColumnDef="AcademicYear">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Academic Year</mat-header-cell>
                    <mat-cell *matCellDef="let courseMaterial" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{courseMaterial.AcademicYear}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ClassName Column -->
                <ng-container matColumnDef="ClassName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Class</mat-header-cell>
                    <mat-cell *matCellDef="let courseMaterial">
                        <p class="text-truncate">{{courseMaterial.ClassName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ReceiptDate Column -->
                <ng-container matColumnDef="ReceiptDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Receipt Date</mat-header-cell>
                    <mat-cell *matCellDef="let courseMaterial">
                        <p class="text-truncate">{{courseMaterial.ReceiptDate | date: Constants.ShortDateFormat }}</p>
                    </mat-cell>
                </ng-container>

                <!-- CMStatus Column -->
                <ng-container matColumnDef="CMStatus">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>CM Status</mat-header-cell>
                    <mat-cell *matCellDef="let courseMaterial" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{courseMaterial.CMStatus}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Details Column -->
                <ng-container matColumnDef="Details">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Details</mat-header-cell>
                    <mat-cell *matCellDef="let courseMaterial" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{courseMaterial.Details}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let courseMaterial" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                        [routerLink]="['/course-materials', 'view', courseMaterial.CourseMaterialId]" skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsEdit" mat-icon-button
                        [routerLink]="['/course-materials', 'edit', courseMaterial.CourseMaterialId]" skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>
                        <button mat-icon-button color="warn" (click)="onDeleteCourseMaterial(courseMaterial.CourseMaterialId)"
                            *ngIf="PageRights.IsDelete">
                            <mat-icon>delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let courseMaterial; columns: displayedColumns;" class="course-material" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
