import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';

@Injectable()
export class VTStudentExitSurveyDetailService {
    constructor(private http: BaseService) { }

    getVTStudentExitSurveyDetails(): Observable<any> {
        return this.http
            .HttpGet(this.http.Services.VTStudentPlacementDetail.GetAll)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetAllByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.VTStudentPlacementDetail.GetAllByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    getVTStudentExitSurveyDetailById(vtStudentExitSurveyDetailId: string) {
        var requestParams = {
            DataId: vtStudentExitSurveyDetailId
        };

        return this.http
            .HttpPost(this.http.Services.VTStudentPlacementDetail.GetById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    createOrUpdateVTStudentExitSurveyDetail(formData: any) {
        return this.http
            .HttpPost(this.http.Services.VTStudentPlacementDetail.CreateOrUpdate, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    deleteVTStudentExitSurveyDetailById(vtStudentExitSurveyDetailId: string) {
        var vtStudentExitSurveyDetailParams = {
            DataId: vtStudentExitSurveyDetailId
        };

        return this.http
            .HttpPost(this.http.Services.VTStudentPlacementDetail.Delete, vtStudentExitSurveyDetailParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    getClassesAndStudents(): Observable<any[]> {
        let studentRequest = this.http.GetMasterDataByType({ DataType: 'Students', SelectTitle: 'Student' });

        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([studentRequest]);
    }
}
