<div id="account-transaction" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/account-transactions'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ accountTransactionModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New User Transaction
                    </div>
                    <div class="subtitle secondary-text">
                        <span>User Transaction Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-account-transaction-button" *ngIf="PageRights.ActionType ==='new'"
                (click)="saveOrUpdateAccountTransactionDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-account-transaction-button" *ngIf="PageRights.ActionType ==='edit'"
                (click)="saveOrUpdateAccountTransactionDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="accountTransactionForm" [formGroup]="accountTransactionForm"
                    class="account-transaction w-100-p p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
                    fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>

                    <!-- <mat-tab label="Basic Info"> -->
                    <div class="tab-content p-10" fusePerfectScrollbar>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Select Role</mat-label>

                                <mat-select formControlName="RoleId" appearance="outline"
                                    [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeRole($event.value)">
                                    <mat-option [value]="roleItem.Id" *ngFor="let roleItem of roleList">
                                        {{ roleItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="accountTransactionForm.controls.RoleId.invalid">
                                    {{getErrorMessage(accountTransactionForm, 'RoleId')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Select User</mat-label>

                                <mat-select formControlName="AccountId" required appearance="outline"
                                    [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeAccount($event.value)"
                                    #SelectAccount>
                                    <mat-select-filter class="select-filter" *ngIf="SelectAccount.focused"
                                        [array]="accountList" (filteredReturn)="this.filteredAccountItems = $event"
                                        [displayMember]="'Name'"></mat-select-filter>
                                    <mat-option [value]="accountItem.Id"
                                        *ngFor="let accountItem of filteredAccountItems">
                                        {{ accountItem.Name }}
                                    </mat-option>
                                </mat-select>

                                <mat-error *ngIf="accountTransactionForm.controls.AccountId.invalid">
                                    {{getErrorMessage(accountTransactionForm, 'AccountId')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Select Transaction</mat-label>

                                <mat-select formControlName="TransactionId" required appearance="outline"
                                    [disabled]="PageRights.IsReadOnly" #SelectTransaction>
                                    <mat-select-filter class="select-filter" *ngIf="SelectTransaction.focused"
                                        [array]="transactionList"
                                        (filteredReturn)="this.filteredTransactionItems = $event"
                                        [displayMember]="'Name'"></mat-select-filter>
                                    <mat-option [value]="transactionItem.Id"
                                        *ngFor="let transactionItem of filteredTransactionItems">
                                        {{ transactionItem.Name }}
                                    </mat-option>
                                </mat-select>

                                <mat-error *ngIf="accountTransactionForm.controls.TransactionId.invalid">
                                    {{getErrorMessage(accountTransactionForm, 'TransactionId')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <div class="checkbox-row" fxFlex="25" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <span class="checkbox-space" fxFlex="60">Rights?</span>
                                <mat-checkbox matInput formControlName="Rights" fxFlex="40"
                                    [disabled]="PageRights.IsReadOnly">
                                </mat-checkbox>
                            </div>
                            <div class="checkbox-row" fxFlex="25" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <span class="checkbox-space" fxFlex="60">Can Add?</span>
                                <mat-checkbox matInput formControlName="CanAdd" fxFlex="40"
                                    [disabled]="PageRights.IsReadOnly">
                                </mat-checkbox>
                            </div>
                            <div class="checkbox-row" fxFlex="25" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <span class="checkbox-space" fxFlex="60">Can Edit?</span>
                                <mat-checkbox matInput formControlName="CanEdit" fxFlex="40"
                                    [disabled]="PageRights.IsReadOnly">
                                </mat-checkbox>
                            </div>
                            <div class="checkbox-row" fxFlex="25" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <span class="checkbox-space" fxFlex="60">Can Delete?</span>
                                <mat-checkbox matInput formControlName="CanDelete" fxFlex="40"
                                    [disabled]="PageRights.IsReadOnly">
                                </mat-checkbox>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <div class="checkbox-row" fxFlex="25" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <span class="checkbox-space" fxFlex="60">Can View?</span>
                                <mat-checkbox matInput formControlName="CanView" fxFlex="40"
                                    [disabled]="PageRights.IsReadOnly">
                                </mat-checkbox>
                            </div>

                            <div class="checkbox-row" fxFlex="25" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <span class="checkbox-space" fxFlex="60">Can Export?</span>
                                <mat-checkbox matInput formControlName="CanExport" fxFlex="40"
                                    [disabled]="PageRights.IsReadOnly">
                                </mat-checkbox>
                            </div>

                            <div class="checkbox-row" fxFlex="25" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <span class="checkbox-space" fxFlex="60">List View?</span>
                                <mat-checkbox matInput formControlName="ListView" fxFlex="40"
                                    [disabled]="PageRights.IsReadOnly">
                                </mat-checkbox>
                            </div>

                            <div class="checkbox-row" fxFlex="25" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <span class="checkbox-space" fxFlex="60">Basic View?</span>
                                <mat-checkbox matInput formControlName="BasicView" fxFlex="40"
                                    [disabled]="PageRights.IsReadOnly">
                                </mat-checkbox>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <div class="checkbox-row" fxFlex="25" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <span class="checkbox-space" fxFlex="60">Detail View?</span>
                                <mat-checkbox matInput formControlName="DetailView" fxFlex="40"
                                    [disabled]="PageRights.IsReadOnly">
                                </mat-checkbox>
                            </div>

                            <div class="checkbox-row" fxFlex="25" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <span class="checkbox-space" fxFlex="60">Is Public?</span>
                                <mat-checkbox matInput formControlName="IsPublic" fxFlex="40"
                                    [disabled]="PageRights.IsReadOnly">
                                </mat-checkbox>
                            </div>

                            <div class="checkbox-row" fxFlex="25" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <span class="checkbox-space" fxFlex="60">Is Active?</span>
                                <mat-checkbox matInput formControlName="IsActive" fxFlex="40"
                                    [disabled]="PageRights.IsReadOnly">
                                </mat-checkbox>
                            </div>

                            <div class="checkbox-row" fxFlex="25" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            </div>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Remarks</mat-label>
                                <input matInput name="remarks" formControlName="Remarks">
                                <mat-error *ngIf="accountTransactionForm.controls.Remarks.invalid">
                                    {{getErrorMessage(accountTransactionForm, 'Remarks')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>