export const locale = {
    lang: 'en',
    data: {
        'VT_SCHOOL_SECTOR': {
            'VT_SCHOOL_SECTORS': 'VT SCHOOL SECTORS',
            'ADD_NEW_VT_SCHOOL_SECTORS': 'ADD',
            'SEARCH_FOR_A_VT_SCHOOL_SECTORS': 'Search for a vt school sectors',
        }
    }
};

