import { FuseUtils } from '@fuse/utils';

export class InternshipfeedbackModel {


    InternshipfeedbackId: string;
    StudentId: string;
    VTId: string;
    WhatsappMobile: string;
    DateOfActivity: Date

    WasOrientationOrganized: any;
    ParticipateInOrientation: any;
    EnjoyOrientation: any;
    EnjoyOrientationOption: any;
    NotEnjoyOrientationText: any;
    ImprovementInOrientationOption: any;
    ImprovementInOrientationText: any;
    InternshipStartAt: Date;
    InternshipEndtAt: Date;
    Supervisor: any;
    SatisfiedWithSupervisor: any;
    ThingsLikedInSupervisor: any;
    ThingsLikedInSupervisorText: any;
    ImprovementInSupervisor: any;
    ImprovementInSupervisorText: any;
    VTActivities: any;
    SupervisorMaintainAttendance: any;
    OverallInternshipExperience: any;
    FaceChallenge: any;
    FaceChallengeText: any;
    SkillsDevelopInInternship: any;
    SkillsDevelopInInternshipText: any;
    LearnInInternship: any;
    LearnInInternshipText: any;
    OverallFeedbackInternship: any;
    PrepareInternshipReport: any;

    Attachment: any;
    Attachment2: any;
    Attachment3: any;

    ScreenshotFile: any;
    ScreenshotFile2: any;
    ScreenshotFile3: any;

    ImageUrlGCS: any;
    ImageUrlGCS2: any;
    ImageUrlGCS3: any;

    RequestType: any;
    IsActive: boolean;

    constructor(internshipfeedbackItem?: any) {
        internshipfeedbackItem = internshipfeedbackItem || {};

        this.InternshipfeedbackId = internshipfeedbackItem.InternshipfeedbackId || FuseUtils.NewGuid();
        this.VTId = internshipfeedbackItem.VTId || '';
        this.StudentId = internshipfeedbackItem.StudentId || '';
        this.WhatsappMobile = internshipfeedbackItem.WhatsappMobile || '';
        this.DateOfActivity = internshipfeedbackItem.DateOfActivity || '';

        this.WasOrientationOrganized = internshipfeedbackItem.WasOrientationOrganized || '';
        this.ParticipateInOrientation = internshipfeedbackItem.ParticipateInOrientation || '';
        this.EnjoyOrientation = internshipfeedbackItem.EnjoyOrientation || '';
        this.EnjoyOrientationOption = internshipfeedbackItem.EnjoyOrientationOption || '';
        this.NotEnjoyOrientationText = internshipfeedbackItem.NotEnjoyOrientationText || '';
        this.ImprovementInOrientationOption = internshipfeedbackItem.ImprovementInOrientationOption || '';
        this.ImprovementInOrientationText = internshipfeedbackItem.ImprovementInOrientationText || '';
        this.InternshipStartAt = internshipfeedbackItem.InternshipStartAt || '';
        this.InternshipEndtAt = internshipfeedbackItem.InternshipEndtAt || '';
        this.Supervisor = internshipfeedbackItem.Supervisor || '';
        this.SatisfiedWithSupervisor = internshipfeedbackItem.SatisfiedWithSupervisor || '';
        this.ThingsLikedInSupervisor = internshipfeedbackItem.ThingsLikedInSupervisor || '';
        this.ThingsLikedInSupervisorText = internshipfeedbackItem.ThingsLikedInSupervisorText || '';
        this.ImprovementInSupervisor = internshipfeedbackItem.ImprovementInSupervisor || '';
        this.ImprovementInSupervisorText = internshipfeedbackItem.ImprovementInSupervisorText || '';
        this.VTActivities = internshipfeedbackItem.VTActivities || '';
        this.SupervisorMaintainAttendance = internshipfeedbackItem.SupervisorMaintainAttendance || '';
        this.OverallInternshipExperience = internshipfeedbackItem.OverallInternshipExperience || '';
        this.FaceChallenge = internshipfeedbackItem.FaceChallenge || '';
        this.FaceChallengeText = internshipfeedbackItem.FaceChallengeText || '';
        this.SkillsDevelopInInternship = internshipfeedbackItem.SkillsDevelopInInternship || '';
        this.SkillsDevelopInInternshipText = internshipfeedbackItem.SkillsDevelopInInternshipText || '';
        this.LearnInInternship = internshipfeedbackItem.LearnInInternship || '';
        this.LearnInInternshipText = internshipfeedbackItem.LearnInInternshipText || '';
        this.OverallFeedbackInternship = internshipfeedbackItem.OverallFeedbackInternship || '';
        this.PrepareInternshipReport = internshipfeedbackItem.PrepareInternshipReport || '';

        this.Attachment = internshipfeedbackItem.Attachment || '';
        this.Attachment2 = internshipfeedbackItem.Attachment2 || '';
        this.Attachment3 = internshipfeedbackItem.Attachment3 || '';

        this.ScreenshotFile = internshipfeedbackItem.ScreenshotFile || '';
        this.ScreenshotFile2 = internshipfeedbackItem.ScreenshotFile2 || '';
        this.ScreenshotFile3 = internshipfeedbackItem.ScreenshotFile3 || '';

        this.ImageUrlGCS = internshipfeedbackItem.ImageUrlGCS || '';
        this.ImageUrlGCS2 = internshipfeedbackItem.ImageUrlGCS2 || '';
        this.ImageUrlGCS3 = internshipfeedbackItem.ImageUrlGCS3 || '';

        this.IsActive = internshipfeedbackItem.IsActive || true;
        this.RequestType = 0; // New
    }
}
