import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { CommonService } from 'app/services/common.service';
import { UserModel } from 'app/models/user.model';

@Injectable()
export class StudentClassDetailService {
    constructor(private http: CommonService) { }

    getStudentClassDetails(): Observable<any> {
        return this.http
            .HttpGet(this.http.Services.StudentClassDetail.GetAll)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetAllByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.StudentClassDetail.GetAllByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    getStudentClassDetailById(studentId: string) {
        let requestParams = {
            DataId: studentId
        };

        return this.http
            .HttpPost(this.http.Services.StudentClassDetail.GetById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    createOrUpdateStudentClassDetail(formData: any) {
        return this.http
            .HttpPost(this.http.Services.StudentClassDetail.CreateOrUpdate, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    deleteStudentClassDetailById(studentId: string) {
        var studentClassDetailParams = {
            DataId: studentId
        };

        return this.http
            .HttpPost(this.http.Services.StudentClassDetail.Delete, studentClassDetailParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    getDropdownforStudentClassDetails(userModel: UserModel): Observable<any[]> {
        let studentsRequest = this.http.GetStudentsByVTId({ DataId: userModel.LoginId, DataId1: userModel.UserTypeId });
        let socialCategoryRequest = this.http.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'SocialCategory', SelectTitle: 'Social Category' });
        let religionRequest = this.http.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'Religion', SelectTitle: 'Religion' });

        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([studentsRequest, socialCategoryRequest, religionRequest]);
    }
}
