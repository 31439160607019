import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant'
import { VTStudentExitSurveyDetailService } from '../vt-student-exit-survey-detail.service';
import { VTStudentExitSurveyDetailModel } from '../vt-student-exit-survey-detail.model';
import { DropdownModel } from 'app/models/dropdown.model';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
 import { default as _rollupMoment, Moment } from "moment";

// const moment = _rollupMoment || _moment;
// import { MatDatepicker } from '@angular/material/datepicker';
// export const MY_FORMATS = {
//   parse: {
//     dateInput: 'YYYY',
//   },
//   display: {
//     dateInput: 'YYYY',
//     monthYearLabel: 'YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'YYYY',
//   },
// };

@Component({
  selector: 'vt-student-exit-survey-detail',
  templateUrl: './create-vt-student-exit-survey-detail.component.html',
  styleUrls: ['./create-vt-student-exit-survey-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  // providers: [
  //   {
  //     provide: DateAdapter,
  //     useClass: MomentDateAdapter,
  //     deps: [MAT_DATE_LOCALE,MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  //   },

  //   { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  // ],
})
export class CreateVTStudentExitSurveyDetailComponent extends BaseComponent<VTStudentExitSurveyDetailModel> implements OnInit {
  vtStudentExitSurveyDetailForm: FormGroup;
  vtStudentExitSurveyDetailModel: VTStudentExitSurveyDetailModel;
  studentList: [DropdownModel];
  genderList: any;
  migrationReasonList: any;
  parentsOccupationList: any;
  currentEmployementStatusList: any;
  courseList: any;
  courseSelectionReasonList: any;
  postCourseCpmletionPlanningList: any;
  educationDiscontinueReasonList: any;
  plansList: any;
  age: number;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private vtStudentExitSurveyDetailService: VTStudentExitSurveyDetailService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default vtStudentExitSurveyDetail Model
    this.vtStudentExitSurveyDetailModel = new VTStudentExitSurveyDetailModel();
  }

  ngOnInit(): void {

    this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'Gender', SelectTitle: 'Gender' }).subscribe((response) => {
      if (response.Success) {
        this.genderList = response.Results;
      }
    });

    this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'MigrationReasons', SelectTitle: 'Reason For Migration' }).subscribe((response) => {
      if (response.Success) {
        this.migrationReasonList = response.Results;
      }
    });

    this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'ParentsOccupation', SelectTitle: 'Occupation' }).subscribe((response) => {
      if (response.Success) {
        this.parentsOccupationList = response.Results;
      }
    });

    this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'CurrentEmploymentStatus', SelectTitle: 'Current Employment Status' }).subscribe((response) => {
      if (response.Success) {
        this.currentEmployementStatusList = response.Results;
      }
    });

    this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'PursueCourse', SelectTitle: 'Course' }).subscribe((response) => {
      if (response.Success) {
        this.courseList = response.Results;
      }
    });

    this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'CourseSelectionReason', SelectTitle: 'CourseSelectionReason' }).subscribe((response) => {
      if (response.Success) {
        this.courseSelectionReasonList = response.Results;
      }
    });

    this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'PostCourseCpmletionPlanning', SelectTitle: 'Post Course Cpmletion Planning' }).subscribe((response) => {
      if (response.Success) {
        this.postCourseCpmletionPlanningList = response.Results;
        console.log(this.postCourseCpmletionPlanningList);
      }
    });

    this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'EducationDiscontinueReason', SelectTitle: 'Education Discontinue Reason' }).subscribe((response) => {
      if (response.Success) {
        this.educationDiscontinueReasonList = response.Results;
      }
    });

    this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'Plans', SelectTitle: 'Plans' }).subscribe((response) => {
      if (response.Success) {
        this.plansList = response.Results;
      }
    });

        this.route.paramMap.subscribe(params => {
          if (params.keys.length > 0) {
            this.PageRights.ActionType = params.get('actionType');

            if (this.PageRights.ActionType == this.Constants.Actions.New) {
              this.vtStudentExitSurveyDetailModel = new VTStudentExitSurveyDetailModel();

            } else {
              var vtStudentExitSurveyDetailId: string = params.get('vtStudentExitSurveyDetailId')

              this.vtStudentExitSurveyDetailService.getVTStudentExitSurveyDetailById(vtStudentExitSurveyDetailId)
                .subscribe((response: any) => {
                  this.vtStudentExitSurveyDetailModel = response.Result;

                  if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                    this.vtStudentExitSurveyDetailModel.RequestType = this.Constants.PageType.Edit;
                  else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                    this.vtStudentExitSurveyDetailModel.RequestType = this.Constants.PageType.View;
                    this.PageRights.IsReadOnly = true;
                  }

                  this.vtStudentExitSurveyDetailForm = this.createVTStudentExitSurveyDetailForm();
                });
            }
          }
        });
      

    this.vtStudentExitSurveyDetailForm = this.createVTStudentExitSurveyDetailForm();
  }

  // chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
  //   const ctrlValue = this.vtStudentExitSurveyDetailForm.get('FromYear').value;
  //   ctrlValue.year(normalizedYear.year());
  //   this.vtStudentExitSurveyDetailForm.get('FromYear').setValue(ctrlValue);
  //   datepicker.close();
  // }

  onChangeDateCalculateAge(event) {
    let dateOfBirth = new Date(event.value);
    let today = new Date();
    this.age = today.getFullYear() - dateOfBirth.getFullYear();
    this.vtStudentExitSurveyDetailForm.get("Age").patchValue(this.age);
  }

  saveOrUpdateVTStudentExitSurveyDetailDetails() {
    if (!this.vtStudentExitSurveyDetailForm.valid) {
      this.validateAllFormFields(this.vtStudentExitSurveyDetailForm);  
      return;
    }
    this.setValueFromFormGroup(this.vtStudentExitSurveyDetailForm, this.vtStudentExitSurveyDetailModel);
    this.vtStudentExitSurveyDetailModel.VTId= this.UserModel.UserTypeId;

    this.vtStudentExitSurveyDetailService.createOrUpdateVTStudentExitSurveyDetail(this.vtStudentExitSurveyDetailModel)
      .subscribe((vtStudentExitSurveyDetailResp: any) => {

        if (vtStudentExitSurveyDetailResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.VTStudentExitSurveyDetail.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(vtStudentExitSurveyDetailResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('VTStudentExitSurveyDetail deletion errors =>', error);
      });
  }

  //Create vtStudentExitSurveyDetail form and returns {FormGroup}
  createVTStudentExitSurveyDetailForm(): FormGroup {
    return this.formBuilder.group({
      VTStudentExitSurveyDetailId: new FormControl(this.vtStudentExitSurveyDetailModel.VTStudentExitSurveyDetailId),      
      Date: new FormControl({ value: new Date(this.vtStudentExitSurveyDetailModel.Date), disabled: this.PageRights.IsReadOnly }, Validators.required),
      InterviewerName: new FormControl({ value: this.vtStudentExitSurveyDetailModel.InterviewerName, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.pattern(this.Constants.Regex.OnlyCapitalChars)]),
      Designation: new FormControl({ value: this.vtStudentExitSurveyDetailModel.Designation, disabled: this.PageRights.IsReadOnly }, Validators.required),
      CompletedJobRoleName: new FormControl({ value: this.vtStudentExitSurveyDetailModel.CompletedJobRoleName, disabled: this.PageRights.IsReadOnly }, Validators.required),
      FromYear: new FormControl({ value: new Date(this.vtStudentExitSurveyDetailModel.FromYear), disabled: this.PageRights.IsReadOnly }),
      ToYear: new FormControl({ value: new Date(this.vtStudentExitSurveyDetailModel.ToYear), disabled: this.PageRights.IsReadOnly }),
      RollNo: new FormControl({ value: this.vtStudentExitSurveyDetailModel.RollNo, disabled: this.PageRights.IsReadOnly }),
      Year: new FormControl({ value: new Date(this.vtStudentExitSurveyDetailModel.Year), disabled: this.PageRights.IsReadOnly },),
      StudentFirstName: new FormControl({ value: this.vtStudentExitSurveyDetailModel.StudentFirstName, disabled: this.PageRights.IsReadOnly },[Validators.pattern(this.Constants.Regex.FirstCharsCapital), Validators.required]),
      StudentMiddleName: new FormControl({ value: this.vtStudentExitSurveyDetailModel.StudentMiddleName, disabled: this.PageRights.IsReadOnly }, Validators.pattern(this.Constants.Regex.FirstCharsCapital)),
      StudentLastName: new FormControl({ value: this.vtStudentExitSurveyDetailModel.StudentLastName, disabled: this.PageRights.IsReadOnly },[Validators.pattern(this.Constants.Regex.FirstCharsCapital), Validators.required]),
      Gender: new FormControl({ value: this.vtStudentExitSurveyDetailModel.Gender, disabled: this.PageRights.IsReadOnly }),
      DateOfBirth: new FormControl({ value: new Date(this.vtStudentExitSurveyDetailModel.DateOfBirth), disabled: this.PageRights.IsReadOnly }),
      Age: new FormControl({ value: this.vtStudentExitSurveyDetailModel.Age, disabled: this.PageRights.IsReadOnly }),
      StudentMobileNumber: new FormControl({ value: this.vtStudentExitSurveyDetailModel.StudentMobileNumber, disabled: this.PageRights.IsReadOnly }, Validators.pattern(this.Constants.Regex.Number)),
      StudentFirstRelativeName: new FormControl({ value: this.vtStudentExitSurveyDetailModel.StudentFirstRelativeName, disabled: this.PageRights.IsReadOnly }),
      StudentFirstRelativeRelation: new FormControl({ value: this.vtStudentExitSurveyDetailModel.StudentFirstRelativeRelation, disabled: this.PageRights.IsReadOnly }),
      StudentFirstRelativeMobile: new FormControl({ value: this.vtStudentExitSurveyDetailModel.StudentFirstRelativeMobile, disabled: this.PageRights.IsReadOnly },Validators.pattern(this.Constants.Regex.Number)),
      StudentSecondRelativeName: new FormControl({ value: this.vtStudentExitSurveyDetailModel.StudentSecondRelativeName, disabled: this.PageRights.IsReadOnly }),
      StudentSecondRelativeRelation: new FormControl({ value: this.vtStudentExitSurveyDetailModel.StudentSecondRelativeRelation, disabled: this.PageRights.IsReadOnly }),
      StudentSecondRelativeMobile: new FormControl({ value: this.vtStudentExitSurveyDetailModel.StudentSecondRelativeMobile, disabled: this.PageRights.IsReadOnly }),
      FamilyMemberCount: new FormControl({ value: this.vtStudentExitSurveyDetailModel.FamilyMemberCount, disabled: this.PageRights.IsReadOnly }),
      FamilyMemberMigrate: new FormControl({ value: this.vtStudentExitSurveyDetailModel.FamilyMemberMigrate, disabled: this.PageRights.IsReadOnly }),
      ReasonOfMigration: new FormControl({ value: this.vtStudentExitSurveyDetailModel.ReasonOfMigration, disabled: this.PageRights.IsReadOnly }),
      Pleasemention: new FormControl({ value: this.vtStudentExitSurveyDetailModel.Pleasemention, disabled: this.PageRights.IsReadOnly }),
      FatherOccupation: new FormControl({ value: this.vtStudentExitSurveyDetailModel.FatherOccupation, disabled: this.PageRights.IsReadOnly }),
      FatherOccupationDetails: new FormControl({ value: this.vtStudentExitSurveyDetailModel.FatherOccupationDetails, disabled: this.PageRights.IsReadOnly }),
      MotherOccupation: new FormControl({ value: this.vtStudentExitSurveyDetailModel.MotherOccupation, disabled: this.PageRights.IsReadOnly }),
      MotherOccupationDetails: new FormControl({ value: this.vtStudentExitSurveyDetailModel.MotherOccupationDetails, disabled: this.PageRights.IsReadOnly }),
      EmployementExchangeKnowledge: new FormControl({ value: this.vtStudentExitSurveyDetailModel.EmployementExchangeKnowledge, disabled: this.PageRights.IsReadOnly }),
      EnrolledInEmploymentOffice: new FormControl({ value: this.vtStudentExitSurveyDetailModel.EnrolledInEmploymentOffice, disabled: this.PageRights.IsReadOnly }),
      CurrentEmployementStatus: new FormControl({ value: this.vtStudentExitSurveyDetailModel.CurrentEmployementStatus, disabled: this.PageRights.IsReadOnly }),
      EmployementOrganisation: new FormControl({ value: this.vtStudentExitSurveyDetailModel.EmployementOrganisation, disabled: this.PageRights.IsReadOnly }),
      NatureOfWork: new FormControl({ value: this.vtStudentExitSurveyDetailModel.NatureOfWork, disabled: this.PageRights.IsReadOnly }),
      EmployementInVocationalEducationSector: new FormControl({ value: this.vtStudentExitSurveyDetailModel.EmployementInVocationalEducationSector, disabled: this.PageRights.IsReadOnly }),
      WorkDetails: new FormControl({ value: this.vtStudentExitSurveyDetailModel.WorkDetails, disabled: this.PageRights.IsReadOnly }),
      EarningsPerMonth: new FormControl({ value: this.vtStudentExitSurveyDetailModel.EarningsPerMonth, disabled: this.PageRights.IsReadOnly }),
      ContinueStudies: new FormControl({ value: this.vtStudentExitSurveyDetailModel.ContinueStudies, disabled: this.PageRights.IsReadOnly }),
      CoursePursue: new FormControl({ value: this.vtStudentExitSurveyDetailModel.CoursePursue, disabled: this.PageRights.IsReadOnly }),
      CourseDetails: new FormControl({ value: this.vtStudentExitSurveyDetailModel.CourseDetails, disabled: this.PageRights.IsReadOnly }),
      CourseSelectionReason: new FormControl({ value: this.vtStudentExitSurveyDetailModel.CourseSelectionReason, disabled: this.PageRights.IsReadOnly }),
      ReasonDetails: new FormControl({ value: this.vtStudentExitSurveyDetailModel.ReasonDetails, disabled: this.PageRights.IsReadOnly }),
      PostCourseCompletionPlanning: new FormControl({ value: this.vtStudentExitSurveyDetailModel.PostCourseCompletionPlanning, disabled: this.PageRights.IsReadOnly }),
      TargetedEmployer: new FormControl({ value: this.vtStudentExitSurveyDetailModel.TargetedEmployer, disabled: this.PageRights.IsReadOnly }),
      CapitalSource: new FormControl({ value: this.vtStudentExitSurveyDetailModel.CapitalSource, disabled: this.PageRights.IsReadOnly }),
      OtherDetails: new FormControl({ value: this.vtStudentExitSurveyDetailModel.OtherDetails, disabled: this.PageRights.IsReadOnly }),
      // NotDecidedReason: new FormControl({ value: this.vtStudentExitSurveyDetailModel.NotDecidedReason, disabled: this.PageRights.IsReadOnly }),
      VEHelpCourseCompletion: new FormControl({ value: this.vtStudentExitSurveyDetailModel.VEHelpCourseCompletion, disabled: this.PageRights.IsReadOnly }),
      ExpectedIncome: new FormControl({ value: this.vtStudentExitSurveyDetailModel.ExpectedIncome, disabled: this.PageRights.IsReadOnly }),
      OtherInformation: new FormControl({ value: this.vtStudentExitSurveyDetailModel.OtherInformation, disabled: this.PageRights.IsReadOnly }),
      DiscontinueEducationReason: new FormControl({ value: this.vtStudentExitSurveyDetailModel.DiscontinueEducationReason, disabled: this.PageRights.IsReadOnly }),
      Plans: new FormControl({ value: this.vtStudentExitSurveyDetailModel.Plans, disabled: this.PageRights.IsReadOnly }),
      PlansDetails: new FormControl({ value: this.vtStudentExitSurveyDetailModel.PlansDetails, disabled: this.PageRights.IsReadOnly }),
      PlansRelatedToVESector: new FormControl({ value: this.vtStudentExitSurveyDetailModel.PlansRelatedToVESector, disabled: this.PageRights.IsReadOnly }),
      VEHelpsToTakeDecision: new FormControl({ value: this.vtStudentExitSurveyDetailModel.VEHelpsToTakeDecision, disabled: this.PageRights.IsReadOnly }),
      //ExpectedMonthlyIncome: new FormControl({ value: this.vtStudentExitSurveyDetailModel.ExpectedMonthlyIncome, disabled: this.PageRights.IsReadOnly }),

      
      //IsActive: new FormControl({ value: this.vtStudentExitSurveyDetailModel.IsActive, disabled: this.PageRights.IsReadOnly }),
    });
  }
}
