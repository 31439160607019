<div id="employee" class="page-layout carded fullwidth inner-scroll">
  <!-- TOP BACKGROUND -->
  <div class="top-bg accent"></div>
  <!-- / TOP BACKGROUND -->

  <!-- CENTER -->
  <div class="center">
    <!-- HEADER -->
    <div
      class="header accent"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="space-between center"
    >
      <!-- APP TITLE -->
      <div
        class="logo mb-24 mb-md-0"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <mat-icon
          class="logo-icon s-32 mr-16"
          [@animate]="{ value: '*', params: { delay: '50ms', scale: '0.2' } }"
        >
          shopping_basket
        </mat-icon>
        <span
          class="logo-text h1"
          [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }"
        >
          EMPLOYEES
          <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
            <mat-icon
              [@animate]="{
                value: '*',
                params: { delay: '50ms', scale: '0.2' }
              }"
              >refresh</mat-icon
            >
          </a>
        </span>
      </div>
      <!-- / APP TITLE -->

      <!-- SEARCH -->
      <div class="search-wrapper mx-32 mx-md-0">
        <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>search</mat-icon>
          <input
            #filter
            (keyup)="applySearchFilter($event.target.value)"
            placeholder="Search for a employees"
          />
        </div>
      </div>
      <!-- / SEARCH -->

      <!-- ADD BUTTON -->
      <button
        mat-raised-button
        [routerLink]="['./', 'new']"
        skipLocationChange="true"
        replaceUrl="false"
        class="add-employee-button fuse-white mt-24 mt-md-0"
      >
        <span>ADD</span>
      </button>
      <!-- / ADD BUTTON -->
    </div>
    <!-- / HEADER -->

    <!-- CONTENT CARD -->
    <div class="content-card">
      <mat-table
        class="employee-table"
        #table
        [dataSource]="tableDataSource"
        matSort
        fusePerfectScrollbar
        [fusePerfectScrollbarOptions]="{ suppressScrollX: false }"
      >
        <!-- AccountId Column -->
        <ng-container matColumnDef="AccountId">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs
            >AccountId</mat-header-cell
          >
          <mat-cell *matCellDef="let employee" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employee.AccountId }}</p>
          </mat-cell>
        </ng-container>

        <!-- EmployeeCode Column -->
        <ng-container matColumnDef="EmployeeCode">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs
            >EmployeeCode</mat-header-cell
          >
          <mat-cell *matCellDef="let employee" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employee.EmployeeCode }}</p>
          </mat-cell>
        </ng-container>

        <!-- FirstName Column -->
        <ng-container matColumnDef="FirstName">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            fxShow.lt-sm
            fxShow.gt-md
            >FirstName</mat-header-cell
          >
          <mat-cell *matCellDef="let employee">
            <p class="text-truncate">{{ employee.FirstName }}</p>
          </mat-cell>
        </ng-container>

        <!-- MiddleName Column -->
        <ng-container matColumnDef="MiddleName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs
            >MiddleName</mat-header-cell
          >
          <mat-cell *matCellDef="let employee" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employee.MiddleName }}</p>
          </mat-cell>
        </ng-container>

        <!-- LastName Column -->
        <ng-container matColumnDef="LastName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs
            >LastName</mat-header-cell
          >
          <mat-cell *matCellDef="let employee" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employee.LastName }}</p>
          </mat-cell>
        </ng-container>

        <!-- Gender Column -->
        <ng-container matColumnDef="Gender">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs
            >Gender</mat-header-cell
          >
          <mat-cell *matCellDef="let employee">
            <p class="text-truncate">{{ employee.Gender }}</p>
          </mat-cell>
        </ng-container>

        <!-- DateOfBirth Column -->
        <ng-container matColumnDef="DateOfBirth">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs
            >DateOfBirth</mat-header-cell
          >
          <mat-cell *matCellDef="let employee" fxHide fxShow.gt-xs>
            <p class="text-truncate">
              {{ employee.DateOfBirth | date: "dd/MM/yyyy" }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- Department Column -->
        <ng-container matColumnDef="Department">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            fxShow.lt-sm
            fxShow.gt-md
            >Department</mat-header-cell
          >
          <mat-cell *matCellDef="let employee">
            <p class="text-truncate">{{ employee.Department }}</p>
          </mat-cell>
        </ng-container>

        <!-- Telephone Column -->
        <ng-container matColumnDef="Telephone">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs
            >Telephone</mat-header-cell
          >
          <mat-cell *matCellDef="let employee" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employee.Telephone }}</p>
          </mat-cell>
        </ng-container>

        <!-- Mobile Column -->
        <ng-container matColumnDef="Mobile">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs
            >Mobile</mat-header-cell
          >
          <mat-cell *matCellDef="let employee" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employee.Mobile }}</p>
          </mat-cell>
        </ng-container>

        <!-- EmailId Column -->
        <ng-container matColumnDef="EmailId">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs
            >EmailId</mat-header-cell
          >
          <mat-cell *matCellDef="let employee" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ employee.EmailId }}</p>
          </mat-cell>
        </ng-container>

        <!-- IsActive Column -->
        <!-- <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Is Active?</mat-header-cell>
                    <mat-cell *matCellDef="let employee" fxHide fxShow.gt-xs>
                        <mat-icon *ngIf="employee.IsActive" class="active-icon green-600 s-16">check</mat-icon>
                        <mat-icon *ngIf="!employee.IsActive" class="active-icon red-500 s-16">close</mat-icon>
                    </mat-cell>
                </ng-container> -->

        <ng-container matColumnDef="Actions">
          <mat-header-cell *matHeaderCellDef fxShow.gt-xs
            >Actions</mat-header-cell
          >
          <mat-cell *matCellDef="let employee" fxShow.gt-xs>
            <button
              *ngIf="PageRights.IsView"
              mat-icon-button
              [routerLink]="['/employees', 'view', employee.AccountId]"
              skipLocationChange="true"
              replaceUrl="false"
            >
              <mat-icon color="primary">visibility</mat-icon>
            </button>
            <button
              *ngIf="PageRights.IsEdit"
              mat-icon-button
              [routerLink]="['/employees', 'edit', employee.AccountId]"
              skipLocationChange="true"
              replaceUrl="false"
            >
              <mat-icon color="primary">edit</mat-icon>
            </button>
            <button
              mat-icon-button
              color="warn"
              (click)="onDeleteEmployee(employee.AccountId)"
              *ngIf="PageRights.IsDelete"
            >
              <mat-icon>delete_outline</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>

        <mat-row
          *matRowDef="let employee; columns: displayedColumns"
          class="employee"
          matRipple
        >
        </mat-row>
      </mat-table>

      <mat-paginator
        #ListPaginator
        [length]="tableDataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 100]"
      >
      </mat-paginator>
    </div>
    <!-- / CONTENT CARD -->
  </div>
  <!-- / CENTER -->
</div>
