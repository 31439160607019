<mat-toolbar>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="0 1 calc(25%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="100%">
            <span style="font-size: 11px;">By:</span> <span style="font-weight: bold; font-size: 12px; margin-left: 10px;">Lend A Hand India</span>
            <img class="managed-by" src="assets/images/logos/LALHI-logo.jpg" />

            <div class="lahi-version">
                <div class="web-version">Web Version : {{ appInfo.version }}</div>
            </div>
        </div>
        
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="0 1 calc(25%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="100%">
            <img class="lighthouse-logo" src="assets/images/logos/lighthouse-logo.png" />
        </div>
    </div>
</mat-toolbar>
