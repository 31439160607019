import { FuseUtils } from '@fuse/utils';

export class HeadMasterModel {
    HMId: string;
    VTId: string;
    SchoolId: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    FullName: string;
    Mobile: string;
    Mobile1: string;
    Email: string;
    Gender: string;
    YearsInSchool: number;
    DateOfJoiningSchool: Date;
    DateOfResignationFromSchool: Date;
    IsActive: boolean;
    RequestType: any;

    constructor(headMasterItem?: any) {
        headMasterItem = headMasterItem || {};

        this.HMId = headMasterItem.HMId || FuseUtils.NewGuid();
        this.VTId = headMasterItem.VTId || FuseUtils.NewGuid();
        this.SchoolId = headMasterItem.VTSchoolSectorId || '';
        this.FirstName = headMasterItem.FirstName || '';
        this.MiddleName = headMasterItem.MiddleName || '';
        this.LastName = headMasterItem.LastName || '';
        this.FullName = headMasterItem.FullName || '';
        this.Mobile = headMasterItem.Mobile || '';
        this.Mobile1 = headMasterItem.Mobile1 || '';
        this.Email = headMasterItem.Email || '';
        this.Gender = headMasterItem.Gender || '';
        this.YearsInSchool = headMasterItem.YearsInSchool || '';
        this.DateOfJoiningSchool = headMasterItem.DateOfJoiningSchool || '';
        this.DateOfResignationFromSchool = headMasterItem.DateOfResignationFromSchool || '';
        this.IsActive = headMasterItem.IsActive || true;
        this.RequestType = 0; // New
    }
}
