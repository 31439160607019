import { Component, OnInit, NgZone, ViewEncapsulation } from "@angular/core";
import { CommonService } from "app/services/common.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { EmployeeModel } from "./employee.model";
import { EmployeeService } from "./employee.service";
import { BaseListComponent } from "app/common/base-list/base.list.component";
import { fuseAnimations } from "@fuse/animations";
import { DialogService } from "app/common/confirm-dialog/dialog.service";

@Component({
  selector: "data-list-view",
  templateUrl: "./employee.component.html",
  styleUrls: ["./employee.component.scss"],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class EmployeeComponent
  extends BaseListComponent<EmployeeModel>
  implements OnInit {
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    private dialogService: DialogService,
    private employeeService: EmployeeService
  ) {
    super(commonService, router, routeParams, snackBar, zone);
  }

  ngOnInit(): void {
    this.employeeService.GetAllByCriteria(this.SearchBy).subscribe(
      (response) => {
        this.displayedColumns = [
          "EmployeeCode",
          "FirstName",
          "MiddleName",
          "LastName",
          "Gender",
          "DateOfBirth",
          "Department",
          "Telephone",
          "Mobile",
          "EmailId",
          "Actions",
        ];

        this.tableDataSource.data = response.Results;
        this.tableDataSource.sort = this.ListSort;
        this.tableDataSource.paginator = this.ListPaginator;
        this.tableDataSource.filteredData = this.tableDataSource.data;

        this.zone.run(() => {
          if (this.tableDataSource.data.length == 0) {
            this.showNoDataFoundSnackBar();
          }
        });
        this.IsLoading = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onDeleteEmployee(accountId: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe((confResp) => {
        if (confResp) {
          this.employeeService
            .deleteEmployeeById(accountId)
            .subscribe((employeeResp: any) => {
              this.zone.run(
                () => {
                  if (employeeResp.Success) {
                    this.showActionMessage(
                      this.Constants.Messages.RecordDeletedMessage,
                      this.Constants.Html.SuccessSnackbar
                    );
                  }
                  this.ngOnInit();
                },
                (error) => {
                  console.log("Employee deletion errors =>", error);
                }
              );
            });
          this.ngOnInit();
        }
      });
  }
}
