export const locale = {
    lang: 'gj',
    data: {
        'SCHOOL': {
            'SCHOOLS': 'શાળાઓ',
            'ADD_NEW_SCHOOL': 'નવી શાળા ઉમેરો',
            'SEARCH_FOR_A_SCHOOL': 'શાળાઓ માટે શોધ'
        }
    }
};

