import { Component, OnInit, NgZone, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SchoolModel } from './school.model';
import { SchoolService } from './school.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as guarati } from './i18n/gj';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'data-list-view',
  templateUrl: './school.component.html',
  styleUrls: ['./school.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class SchoolComponent extends BaseListComponent<SchoolModel> implements OnInit {
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private dialogService: DialogService,
    private schoolService: SchoolService) {
    super(commonService, router, routeParams, snackBar, zone);
    this.translationLoaderService.loadTranslations(english, guarati);
  }

  ngOnInit(): void {
    this.schoolService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      this.displayedColumns = ['SchoolName', 'CategoryName', 'SchoolUniqueId', 'Udise',  'DivisionName', 'DistrictName', 'BlockName', 'IsActive', 'Actions'];
      if (this.UserModel.StateId == 'DL') {
        const schoolUniqueIdIndex = this.displayedColumns.indexOf('SchoolUniqueId');
        if (schoolUniqueIdIndex === -1) {
          this.displayedColumns.splice(2, 0, 'SchoolUniqueId');
        }
      } else {
        const index = this.displayedColumns.indexOf('SchoolUniqueId');
        if (index !== -1) {
          this.displayedColumns.splice(index, 1);
        }
      }

      this.tableDataSource = new MatTableDataSource<Element>(response.Results);      
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }

  onDeleteSchool(schoolId: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.schoolService.deleteSchoolById(schoolId)
            .subscribe((schoolResp: any) => {

              this.zone.run(() => {
                if (schoolResp.Success) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                }
                this.ngOnInit();
              }, error => {
                console.log('School deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }

  exportExcel(): void {
    this.exportExcelFromTable(this.tableDataSource.data, "Schools");
  }
}
