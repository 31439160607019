<div id="employer" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/employers'" skipLocationChange="true"
                    replaceUrl="false">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ employerModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New Employer
                    </div>
                    <div class="subtitle secondary-text">
                        <span>Employer Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-employer-button" *ngIf="PageRights.ActionType ==='new'"
                (click)="saveOrUpdateEmployerDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-employer-button" *ngIf="PageRights.ActionType ==='edit'"
                (click)="saveOrUpdateEmployerDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="employerForm" [formGroup]="employerForm" class="employer w-100-p p-24 mr-24"
                    fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>State</mat-label>
                            <mat-select name="stateCode" formControlName="StateCode" appearance="outline" required
                                [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeState($event.value)">
                                <mat-option [value]="stateItem.Id" *ngFor="let stateItem of stateList">
                                    {{ stateItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="employerForm.controls.StateCode.invalid">
                                {{getErrorMessage(employerForm, 'StateCode')}}</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Division</mat-label>

                            <mat-select name="divisionId" formControlName="DivisionId" appearance="outline" required
                                [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeDivision($event.value)">
                                <mat-option [value]="divisionItem.Id" *ngFor="let divisionItem of divisionList">
                                    {{ divisionItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="employerForm.controls.DivisionId.invalid">
                                {{getErrorMessage(employerForm, 'DivisionId')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>District</mat-label>

                            <mat-select name="districtCode" formControlName="DistrictCode" appearance="outline" required
                                [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeDistrict($event.value)">
                                <mat-option [value]="districtItem.Id" *ngFor="let districtItem of districtList">
                                    {{ districtItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="employerForm.controls.DistrictCode.invalid">
                                {{getErrorMessage(employerForm, 'DistrictCode')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Block Name</mat-label>
                            
                            <mat-select name="blockName" formControlName="BlockName" appearance="outline"
                                [disabled]="PageRights.IsReadOnly" required>
                                <mat-option [value]="blockItem.Id" *ngFor="let blockItem of blockList">
                                    {{ blockItem.Name }}
                                </mat-option>
                            </mat-select>

                            <mat-error *ngIf="employerForm.controls.BlockName.invalid">
                                {{getErrorMessage(employerForm, 'BlockName')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>Address</mat-label>
                            <input matInput name="address" formControlName="Address" required>
                            <mat-error *ngIf="employerForm.controls.Address.invalid">
                                {{getErrorMessage(employerForm, 'Address')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>City</mat-label>
                            <input matInput name="city" formControlName="City">
                            <mat-error *ngIf="employerForm.controls.City.invalid">
                                {{getErrorMessage(employerForm, 'City')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Year of Establishment</mat-label>
                            <input matInput name="YearofEstablishment" formControlName="YearofEstablishment" digitOnly minlength="4"
                                maxlength="4" matTooltipPosition="above">
                            <mat-error *ngIf="employerForm.controls.YearofEstablishment.invalid">
                                {{getErrorMessage(employerForm, 'YearofEstablishment')}}
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Name of employer/shop/organization/company</mat-label>
                            <input matInput name="EmployerName" formControlName="EmployerName" required
                                matTooltip="Enter name of the shop/organization/company."
                                matTooltipPosition="above">
                            <mat-error *ngIf="employerForm.controls.EmployerName.invalid">
                                {{getErrorMessage(employerForm, 'EmployerName')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Pincode</mat-label>
                            <input matInput name="pincode" formControlName="Pincode" digitOnly minlength="6"
                                maxlength="6" matTooltip="Enter the 6 digit pincode." matTooltipPosition="above">
                            <mat-error *ngIf="employerForm.controls.Pincode.invalid">
                                {{getErrorMessage(employerForm, 'Pincode')}}
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Employer Type</mat-label>
                            <mat-select name="BusinessType" formControlName="BusinessType" appearance="outline"
                                [disabled]="PageRights.IsReadOnly" 
                                matTooltipPosition="above">
                                <mat-option value="Freelancer/Independent Practioner/Independent Service Provider">Freelancer/Independent Practioner/Independent Service Provider</mat-option>
                                <mat-option value="Shop/Company/Establishment">Shop/Company/Establishment</mat-option>
                            </mat-select>    
                            <mat-error *ngIf="employerForm.controls.BusinessType.invalid">
                                {{getErrorMessage(employerForm, 'BusinessType')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Employee Count</mat-label>
                            <input matInput name="employeeCount" formControlName="EmployeeCount" required digitOnly>
                            <mat-error *ngIf="employerForm.controls.EmployeeCount.invalid">
                                {{getErrorMessage(employerForm, 'EmployeeCount')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>No. of Outlets</mat-label>
                            <input matInput name="numberOfOutlets" formControlName="NumberOfOutlets" digitOnly
                                matTooltip="Enter the number of outlets" matTooltipPosition="above">
                            <mat-error *ngIf="employerForm.controls.NumberOfOutlets.invalid">
                                {{getErrorMessage(employerForm, 'NumberOfOutlets')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline"  fxFlex="34" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Sector</mat-label>
                            <mat-select formControlName="SectorId" appearance="outline" 
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                    {{ sectorItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="employerForm.controls.SectorId.invalid">
                                {{getErrorMessage(employerForm, 'SectorId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Internship type</mat-label>
                            <mat-select name="InternshipType" formControlName="InternshipType" appearance="outline" required
                                [disabled]="PageRights.IsReadOnly" matTooltipPosition="above">
                                <mat-option value="Online">Online</mat-option>
                                <mat-option value="Onsite">Onsite</mat-option>
                                <mat-option value="Internship@school">Internship@school</mat-option>
                                <mat-option value="Hybrid">Hybrid</mat-option>
                            </mat-select>
                            <mat-error *ngIf="employerForm.controls.InternshipType.invalid">
                                {{getErrorMessage(employerForm, 'InternshipType')}}
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Employer's First Name</mat-label>
                            <input matInput name="ContactFirst" formControlName="ContactFirst" required
                                matTooltipPosition="above">
                            <mat-error *ngIf="employerForm.controls.ContactFirst.invalid">
                                {{getErrorMessage(employerForm, 'ContactFirst')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Employer's Middle Name</mat-label>
                            <input matInput name="ContactMiddle" formControlName="ContactMiddle"
                                matTooltipPosition="above">
                            <mat-error *ngIf="employerForm.controls.ContactMiddle.invalid">
                                {{getErrorMessage(employerForm, 'ContactMiddle')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Employer's Last Name</mat-label>
                            <input matInput name="ContactLast" formControlName="ContactLast" required
                                matTooltipPosition="above">
                            <mat-error *ngIf="employerForm.controls.ContactLast.invalid">
                                {{getErrorMessage(employerForm, 'ContactLast')}}
                            </mat-error>
                        </mat-form-field>

                        
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Mobile number</mat-label>
                            <input matInput name="mobile1" formControlName="Mobile1" required digitOnly minlength="10"
                                maxlength="10" matTooltip="Enter the mobile number of primary contact" matTooltipPosition="above">
                            <mat-error *ngIf="employerForm.controls.Mobile1.invalid">
                                {{getErrorMessage(employerForm, 'Mobile1')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Whatsapp number</mat-label>
                            <input matInput name="mobile2" formControlName="Mobile2" digitOnly minlength="10"
                                maxlength="10">
                            <mat-error *ngIf="employerForm.controls.Mobile2.invalid">
                                {{getErrorMessage(employerForm, 'Mobile2')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Email ID</mat-label>
                            <input matInput name="emailId1" formControlName="EmailId1">
                            <mat-error *ngIf="employerForm.controls.EmailId1.invalid">
                                {{getErrorMessage(employerForm, 'EmailId1')}}
                            </mat-error>
                        </mat-form-field>
                    </div>


                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        
                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Designation</mat-label>
                            <input matInput name="designation1" formControlName="Designation1" required
                                matTooltip="" matTooltipPosition="above">
                            <mat-error *ngIf="employerForm.controls.Designation1.invalid">
                                {{getErrorMessage(employerForm, 'Designation1')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Ready to provide internship?</mat-label>
                            <mat-select name="internship_agreed" formControlName="InternshipAgreed" appearance="outline"
                                [disabled]="PageRights.IsReadOnly" matTooltip="Internship Agreed"
                                matTooltipPosition="above">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                            <mat-error *ngIf="employerForm.controls.InternshipAgreed.invalid">
                                {{getErrorMessage(employerForm, 'InternshipAgreed')}}
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>Internship Description</mat-label>
                            <input matInput name="internship_description" formControlName="InternshipDescription"
                                matTooltip="" matTooltipPosition="above">
                            <mat-error *ngIf="employerForm.controls.InternshipDescription.invalid">
                                {{getErrorMessage(employerForm, 'InternshipDescription')}}
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="PageRights.ActionType !='new'">
                        <span class="checkbox-space">Is Active</span>
                        <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                        </mat-checkbox>
                    </div>
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>