import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StudentClassModel } from './student-class.model';
import { StudentClassService } from './student-class.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';

@Component({
  selector: 'data-list-view',
  templateUrl: './student-class.component.html',
  styleUrls: ['./student-class.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class StudentClassComponent extends BaseListComponent<StudentClassModel> implements OnInit {
  hideAddButton: string;
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    private dialogService: DialogService,
    private studentClassService: StudentClassService) {
    super(commonService, router, routeParams, snackBar, zone);
  }

  ngOnInit(): void {
    this.studentClassService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      //this.displayedColumns = ['AcademicYear', 'ClassName', 'SectionName', 'FullName', 'Gender', 'DateOfEnrollment', 'Mobile', 'CreatedOn', 'IsActive', 'Actions'];
      
      this.displayedColumns = [
        'SystemStudentUniqueID',
        'FullName',
        'Gender',
        'FatherName',
        'MotherName',
        'Mobile2',
        'Emailid',
        'DateOfBirth',
        'SchoolName',
        'SchoolUniqueId',
        'Udise',
        'AcademicYear',
        'ClassName',
        'SectionName',
        'StateName',
        'DivisionId',
        'DistrictCode',
        'BlockId',
        'CreatedBy',
        'DateOfEnrollment',
        'StudentRollNumber',
        'SchoolCategoryId',
        'SchoolManagementId',
        'Stream',
        'DoneInternshipEarlier',
        'DevicesAvailable',
        'InternshipAgreed',
        'PlannedStartDate',
        'PlannedEndDate',
        'EmployerId',
        'Sector',
        'JobRole',
        'Remarks',
        'CreatedOn',
        'IsActive',
        'Actions'];

        if (this.UserModel.StateId == 'DL') {
          const schoolUniqueIdIndex = this.displayedColumns.indexOf('SchoolUniqueId');
          if (schoolUniqueIdIndex === -1) {
            this.displayedColumns.splice(9, 0, 'SchoolUniqueId');
          }
        } else {
          const index = this.displayedColumns.indexOf('SchoolUniqueId');
          if (index !== -1) {
            this.displayedColumns.splice(index, 1);
          }
        }

        if((this.UserModel.StateId == "MH" || "DL") && (this.UserModel.RoleCode == "VT")){
          this.hideAddButton = this.UserModel.StateId;

        };
      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }

  onDeleteStudentClass(studentId: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.studentClassService.deleteStudentClassById(studentId)
            .subscribe((studentClassResp: any) => {

              this.zone.run(() => {
                if (studentClassResp.Success) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                }
                this.ngOnInit();
              }, error => {
                console.log('StudentClass deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }
}
