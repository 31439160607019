export const locale = {
    lang: 'gj',
    data: {
        'VT_SCHOOL_SECTOR': {
            'VT_SCHOOL_SECTORS': 'વીટી શાળા ક્ષેત્રો',
            'ADD_NEW_VT_SCHOOL_SECTORS': 'ઉમેરો',
            'SEARCH_FOR_A_VT_SCHOOL_SECTORS': 'વીટી શાળા ક્ષેત્રો માટે શોધ કરો',
        }
    }
};

