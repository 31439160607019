import { Component, OnInit, NgZone, ViewEncapsulation } from "@angular/core";
import { BaseComponent } from "app/common/base/base.component";
import { CommonService } from "app/services/common.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import { DialogService } from "app/common/confirm-dialog/dialog.service";
import { RouteConstants } from "app/constants/route.constant";
import { StudentClassService } from "../student-class.service";
import { StudentClassModel } from "../student-class.model";
import { DropdownModel } from "app/models/dropdown.model";

@Component({
  selector: "student-class",
  templateUrl: "./create-student-class.component.html",
  styleUrls: ["./create-student-class.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class CreateStudentClassComponent
  extends BaseComponent<StudentClassModel>
  implements OnInit {
  studentClassForm: FormGroup;
  studentClassModel: StudentClassModel;
  academicYearList: [DropdownModel];
  classList: [DropdownModel];
  sectionList: [DropdownModel];
  genderList: [DropdownModel];
  schoolCategoryList: [DropdownModel];
  schoolManagementList: [DropdownModel];
  createdByMultipleVtIdData: any;
  divisionList: [DropdownModel];
  stateList: [DropdownModel];
  districtList: [DropdownModel];
  blockList: [DropdownModel];
  clusterList: [DropdownModel];
  schoolList: [DropdownModel];
  vocationalTrainerList: [DropdownModel];
  employerList: [DropdownModel];
  divisionListForEmployer: [DropdownModel];
  districtListForEmployer: [DropdownModel];
  blockListForEmployer: [DropdownModel];
  // divisonFilterEmployerList : [DropdownModel];
  SchoolUdise: [String];
  JobRole: [String];
  Sector: [String];
  filteredEmployerItems: any;

  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private studentClassService: StudentClassService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder
  ) {
    super(commonService, router, routeParams, snackBar);

    // Set the default studentClass Model
    this.studentClassModel = new StudentClassModel();
  }

  ngOnInit(): void {
    this.studentClassService.getDropdownforStudentClass(this.UserModel).subscribe((results) => {

      if (this.UserModel.RoleCode === 'VT') {
        if (results[0].Success) {
          this.academicYearList = results[0].Results;
        }

      } else {
        if (results[1].Success) {
          this.academicYearList = results[1].Results;
        }
      }


      if (results[2].Success) {
        this.genderList = results[2].Results;
      }

      if (results[3].Success) {
        this.stateList = results[3].Results;
      }

      if (results[4].Success) {
        this.schoolCategoryList = results[4].Results;
      }

      // if (results[4].Success) {
      //   this.EmployerList = results[4].Results;
      //   this.filteredEmployerItems = this.EmployerList.slice();
      // }

      if (this.UserModel.RoleCode === 'VT') {

        if (results[5].Success) {
          this.JobRole = results[5].Results[1].Name;
          this.studentClassForm.get("JobRole").setValue(this.JobRole);
        }

        if (results[6].Success) {
          this.Sector = results[6].Results[1].Name;
          this.studentClassForm.get("Sector").setValue(this.Sector);
        }
      }

      if (this.UserModel.RoleCode === 'VT') {
        if (results[7].Success) {
          this.schoolList = results[7].Results;
        }

      } else {
        if (results[8].Success) {
          this.schoolList = results[8].Results;
        }
      }

      if (results[9].Success) {
        this.schoolManagementList = results[9].Results;
      }
      if (results[10].Success) {
        this.vocationalTrainerList = results[10].Results;
        }
      this.route.paramMap.subscribe((params) => {
        // console.log(this.PageRights.ActionType);
        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get("actionType");

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.studentClassModel = new StudentClassModel();
          } else {
            var studentId: string = params.get("studentId");

            this.studentClassService
              .getStudentClassById(studentId).subscribe((response: any) => {
                this.studentClassModel = response.Result;
                if(response.Result.DevicesAvailable !== null){
                  this.studentClassModel.DevicesAvailable = response.Result.DevicesAvailable.split(",");
              }

                if (this.PageRights.ActionType == this.Constants.Actions.Edit) {
                  this.studentClassModel.RequestType = this.Constants.PageType.Edit;
                  this.setDropoutReasonValidators();

                  this.initiateEmployerDetails(this.studentClassModel.EmployerId);

                } else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.studentClassModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                  this.initiateEmployerDetails(this.studentClassModel.EmployerId);
                }

                this.onChangeAcademicYear(this.studentClassModel.AcademicYearId);
                this.onChangeClass(this.studentClassModel.ClassId);
                this.onChangeState(this.studentClassModel.StateName);
                this.onChangeDivision(this.studentClassModel.DivisionId);
                this.onChangeDistrict(this.studentClassModel.DistrictCode);

                this.onChangeSchool(this.studentClassModel.SchoolId);
                this.onChangeEmployerDivision(this.studentClassModel.EmployerDivisionId);
                this.onChangeEmployerDistrict(this.studentClassModel.EmployerDistrictCode);
                this.onChangeEmployerBlock(this.studentClassModel.EmployerBlockId);
                this.onChangeVocationalTrainer(this.studentClassModel.VTId);

                this.studentClassForm = this.createStudentClassForm();
              });
          }
        }
      });
    });
    this.studentClassForm = this.createStudentClassForm();
  }

  initiateEmployerDetails(EmployerId) {
    this.commonService.GetMasterDataByType({ DataType: "EmployerById", ParentId: EmployerId, UserId: this.UserModel.UserTypeId, SelectTitle: "Employer", })
      .subscribe((response) => {
        if (response.Success) {
          var EmployerDetail = response.Results[1].Description;
          var fields = EmployerDetail.split('/');

          this.onChangeEmployerDivision(fields[0]);
          this.onChangeEmployerDistrict(fields[1]);
          this.onChangeEmployerBlock(fields[2]);

          this.studentClassForm.get("EmployerDivisionId").setValue(fields[0]);
          this.studentClassForm.get("EmployerDistrictCode").setValue(fields[1]);
          this.studentClassForm.get("EmployerBlockId").setValue(fields[2]);
          console.log("Hello");
          console.log(response);
          console.log(response.AuthToken);
        }
      });

  }

  onChangeVocationalTrainer(VTId) {
    if (this.UserModel.RoleCode === 'VC') {
      this.commonService.GetMasterDataByType({ DataType: 'SchoolsByVT', ParentId: VTId, SelectTitle: "School", })
      .subscribe((response: any) => {
        if(response.Success){
          this.schoolList = response.Results;
        }
      });
    }
  }
  
  onChangeAcademicYear(academicYearId) {

    if (this.UserModel.RoleCode === 'VT') {
      this.commonService.GetMasterDataByType({ DataType: "ClassesByVT", ParentId: academicYearId, UserId: this.UserModel.UserTypeId, SelectTitle: "Class", })
        .subscribe((response) => {
          if (response.Success) {
            this.classList = response.Results;
          }
        });
    } else {

      this.commonService.GetMasterDataByType({ DataType: "Classes", ParentId: academicYearId, UserId: this.UserModel.UserTypeId, SelectTitle: "Class", })
        .subscribe((response) => {
          if (response.Success) {
            this.classList = response.Results;
          }
        });
    }
  }

  onChangeClass(classId) {
    if (this.UserModel.RoleCode === 'VT') {
      this.commonService.GetMasterDataByType({ DataType: "SectionsByVT", ParentId: classId, UserId: this.UserModel.UserTypeId, SelectTitle: "Section", })
        .subscribe((response) => {
          if (response.Success) {
            this.sectionList = response.Results;
          }
        });
    } else {
      this.commonService.GetMasterDataByType({ DataType: "Sections", SelectTitle: "Section", })
        .subscribe((response) => {
          if (response.Success) {
            this.sectionList = response.Results;
          }
        });
    }
  }

  onChangeState(stateId: any) {
    this.commonService.GetMasterDataByType({ DataType: "Divisions", ParentId: stateId, SelectTitle: "Division", }).subscribe((response: any) => {
      this.divisionList = response.Results;
    });

    this.commonService.GetMasterDataByType({ DataType: 'Employers', ParentId: stateId, SelectTitle: 'Employers' }).subscribe((response: any) => {
      this.employerList = response.Results;
      this.filteredEmployerItems = this.employerList.slice();
    });

    this.commonService.GetMasterDataByType({ DataType: "Divisions", ParentId: stateId, SelectTitle: "Division", }).subscribe((response: any) => {
      this.divisionListForEmployer = response.Results;
    });
  }

  onChangeDivision(divisionId: any) {
    var stateCode = this.studentClassForm.get("StateName").value;

    this.commonService.GetMasterDataByType({ DataType: "Districts", UserId: stateCode, ParentId: divisionId, SelectTitle: "District", })
      .subscribe((response: any) => {
        this.districtList = response.Results;
      });
  }

  onChangeDistrict(districtId: any) {
    this.commonService.GetMasterDataByType({ DataType: "Blocks", UserId: this.UserModel.DefaultStateId, ParentId: districtId, SelectTitle: "Block", })
      .subscribe((response: any) => {
        this.blockList = response.Results;
      });
  }

  // onChangeBlock(BlockId: any) {
  //   this.commonService.GetMasterDataByType({ DataType: 'SchoolsByBlock', ParentId: BlockId, SelectTitle: 'School' }).subscribe((response: any) => {
  //     this.schoolList = response.Results;
  //   });
  // }

  onChangeSchool(SchoolId: any) {
    this.commonService.GetMasterDataByType({ DataType: "SchoolDetails", ParentId: SchoolId, SelectTitle: "SchoolDetail", })
      .subscribe((response: any) => {
        var SchoolDetail = response.Results[1].Description;
        var fields = SchoolDetail.split('/');
        this.studentClassForm.get("Udise").setValue(fields[0]);
        this.onChangeState(fields[1]);
        this.onChangeDivision(fields[2]);
        this.onChangeDistrict(fields[3]);
        this.studentClassForm.get("StateName").setValue(fields[1]);
        this.studentClassForm.get("DivisionId").setValue(fields[2]);
        this.studentClassForm.get("DistrictCode").setValue(fields[3]);
        this.studentClassForm.get("BlockId").setValue(fields[4]);
        this.studentClassForm.get("SchoolCategoryId").setValue(fields[5]);
        this.studentClassForm.get("SchoolManagementId").setValue(fields[6]);
      });
  }

  onChangeEmployerDivision(divisionId: any) {
    var stateCode = this.studentClassForm.get("StateName").value;

    this.commonService.GetMasterDataByType({ DataType: "Districts", UserId: stateCode, ParentId: divisionId, SelectTitle: "District", })
      .subscribe((response: any) => {
        this.districtListForEmployer = response.Results;
      });

    this.commonService.GetMasterDataByType({ DataType: 'DivisionEmployers', ParentId: divisionId, SelectTitle: 'DivisionEmployers' })
      .subscribe((response: any) => {
        this.employerList = response.Results;
        this.filteredEmployerItems = this.employerList.slice();
      });

  }

  onChangeEmployerDistrict(districtId: any) {
    var stateCode = this.studentClassForm.get("StateName").value;

    this.commonService.GetMasterDataByType({ DataType: "Blocks", UserId: stateCode, ParentId: districtId, SelectTitle: "Block", })
      .subscribe((response: any) => {
        this.blockListForEmployer = response.Results;
      });

    this.commonService.GetMasterDataByType({ DataType: 'DistrictEmployers', ParentId: districtId, SelectTitle: 'DistrictEmployers' }).subscribe((response: any) => {
      this.employerList = response.Results;
      this.filteredEmployerItems = this.employerList.slice();
    });

  }

  onChangeEmployerBlock(blockId: any) {
    this.commonService.GetMasterDataByType({ DataType: 'BlockEmployers', ParentId: blockId, SelectTitle: 'BlockEmployers' }).subscribe((response: any) => {
      this.employerList = response.Results;
      this.filteredEmployerItems = this.employerList.slice();
    });

  }

  saveOrUpdateStudentClassDetails() {
    if (!this.studentClassForm.valid) {
      this.validateAllFormFields(this.studentClassForm);
      return;
    }

    var DevicesAvailable = this.studentClassForm.get("DevicesAvailable").value;

    this.setValueFromFormGroup(this.studentClassForm, this.studentClassModel);

    if (this.UserModel.RoleCode == 'VT') {
      this.studentClassModel.VTId = this.UserModel.UserTypeId;
    } else {
      this.studentClassModel.VTId = this.studentClassForm.value.VTId;
    }

    if (DevicesAvailable) {
      this.studentClassModel.DevicesAvailable = DevicesAvailable.join(",");
    }

    const dateOfDropoutControl = this.studentClassForm.get("DateOfDropout");
    if (dateOfDropoutControl.value == null) {
      this.studentClassModel.DateOfDropout = null;
      this.studentClassModel.DropoutReason = null;
    }

    this.studentClassService
      .createOrUpdateStudentClass(this.studentClassModel)
      .subscribe(
        (studentClassResp: any) => {
          if (studentClassResp.Success) {
            this.zone.run(() => {
              this.showActionMessage(
                this.Constants.Messages.RecordSavedMessage,
                this.Constants.Html.SuccessSnackbar
              );

              this.router.navigate([RouteConstants.StudentClass.List]);
            });
          } else {
            var errorMessages = this.getHtmlMessage(studentClassResp.Errors);
            this.dialogService.openShowDialog(errorMessages);
          }
        },
        (error) => {
          console.log("StudentClass deletion errors =>", error);
        }
      );
  }

  setDropoutReasonValidators() {
    const dropoutReasonControl = this.studentClassForm.get("DropoutReason");

    this.studentClassForm
      .get("DateOfDropout")
      .valueChanges.subscribe((dateOfDropoutValue) => {
        debugger;
        dropoutReasonControl.clearValidators();
        if (dateOfDropoutValue == null || dateOfDropoutValue == "") {
          dropoutReasonControl.setValidators([Validators.maxLength(350)]);
        } else {
          dropoutReasonControl.setValidators([
            Validators.required,
            Validators.maxLength(350),
          ]);
        }
        dropoutReasonControl.updateValueAndValidity();

        const dateOfDropoutControl = this.studentClassForm.get("DateOfDropout");
        if (
          dateOfDropoutControl.value != null &&
          dateOfDropoutControl.value != ""
        ) {
          dateOfDropoutControl.disable();
        } else {
          dateOfDropoutControl.enable();
        }
      });

    if (
      this.studentClassModel.DropoutReason == null ||
      this.studentClassModel.DropoutReason == ""
    ) {
      this.studentClassForm.get("IsActive").enable();
    } else {
      this.studentClassForm.get("IsActive").disable();
    }
  }

  //Create studentClass form and returns {FormGroup}
  createStudentClassForm(): FormGroup {
    return this.formBuilder.group({
      StudentId: new FormControl(this.studentClassModel.StudentId),
      AcademicYearId: new FormControl({ value: this.studentClassModel.AcademicYearId, disabled: this.PageRights.IsReadOnly, }, Validators.required),
      ClassId: new FormControl({ value: this.studentClassModel.ClassId, disabled: this.PageRights.IsReadOnly, }, Validators.required),
      SectionId: new FormControl({ value: this.studentClassModel.SectionId, disabled: this.PageRights.IsReadOnly, }),
      FirstName: new FormControl({ value: this.studentClassModel.FirstName, disabled: this.PageRights.IsReadOnly, }, [Validators.required, Validators.maxLength(100)]),
      MiddleName: new FormControl({ value: this.studentClassModel.MiddleName, disabled: this.PageRights.IsReadOnly, }, [Validators.maxLength(50)]),
      LastName: new FormControl({ value: this.studentClassModel.LastName, disabled: this.PageRights.IsReadOnly, }, [Validators.maxLength(50)]),
      FullName: new FormControl({ value: this.studentClassModel.FullName, disabled: this.PageRights.IsReadOnly, }, [Validators.maxLength(150)]),
      MotherName: new FormControl({ value: this.studentClassModel.MotherName, disabled: this.PageRights.IsReadOnly, }, [Validators.maxLength(128)]),
      FatherName: new FormControl({ value: this.studentClassModel.FatherName, disabled: this.PageRights.IsReadOnly, }, [Validators.maxLength(128)]),
      DateOfBirth: new FormControl({ value: this.getDateValue(this.studentClassModel.DateOfBirth), disabled: this.PageRights.IsReadOnly, }),
      Emailid: new FormControl({ value: this.studentClassModel.Emailid, disabled: this.PageRights.IsReadOnly, }, [Validators.maxLength(150), Validators.pattern(this.Constants.Regex.Email),]),
      Sector: new FormControl({ value: this.studentClassModel.Sector, disabled: true }, [Validators.maxLength(150)]),
      JobRole: new FormControl({ value: this.studentClassModel.JobRole, disabled: true }, [Validators.maxLength(150)]),
      SchoolId: new FormControl({ value: this.studentClassModel.SchoolId, disabled: this.PageRights.IsReadOnly, }, [Validators.maxLength(128), Validators.required]),
      VTId: new FormControl({ value: this.studentClassModel.VTId, disabled: this.PageRights.IsReadOnly, }, [Validators.maxLength(128), Validators.required]),
      DistrictCode: new FormControl({ value: this.studentClassModel.DistrictCode, disabled: this.PageRights.IsReadOnly, }, [Validators.maxLength(128)]),

      EmployerDivisionId: new FormControl({ value: this.studentClassModel.EmployerDivisionId, disabled: this.PageRights.IsReadOnly, }),
      EmployerDistrictCode: new FormControl({ value: this.studentClassModel.EmployerDistrictCode, disabled: this.PageRights.IsReadOnly, }),
      EmployerBlockId: new FormControl({ value: this.studentClassModel.EmployerBlockId, disabled: this.PageRights.IsReadOnly, }),

      StateName: new FormControl({ value: this.studentClassModel.StateName, disabled: this.PageRights.IsReadOnly, }, [Validators.maxLength(128)]),
      DevicesAvailable: new FormControl({ value: this.studentClassModel.DevicesAvailable, disabled: this.PageRights.IsReadOnly, }),
      StudentRollNumber: new FormControl({ value: this.studentClassModel.StudentRollNumber, disabled: this.PageRights.IsReadOnly, }, [Validators.maxLength(128)]),
      SchoolCategoryId: new FormControl({ value: this.studentClassModel.SchoolCategoryId, disabled: this.PageRights.IsReadOnly, }),
      SchoolManagementId: new FormControl({ value: this.studentClassModel.SchoolManagementId, disabled: this.PageRights.IsReadOnly, }),
      Udise: new FormControl({ value: this.studentClassModel.Udise, disabled: this.PageRights.IsReadOnly, }, [Validators.maxLength(11), Validators.minLength(11), Validators.required, Validators.pattern(this.Constants.Regex.Number),]),

      DivisionId: new FormControl({ value: this.studentClassModel.DivisionId, disabled: this.PageRights.IsReadOnly, }, Validators.required),
      BlockId: new FormControl({ value: this.studentClassModel.BlockId, disabled: this.PageRights.IsReadOnly, }),

      InternshipAgreed: new FormControl({ value: this.studentClassModel.InternshipAgreed, disabled: this.PageRights.IsReadOnly, }),
      PlannedStartDate: new FormControl({ value: this.getDateValue(this.studentClassModel.PlannedStartDate), disabled: this.PageRights.IsReadOnly, }),
      PlannedEndDate: new FormControl({ value: this.getDateValue(this.studentClassModel.PlannedEndDate), disabled: this.PageRights.IsReadOnly, }),
      EmployerId: new FormControl({ value: this.studentClassModel.EmployerId, disabled: this.PageRights.IsReadOnly, }, [Validators.maxLength(128)]),
      Stream: new FormControl({ value: this.studentClassModel.Stream, disabled: this.PageRights.IsReadOnly, }, [Validators.maxLength(128)]),
      DoneInternshipEarlier: new FormControl({ value: this.studentClassModel.DoneInternshipEarlier, disabled: this.PageRights.IsReadOnly, }, [Validators.maxLength(128)]),
      Remarks: new FormControl({ value: this.studentClassModel.Remarks, disabled: this.PageRights.IsReadOnly, }, [Validators.maxLength(225)]),
      Gender: new FormControl({ value: this.studentClassModel.Gender, disabled: this.PageRights.IsReadOnly, }, [Validators.required, Validators.maxLength(10)]),
      Mobile: new FormControl({ value: this.studentClassModel.Mobile, disabled: this.PageRights.IsReadOnly, }),
      Mobile2: new FormControl({ value: this.studentClassModel.Mobile2, disabled: this.PageRights.IsReadOnly, }),
      DateOfEnrollment: new FormControl({ value: new Date(this.studentClassModel.DateOfEnrollment), disabled: this.PageRights.IsReadOnly, }),
      DateOfDropout: new FormControl({ value: this.getDateValue(this.studentClassModel.DateOfDropout), disabled: this.PageRights.IsReadOnly, }),
      DropoutReason: new FormControl({ value: this.studentClassModel.DropoutReason, disabled: this.PageRights.IsReadOnly, }, [Validators.maxLength(350)]),
      IsActive: new FormControl({ value: this.studentClassModel.IsActive, disabled: this.PageRights.IsReadOnly, }),
    });
  }
}
