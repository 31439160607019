<div id="tool-equipment" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/tool-equipments'"
                    skipLocationChange="true" replaceUrl="false">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ toolEquipmentModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New Tool Equipment
                    </div>
                    <div class="subtitle secondary-text">
                        <span>Tool Equipment Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-tool-equipment-button" *ngIf="PageRights.ActionType ==='new'"
                (click)="saveOrUpdateToolEquipmentDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-tool-equipment-button" *ngIf="PageRights.ActionType ==='edit'"
                (click)="saveOrUpdateToolEquipmentDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="toolEquipmentForm" [formGroup]="toolEquipmentForm" class="tool-equipment w-100-p p-24 mr-24"
                    fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select Academic Year</mat-label>

                            <mat-select formControlName="AcademicYearId" appearance="outline" required
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="academicYearItem.Id"
                                    *ngFor="let academicYearItem of academicYearList">
                                    {{ academicYearItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="toolEquipmentForm.controls.AcademicYearId.invalid">
                                {{getErrorMessage(toolEquipmentForm, 'AcademicYearId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select Sector</mat-label>

                            <mat-select formControlName="SectorId" appearance="outline" required
                                [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeSector($event.value)">
                                <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                    {{ sectorItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="toolEquipmentForm.controls.SectorId.invalid">
                                {{getErrorMessage(toolEquipmentForm, 'SectorId')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select Job Role</mat-label>

                            <mat-select formControlName="JobRoleId" appearance="outline" required
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="jobRoleItem.Id" *ngFor="let jobRoleItem of jobRoleList">
                                    {{ jobRoleItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="toolEquipmentForm.controls.JobRoleId.invalid">
                                {{getErrorMessage(toolEquipmentForm, 'JobRoleId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Receipt Date</mat-label>
                            <input matInput name="receiptDate" formControlName="ReceiptDate" required
                                [matDatepicker]="receiptDate" [disabled]="PageRights.IsReadOnly" [max]="CurrentDate" [readonly]="true" (click)="receiptDate.open()">
                            <mat-datepicker-toggle matSuffix [for]="receiptDate"></mat-datepicker-toggle>
                            <mat-datepicker #receiptDate></mat-datepicker>

                            <mat-error *ngIf="toolEquipmentForm.controls.ReceiptDate.invalid">
                                {{getErrorMessage(toolEquipmentForm, 'ReceiptDate')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-label fxFlex="30" class="radio-label-tc">Tools & Equipment Receiving Status</mat-label>
                        
                        <mat-radio-group aria-labelledby="Select Tools & Equipment Receiving Status"
                            name="teReceiveStatus" formControlName="TEReceiveStatus" required appearance="outline"
                            fxFlex="70" fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="20px" class="radio-space-tc"
                            [disabled]="PageRights.IsReadOnly">
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                            <mat-radio-button value="No">No</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">
                        <mat-label fxFlex="30" class="radio-label-tc">Tools & Equipment Status</mat-label>
                      
                        <mat-radio-group aria-labelledby="Select Tools & Equipment Status" name="teStatus"
                            formControlName="TEStatus" required appearance="outline" fxFlex="70" fxFlex.lt-md="75"
                            fxFlex.lt-sm="100" fxLayoutGap="20px" class="radio-space-tc"
                            [disabled]="PageRights.IsReadOnly">
                            <mat-radio-button value="Working">Working</mat-radio-button>
                            <mat-radio-button value="Partially Working">Partially Working</mat-radio-button>
                            <mat-radio-button value="Not Working">Not Working</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-label fxFlex="30" class="radio-label-tc">Raw Material Availability Status</mat-label>
                        <mat-radio-group aria-labelledby="Select Raw Material Availability Status" name="rmStatus"
                            formControlName="RMStatus" required appearance="outline" fxFlex="70" fxFlex.lt-md="75"
                            fxFlex.lt-sm="100" fxLayoutGap="20px" class="radio-space-tc"
                            [disabled]="PageRights.IsReadOnly">
                            <mat-radio-button value="Available">Available</mat-radio-button>
                            <mat-radio-button value="Not Availlable">Not Availlable</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-label fxFlex="30" class="radio-label-tc">Raw Material Fund Status</mat-label>
                        <mat-radio-group aria-labelledby="Select Raw Material Fund Status" name="rmFundStatus"
                            formControlName="RMFundStatus" required appearance="outline" fxFlex="70" fxFlex.lt-md="75"
                            fxFlex.lt-sm="100" fxLayoutGap="20px" class="radio-space-tc"
                            [disabled]="PageRights.IsReadOnly">
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                            <mat-radio-button value="No">No</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Details</mat-label>
                            <input matInput name="details" formControlName="Details">
                            <mat-error *ngIf="toolEquipmentForm.controls.Details.invalid">
                                {{getErrorMessage(toolEquipmentForm, 'Details')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <span class="checkbox-space">Is Active</span>
                        <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                        </mat-checkbox>
                    </div> -->
                </form>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>