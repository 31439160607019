import { DatePipe } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { FuseUtils } from '@fuse/utils';

export class StudentClassModel {
    StudentId: string;
    VTId: string;
    // SchoolId: string;
    AcademicYearId: string;
    ClassId: string;
    SectionId: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    FullName: string;
    FatherName: string;
    MotherName: string;
    DateOfBirth: Date;
    Sector: string;
    JobRole: string;
    SchoolId: string;
    DistrictCode: string;
    EmployerDivisionId: string;
    EmployerDistrictCode: string;
    EmployerBlockId: string;
    StateName: string;
    Stream: string;
    DoneInternshipEarlier: string;
    Emailid: string;

    StudentRollNumber: string;
    SchoolCategoryId: string;
    SchoolManagementId: string;
    Udise: string;
    DivisionId: string;
    BlockId: string;
    InternshipAgreed: string;
    PlannedStartDate: Date;
    PlannedEndDate: Date;
    // ActualStartDate: Date;
    // ActualEndDate: Date;
    EmployerId: string;

    DevicesAvailable: any;

    Remarks: string;
    Gender: string;
    Mobile: string;
    Mobile2: string;
    DateOfEnrollment: Date;
    DateOfDropout: Date;
    DropoutReason: string;
    IsActive: boolean;
    RequestType: any;

    constructor(studentClassItem?: any) {
        studentClassItem = studentClassItem || {};

        this.StudentId = studentClassItem.StudentId || FuseUtils.NewGuid();
        this.VTId = studentClassItem.VTId || FuseUtils.NewGuid();
        this.SchoolId = studentClassItem.SchoolId || '';
        this.AcademicYearId = studentClassItem.AcademicYearId || '';
        this.ClassId = studentClassItem.ClassId || '';
        this.SectionId = studentClassItem.SectionId || '';
        this.DateOfEnrollment = studentClassItem.DateOfEnrollment || '';
        this.FatherName = studentClassItem.FatherName || '';
        this.MotherName = studentClassItem.MotherName || '';
        this.DateOfBirth = studentClassItem.DateOfBirth || '';
        this.Sector = studentClassItem.Sector || '';
        this.JobRole = studentClassItem.JobRole || '';
        this.DistrictCode = studentClassItem.DistrictCode || '';
        this.StateName = studentClassItem.StateName || '';
        this.Emailid = studentClassItem.Emailid || '';

        this.DevicesAvailable = studentClassItem.DevicesAvailable || '';
        this.StudentRollNumber = studentClassItem.StudentRollNumber || '';
        this.SchoolCategoryId = studentClassItem.SchoolCategoryId || '';
        this.SchoolManagementId = studentClassItem.SchoolManagementId || '';
        this.Udise = studentClassItem.Udise || '';
        this.DivisionId = studentClassItem.DivisionId || '';
        this.EmployerDivisionId = studentClassItem.EmployerDivisionId || '';
        this.EmployerDistrictCode = studentClassItem.EmployerDistrictCode || '';
        this.EmployerBlockId = studentClassItem.EmployerBlockId || '';
        this.BlockId = studentClassItem.BlockId || '';
        this.InternshipAgreed = studentClassItem.InternshipAgreed || '';
        this.PlannedStartDate = studentClassItem.PlannedStartDate || '';
        this.PlannedEndDate = studentClassItem.PlannedEndDate || '';
        // this.ActualStartDate = studentClassItem.ActualStartDate || '';
        // this.ActualEndDate = studentClassItem.ActualEndDate || '';
        this.EmployerId = studentClassItem.EmployerId || '';

        this.Stream = studentClassItem.Stream || '';
        this.DoneInternshipEarlier = studentClassItem.DoneInternshipEarlier || '';

        this.Remarks = studentClassItem.Remarks || '';
        this.FirstName = studentClassItem.FirstName || '';
        this.MiddleName = studentClassItem.MiddleName || '';
        this.LastName = studentClassItem.LastName || '';
        this.FullName = studentClassItem.FullName || '';
        this.Gender = studentClassItem.Gender || '';
        this.Mobile = studentClassItem.Mobile || '';
        this.Mobile2 = studentClassItem.Mobile2 || '';
        this.DateOfDropout = studentClassItem.DateOfDropout || '';
        this.DropoutReason = studentClassItem.DropoutReason || '';
        this.IsActive = studentClassItem.IsActive || true;
        this.RequestType = 0; // New
    }
}
