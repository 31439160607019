<div id="student-class" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    STUDENT REGISTRATIONS
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)"
                        placeholder="Search for a student registrations">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons" style="display: flex;">
                <ng-container *ngIf="hideAddButton !== 'MH'; else">
                    <button *ngIf="PageRights.IsAdd" mat-raised-button [routerLink]="['./', 'new']" skipLocationChange="true" replaceUrl="false" class="add-student-class-button fuse-white mt-24 mt-md-0">
                        <span>ADD</span>
                    </button>
                </ng-container>
          
            <div>
                <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exporter.exportTable('xlsx', {fileName: 'StudentRegistration', sheet: 'StudentRegistration', Props: {Author: 'Lighthouse'}})">
                    <mat-icon>cloud_download</mat-icon>
                </a>
                </div>
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table matTableExporter class="student-class-table responsive-table" #table [dataSource]="tableDataSource" #exporter="matTableExporter" [hiddenColumns]="[32]"
            matSort fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

            <!-- FullName Column -->
            <ng-container matColumnDef="SystemStudentUniqueID">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>SystemStudentUniqueID
                </mat-header-cell>
                <mat-cell *matCellDef="let studentClass">
                    <p class="text-truncate">{{studentClass.SystemStudentUniqueID}}</p>
                </mat-cell>
            </ng-container>    
            
            <!-- FullName Column -->
                <ng-container matColumnDef="FullName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Full Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.FullName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SchoolName Column -->
                <ng-container matColumnDef="SchoolName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.SchoolName}}</p>
                    </mat-cell>
                </ng-container>
    
                <!-- AcademicYear Column -->
                <ng-container matColumnDef="AcademicYear">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Academic Year
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.AcademicYear}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ClassName Column -->
                <ng-container matColumnDef="ClassName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Class</mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.ClassName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SectionName Column -->
                <ng-container matColumnDef="SectionName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Section
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.SectionName}}</p>
                    </mat-cell>
                </ng-container>
                
                <!-- Gender Column -->
                <ng-container matColumnDef="Gender">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Gender
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.Gender}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DateOfEnrollment Column -->
                <ng-container matColumnDef="DateOfEnrollment">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Date of student's enrollement
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.DateOfEnrollment | date: Constants.ShortDateFormat }}</p>
                    </mat-cell>
                </ng-container>

                 <!-- Mobile2 Column -->
                 <ng-container matColumnDef="Mobile2">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Whatsapp number
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.Mobile2}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Emailid Column -->
                <ng-container matColumnDef="Emailid">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Emailid
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.Emailid}}</p>
                    </mat-cell>
                </ng-container>

                <!-- FatherName Column -->
                <ng-container matColumnDef="FatherName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Father Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.FatherName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- MotherName Column -->
                <ng-container matColumnDef="MotherName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Mother Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.MotherName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DateOfBirth Column -->
                <ng-container matColumnDef="DateOfBirth">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Date Of Birth
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.DateOfBirth | date: Constants.ShortDateFormat }}</p>
                    </mat-cell>
                </ng-container>

                <!-- Sector Column -->
                <ng-container matColumnDef="Sector">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Sector
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.Sector}}</p>
                    </mat-cell>
                </ng-container>

                <!-- JobRole Column -->
                <ng-container matColumnDef="JobRole">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Job Role
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.JobRole}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DistrictCode Column -->
                <ng-container matColumnDef="DistrictCode">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>District
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.DistrictCode}}</p>
                    </mat-cell>
                </ng-container>

                <!-- StateName Column -->
                <ng-container matColumnDef="StateName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>State
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.StateName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- StudentRollNumber Column -->
                <ng-container matColumnDef="StudentRollNumber">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Roll Number
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.StudentRollNumber}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SchoolCategoryId Column -->
                <ng-container matColumnDef="SchoolCategoryId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>School Category
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.SchoolCategoryId}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SchoolManagementId Column -->
                <ng-container matColumnDef="SchoolManagementId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>School Management
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.SchoolManagementId}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SchoolUniqueId Column -->
                <ng-container matColumnDef="SchoolUniqueId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>SchoolId
                    </mat-header-cell>
                      <mat-cell *matCellDef="let school" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{school.SchoolUniqueId}}</p>
                      </mat-cell>
                </ng-container>

                 <!-- Udise Column -->
                 <ng-container matColumnDef="Udise">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Udise
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.Udise}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DivisionId Column -->
                <ng-container matColumnDef="DivisionId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Division
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.DivisionId}}</p>
                    </mat-cell>
                </ng-container>

                <!-- BlockId Column -->
                <ng-container matColumnDef="BlockId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Block
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.BlockId}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VT Column -->
                <ng-container matColumnDef="CreatedBy">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Vocational Trainer
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.CreatedBy}}</p>
                    </mat-cell>
                </ng-container>

                <!-- InternshipAgreed Column -->
                <ng-container matColumnDef="InternshipAgreed">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Is student ready for internship?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.InternshipAgreed}}</p>
                    </mat-cell>
                </ng-container>

                <!-- PlannedStartDate Column -->
                <ng-container matColumnDef="PlannedStartDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Planned Start Date
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.PlannedStartDate | date: Constants.ShortDateFormat }}</p>
                    </mat-cell>
                </ng-container>

                <!-- PlannedEndDate Column -->
                <ng-container matColumnDef="PlannedEndDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Planned End Date
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.PlannedEndDate | date: Constants.ShortDateFormat }}</p>
                    </mat-cell>
                </ng-container>

          
                <!-- <ng-container matColumnDef="ActualStartDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Actual Start Date
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.ActualStartDate | date: Constants.ShortDateFormat }}</p>
                    </mat-cell>
                </ng-container>

   
                <ng-container matColumnDef="ActualEndDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Actual End Date
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.ActualEndDate | date: Constants.ShortDateFormat }}</p>
                    </mat-cell>
                </ng-container> -->

                <!-- EmployerId Column -->
                <ng-container matColumnDef="EmployerId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Employer
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.EmployerId}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Stream Column -->
                <ng-container matColumnDef="Stream">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Stream
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.Stream}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DoneInternshipEarlier Column -->
                <ng-container matColumnDef="DoneInternshipEarlier">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Done Internship Earlier
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.DoneInternshipEarlier}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DevicesAvailable Column -->
                <ng-container matColumnDef="DevicesAvailable">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Devices Available at home
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.DevicesAvailable}}</p>
                    </mat-cell>
                </ng-container>


                <!-- Remarks Column -->
                <ng-container matColumnDef="Remarks">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Remarks
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.Remarks}}</p>
                    </mat-cell>
                </ng-container>

                <!-- CreatedOn Column -->
                <ng-container matColumnDef="CreatedOn">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide>Opt_in
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide>
                        <p class="text-truncate">{{studentClass.CreatedOn | date: Constants.ImportDateFormat }}</p>
                    </mat-cell>
                </ng-container>

                <!-- IsActive Column -->
                <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Is Active?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <mat-icon *ngIf="studentClass.IsActive" class="active-icon green-600 s-16">check</mat-icon>
                        <mat-icon *ngIf="!studentClass.IsActive" class="active-icon red-500 s-16">close</mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/student-registrations', 'view', studentClass.StudentId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsEdit && studentClass.DeletedBy == null" mat-icon-button
                            [routerLink]="['/student-registrations', 'edit', studentClass.StudentId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>
                        <button *ngIf="(PageRights.IsDelete && studentClass.DeletedBy == null)" mat-icon-button color="warn" (click)="onDeleteStudentClass(studentClass.StudentId)">
                            <mat-icon>delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let studentClass; columns: displayedColumns;" class="student-class" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[1, 5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
