<div id="vt-student-veresult" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    VT STUDENT VE RESULTS
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)" placeholder="Search for a vt student ve results">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <button mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false" class="add-vt-student-veresult-button fuse-white mt-24 mt-md-0">
                <span>ADD</span>
            </button>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="vt-student-veresult-table" #table [dataSource]="tableDataSource" matSort fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- VTClassId Column -->
                <ng-container matColumnDef="ClassName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Class Name</mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentVEResult">
                        <p class="text-truncate">{{vtStudentVEResult.ClassName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- StudentId Column -->
                <ng-container matColumnDef="StudentName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Student Name</mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentVEResult">
                        <p class="text-truncate">{{vtStudentVEResult.StudentName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DateIssuence Column -->
                <ng-container matColumnDef="DateIssuence">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Date Issuence</mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentVEResult" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vtStudentVEResult.DateIssuence | date: 'dd/MM/yyyy'}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ExternalMarks Column -->
                <ng-container matColumnDef="ExternalMarks">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>External Marks</mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentVEResult" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vtStudentVEResult.ExternalMarks}}</p>
                    </mat-cell>
                </ng-container>

                <!-- TheoryMarks Column -->
                <ng-container matColumnDef="TheoryMarks">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Theory Marks</mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentVEResult" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vtStudentVEResult.TheoryMarks}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Grade Column -->
                <ng-container matColumnDef="Grade">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Grade</mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentVEResult" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vtStudentVEResult.Grade}}</p>
                    </mat-cell>
                </ng-container>

                <!-- IsActive Column -->
                <!-- <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Is Active?</mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentVEResult" fxHide fxShow.gt-xs>
                        <mat-icon *ngIf="vtStudentVEResult.IsActive" class="active-icon green-600 s-16">check</mat-icon>
                        <mat-icon *ngIf="!vtStudentVEResult.IsActive" class="active-icon red-500 s-16">close</mat-icon>
                    </mat-cell>
                </ng-container> -->

                <ng-container matColumnDef="Actions">
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let vtStudentVEResult" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                        [routerLink]="['/vt-student-ve-results', 'view', vtStudentVEResult.VTStudentVEResultId]" skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsEdit" mat-icon-button
                        [routerLink]="['/vt-student-ve-results', 'edit', vtStudentVEResult.VTStudentVEResultId]" skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>
                        <button mat-icon-button color="warn" (click)="onDeleteVTStudentVEResult(vtStudentVEResult.VTStudentVEResultId)"
                            *ngIf="PageRights.IsDelete">
                            <mat-icon>delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let vtStudentVEResult; columns: displayedColumns;" class="vt-student-veresult" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
