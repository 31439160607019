import { FuseUtils } from '@fuse/utils';

export class VTStudentExitSurveyDetailModel {
    VTStudentExitSurveyDetailId: string;
    VTId: string;
    Date: Date;
    InterviewerName: string;
    Designation: string;
    CompletedJobRoleName: string;
    FromYear: string;
    ToYear: string;
    RollNo: string;
    Year: string;
    StudentFirstName: string;
    StudentMiddleName: string;
    StudentLastName: string;
    Gender: string;
    DateOfBirth: string;
    Age: string;
    StudentMobileNumber: string;
    StudentFirstRelativeName: string;
    StudentFirstRelativeRelation: string;
    StudentFirstRelativeMobile: string;
    StudentSecondRelativeName: string;
    StudentSecondRelativeRelation: string;
    StudentSecondRelativeMobile: string;
    FamilyMemberCount: string;
    FamilyMemberMigrate: string;
    ReasonOfMigration: string;
    Pleasemention: string;
    FatherOccupation: string;
    FatherOccupationDetails: string;
    MotherOccupation: string;
    MotherOccupationDetails: string;
    EmployementExchangeKnowledge: string;
    EnrolledInEmploymentOffice: string;
    CurrentEmployementStatus: string;
    EmployementOrganisation: string;
    NatureOfWork: string;
    EmployementInVocationalEducationSector: string;
    WorkDetails: string;
    EarningsPerMonth: string;
    ContinueStudies:string;
    CoursePursue: string;
    CourseDetails: string;
    CourseSelectionReason: string;
    ReasonDetails: string;
    PostCourseCompletionPlanning: string;
    TargetedEmployer: string;
    CapitalSource: string;
    OtherDetails: string;
    NotDecidedReason: string;
    VEHelpCourseCompletion: string;
    ExpectedIncome: string;
    OtherInformation: string;
    DiscontinueEducationReason: string;
    Plans: string;
    PlansDetails: string;
    PlansRelatedToVESector: string;
    VEHelpsToTakeDecision: string;
    IsActive: boolean;
    RequestType: any;

    constructor(vtStudentExitSurveyDetailItem?: any) {
        vtStudentExitSurveyDetailItem = vtStudentExitSurveyDetailItem || {};

        this.VTStudentExitSurveyDetailId = vtStudentExitSurveyDetailItem.VTStudentExitSurveyDetailId || FuseUtils.NewGuid();
        this.VTId = vtStudentExitSurveyDetailItem.VTId || FuseUtils.NewGuid();
        // this.VTClassId = vtStudentExitSurveyDetailItem.VTClassId || FuseUtils.NewGuid();
        this.Designation = vtStudentExitSurveyDetailItem.Designation || '';
        this.InterviewerName = vtStudentExitSurveyDetailItem.InterviewerName || '';
        this.CompletedJobRoleName = vtStudentExitSurveyDetailItem.CompletedJobRoleName || '';
        this.FromYear = vtStudentExitSurveyDetailItem.FromYear || '';
        this.ToYear = vtStudentExitSurveyDetailItem.ToYear || '';
        this.RollNo = vtStudentExitSurveyDetailItem.RollNo || '';
        this.Year = vtStudentExitSurveyDetailItem.Year || '';
        this.StudentFirstName = vtStudentExitSurveyDetailItem.StudentFirstName || '';
        this.StudentMiddleName = vtStudentExitSurveyDetailItem.StudentMiddleName || '';
        this.StudentLastName = vtStudentExitSurveyDetailItem.StudentLastName || '';
        this.Gender = vtStudentExitSurveyDetailItem.Gender || '';
        this.DateOfBirth = vtStudentExitSurveyDetailItem.DateOfBirth || '';
        this.Age = vtStudentExitSurveyDetailItem.Age || '';
        this.StudentMobileNumber = vtStudentExitSurveyDetailItem.StudentMobileNumber || '';
        this.StudentFirstRelativeName = vtStudentExitSurveyDetailItem.StudentFirstRelativeName || '';
        this.StudentFirstRelativeRelation = vtStudentExitSurveyDetailItem.StudentFirstRelativeRelation || '';
        this.StudentFirstRelativeMobile = vtStudentExitSurveyDetailItem.StudentFirstRelativeMobile || '';
        this.StudentSecondRelativeName = vtStudentExitSurveyDetailItem.StudentSecondRelativeName || '';
        this.StudentSecondRelativeRelation = vtStudentExitSurveyDetailItem.StudentSecondRelativeRelation || '';
        this.StudentSecondRelativeMobile = vtStudentExitSurveyDetailItem.StudentSecondRelativeMobile || '';
        this.FamilyMemberCount = vtStudentExitSurveyDetailItem.FamilyMemberCount || '';
        this.FamilyMemberMigrate = vtStudentExitSurveyDetailItem.FamilyMemberMigrate || '';
        this.ReasonOfMigration = vtStudentExitSurveyDetailItem.ReasonOfMigration || '';
        this.Pleasemention = vtStudentExitSurveyDetailItem.Pleasemention || '';
        this.FatherOccupation = vtStudentExitSurveyDetailItem.FatherOccupation || '';
        this.FatherOccupationDetails = vtStudentExitSurveyDetailItem.FatherOccupationDetails || '';
        this.MotherOccupation = vtStudentExitSurveyDetailItem.MotherOccupation || '';
        this.MotherOccupationDetails = vtStudentExitSurveyDetailItem.MotherOccupationDetails || '';
        this.EmployementExchangeKnowledge = vtStudentExitSurveyDetailItem.EmployementExchangeKnowledge || '';
        this.EnrolledInEmploymentOffice = vtStudentExitSurveyDetailItem.EnrolledInEmploymentOffice || '';
        this.CurrentEmployementStatus = vtStudentExitSurveyDetailItem.CurrentEmployementStatus || '';
        this.EmployementOrganisation = vtStudentExitSurveyDetailItem.EmployementOrganisation || '';
        this.NatureOfWork = vtStudentExitSurveyDetailItem.NatureOfWork || '';
        this.EmployementInVocationalEducationSector = vtStudentExitSurveyDetailItem.EmployementInVocationalEducationSector || '';
        this.WorkDetails = vtStudentExitSurveyDetailItem.WorkDetails || '';
        this.EarningsPerMonth = vtStudentExitSurveyDetailItem.EarningsPerMonth || '';
        this.ContinueStudies = vtStudentExitSurveyDetailItem.ContinueStudies || '';
        this.CoursePursue = vtStudentExitSurveyDetailItem.CoursePursue || '';
        this.CourseDetails = vtStudentExitSurveyDetailItem.CourseDetails || '';
        this.CourseSelectionReason = vtStudentExitSurveyDetailItem.CourseSelectionReason || '';
        this.ReasonDetails = vtStudentExitSurveyDetailItem.ReasonDetails || '';
        this.PostCourseCompletionPlanning = vtStudentExitSurveyDetailItem.PostCourseCompletionPlanning || '';
        this.TargetedEmployer = vtStudentExitSurveyDetailItem.TargetedEmployer || '';
        this.CapitalSource = vtStudentExitSurveyDetailItem.CapitalSource || '';
        this.OtherDetails = vtStudentExitSurveyDetailItem.OtherDetails || '';
        this.NotDecidedReason = vtStudentExitSurveyDetailItem.NotDecidedReason || '';
        this.VEHelpCourseCompletion = vtStudentExitSurveyDetailItem.VEHelpCourseCompletion || '';
        this.ExpectedIncome = vtStudentExitSurveyDetailItem.ExpectedIncome || '';
        this.OtherInformation = vtStudentExitSurveyDetailItem.OtherInformation || '';
        this.DiscontinueEducationReason = vtStudentExitSurveyDetailItem.DiscontinueEducationReason || '';
        this.Plans = vtStudentExitSurveyDetailItem.Plans || '';
        this.PlansDetails = vtStudentExitSurveyDetailItem.PlansDetails || '';
        this.PlansRelatedToVESector = vtStudentExitSurveyDetailItem.PlansRelatedToVESector || '';
        this.VEHelpsToTakeDecision = vtStudentExitSurveyDetailItem.VEHelpsToTakeDecision || '';
        this.IsActive = vtStudentExitSurveyDetailItem.IsActive || true;
        this.RequestType = 0; // New
    }
}
