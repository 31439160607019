export const locale = {
    lang: 'en',
    data: {
        'ROLE': {
            'ROLES': 'ROLES',
            'ADD_NEW_ROLES': 'ADD',
            'SEARCH_FOR_A_ROLES': 'Search for a roles',
        }
    }
};

