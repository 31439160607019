<div id="internshipfeedback" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/internshipfeedback'" skipLocationChange="true"
                    replaceUrl="false">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ internshipfeedbackModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New Daily Dairy
                    </div>
                    <div class="subtitle secondary-text">
                        <span>Internship Feedback</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-internshipfeedback-button" *ngIf="PageRights.ActionType ==='new'"
                (click)="saveOrUpdateInternshipfeedbackDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-internshipfeedback-button" *ngIf="PageRights.ActionType ==='edit'"
                (click)="saveOrUpdateInternshipfeedbackDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="internshipfeedbackForm" [formGroup]="internshipfeedbackForm" class="internshipfeedback w-100-p p-24 mr-24"
                    fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="75" fxFlex.lt-sm="100" *ngIf="this.UserModel.RoleCode === 'VC'">
                            <mat-label>Vocational Trainer Name</mat-label>
                            <mat-select formControlName="VTId" name="VTId" appearance="outline" [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeVocationalTrainer($event.value)" required>
                            <mat-option [value]="VTItem.Id" *ngFor="let VTItem of vocationalTrainerList" >
                            {{ VTItem.Name }}
                            </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Select Student</mat-label>
                            <mat-select name="StudentId" formControlName="StudentId" appearance="outline"
                                [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeStudent($event.value)">
                                <mat-option *ngFor="let studentItem of studentList" [value]="studentItem.Id" >
                                    {{ studentItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline"  fxFlex="34" fxFlex.lt-md="50"
                        fxFlex.lt-sm="100">
                            <mat-label>Mobile(WhatsApp)</mat-label>
                            <input matInput name="WhatsappMobile" formControlName="WhatsappMobile" digitOnly minlength="12"
                                maxlength="12" placeholder="Ex. 9800098000" disabled>
                            <!-- <mat-error *ngIf="studentClassForm.controls.WhatsappMobile.invalid">
                                {{getErrorMessage(studentClassForm, 'WhatsappMobile')}}
                            </mat-error> -->
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Feedback submission date</mat-label>
                            <input matInput [matDatepicker]="DateOfActivity" name="DateOfActivity" formControlName="DateOfActivity" [disabled]="PageRights.IsReadOnly" [max]="CurrentDate"  [readonly]="true" (click)="DateOfActivity.open()">
                            <mat-datepicker-toggle matSuffix [for]="DateOfActivity"></mat-datepicker-toggle>
                            <mat-datepicker #DateOfActivity></mat-datepicker>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="55" fxFlex.lt-md="45" fxFlex.lt-sm="100">
                            <mat-label>Was internship orientation organized for you either online or offline?</mat-label>
                            <mat-select name="WasOrientationOrganized" formControlName="WasOrientationOrganized" appearance="outline" [disabled]="PageRights.IsReadOnly" matTooltipPosition="above">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="45" fxFlex.lt-md="55" fxFlex.lt-sm="100" *ngIf="internshipfeedbackForm.controls.WasOrientationOrganized.value == 'Yes'">
                            <mat-label>Did you participate in the internship orientation?</mat-label>
                            <mat-select name="ParticipateInOrientation" formControlName="ParticipateInOrientation" appearance="outline" [disabled]="PageRights.IsReadOnly" matTooltipPosition="above">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px" *ngIf="internshipfeedbackForm.controls.ParticipateInOrientation.value == 'Yes'">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Did you enjoy the orientation session?</mat-label>
                            <mat-select name="EnjoyOrientation" formControlName="EnjoyOrientation" appearance="outline" [disabled]="PageRights.IsReadOnly" matTooltipPosition="above">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="50" fxFlex.lt-sm="100" *ngIf="internshipfeedbackForm.controls.EnjoyOrientation.value == 'Yes'">
                            <mat-label>What did you enjoy the most during the orientation session?</mat-label>
                            <mat-select name="EnjoyOrientationOption" formControlName="EnjoyOrientationOption" multiple appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option value="Interaction with each other">Interaction with each other</mat-option>
                                <mat-option value="Presentation shown">Presentation shown</mat-option>
                                <mat-option value="Videos shown during session">Videos shown during session</mat-option>
                                <mat-option value="Interaction with alumni student">Interaction with alumni student</mat-option>
                                <mat-option value="Other">Other</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div> 

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="50" fxFlex.lt-sm="100" *ngIf="internshipfeedbackForm.controls.EnjoyOrientation.value == 'No'">
                            <mat-label>Can you tell us why you could not enjoy the session?</mat-label>
                            <input matInput name="NotEnjoyOrientationText" formControlName="NotEnjoyOrientationText" matTooltipPosition="above">
                        </mat-form-field>

                    </div>    

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100" *ngIf="internshipfeedbackForm.controls.EnjoyOrientation.value == 'No'">
                            <mat-label>How to improve the orientation session according to you?</mat-label>
                            <mat-select name="ImprovementInOrientationOption" formControlName="ImprovementInOrientationOption" multiple appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option value="Content of presentation">Content of presentation</mat-option>
                                <mat-option value="Language (Hindi/English/regional language)">Language (Hindi/English/regional language)</mat-option>
                                <mat-option value="Interaction">Interaction</mat-option>
                                <mat-option value="Ability of team to make you understand">Ability of team to make you understand</mat-option>
                                <mat-option value="Other">Other</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div> 

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100" *ngIf="internshipfeedbackForm.controls.ImprovementInOrientationOption.value == 'Other'">
                            <mat-label>Please share specific suggestions</mat-label>
                            <input matInput name="ImprovementInOrientationText" formControlName="ImprovementInOrientationText" matTooltipPosition="above">
                        </mat-form-field>
                    
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="33" fxFlex.lt-sm="100">
                            <mat-label>When did you start internship?</mat-label>
                            
                            <input matInput [matDatepicker]="InternshipStartAt" name="InternshipStartAt" formControlName="InternshipStartAt" [disabled]="PageRights.IsReadOnly"  [readonly]="true" (click)="InternshipStartAt.open()">
                            <mat-datepicker-toggle matSuffix [for]="InternshipStartAt"></mat-datepicker-toggle>
                            <mat-datepicker #InternshipStartAt></mat-datepicker>

                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="34" fxFlex.lt-sm="100">
                            <mat-label>When did you finish internship?</mat-label>
                            
                            <input matInput [matDatepicker]="InternshipEndtAt" name="InternshipEndtAt" formControlName="InternshipEndtAt" [disabled]="PageRights.IsReadOnly" [readonly]="true" (click)="InternshipEndtAt.open()">
                            <mat-datepicker-toggle matSuffix [for]="InternshipEndtAt"></mat-datepicker-toggle>
                            <mat-datepicker #InternshipEndtAt></mat-datepicker>

                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="33" fxFlex.lt-sm="100">
                            <mat-label>Supervisor name from your workplace who guided you</mat-label>
                            <input matInput name="Supervisor" formControlName="Supervisor" matTooltipPosition="above">
                        </mat-form-field>
    
                    </div> 

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>Were you satisfied with the way your employer/supervisor supported you during the entire internship period?</mat-label>
                            <mat-select name="SatisfiedWithSupervisor" formControlName="SatisfiedWithSupervisor" appearance="outline" [disabled]="PageRights.IsReadOnly" matTooltipPosition="above">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                        </mat-form-field>
    
                    </div>
                    
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100" *ngIf="internshipfeedbackForm.controls.SatisfiedWithSupervisor.value == 'Yes'">
                            <mat-label>What all things you liked about your employer/supervisor?</mat-label>
                            <mat-select name="ThingsLikedInSupervisor" formControlName="ThingsLikedInSupervisor" multiple appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option value="Employer spent enough time to guide you">Employer spent enough time to guide you</mat-option>
                                <mat-option value="Employer had frequent interactions with you">Employer had frequent interactions with you</mat-option>
                                <mat-option value="Ability of employer to explain the activity to you">Ability of employer to explain the activity to you</mat-option>
                                <mat-option value="Timely doubts clearance">Timely doubts clearance</mat-option>
                                <mat-option value="Other">Other</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100" *ngIf="internshipfeedbackForm.controls.ThingsLikedInSupervisor.value == 'Other'">
                            <mat-label>Please specify what you like the most about your employer</mat-label>
                            <input matInput name="ThingsLikedInSupervisorText" formControlName="ThingsLikedInSupervisorText" matTooltipPosition="above">
                        </mat-form-field>
    
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100" *ngIf="internshipfeedbackForm.controls.SatisfiedWithSupervisor.value == 'No'">
                            <mat-label>What needs to be improved from the employer's end according to you?</mat-label>
                            <mat-select name="ImprovementInSupervisor" formControlName="ImprovementInSupervisor" multiple appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option value="Frequent interaction with employer">Frequent interaction with employer</mat-option>
                                <mat-option value="Explanation of the activities">Explanation of the activities</mat-option>
                                <mat-option value="The tasks (activities) that were assigned to you">The tasks (activities) that were assigned to you</mat-option>
                                <mat-option value="Support from employer">Support from employer</mat-option>
                                <mat-option value="Other">Other</mat-option>
                            </mat-select>
                        </mat-form-field>
    
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100" *ngIf="internshipfeedbackForm.controls.ImprovementInSupervisor.value == 'Other'">
                            <mat-label>Please specify what else needs to be improved from the employer's end?</mat-label>
                            <input matInput name="ImprovementInSupervisorText" formControlName="ImprovementInSupervisorText" matTooltipPosition="above">
                        </mat-form-field>

                     </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>Which of the following activities did your Vocational trainer do during your internship?</mat-label>
                            <mat-select name="VTActivities" formControlName="VTActivities" multiple appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option value="VT  oriented you for internship">VT  oriented you for internship</mat-option>
                                <mat-option value="VT took regular follow up by calling you">VT took regular follow up by calling you</mat-option>
                                <mat-option value="VT Visited the workplace to know about your work and support">VT Visited the workplace to know about your work and support</mat-option>
                                <mat-option value="VT checked your daily diary regularly">VT checked your daily diary regularly</mat-option>
                                <mat-option value="VT supported you throughout">VT supported you throughout</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>    
                    
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>Did your employer/supervisor maintain your daily attendance register/sheet?</mat-label>
                            <mat-select name="SupervisorMaintainAttendance" formControlName="SupervisorMaintainAttendance" appearance="outline" [disabled]="PageRights.IsReadOnly" matTooltipPosition="above">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                        </mat-form-field>
    
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>How was your overall internship experience?</mat-label>
                            <mat-select name="OverallInternshipExperience" formControlName="OverallInternshipExperience" multiple appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option value="Good">Good</mat-option>
                                <mat-option value="Could have been better">Could have been better</mat-option>
                                <mat-option value="Not good">Not good</mat-option>
                                <mat-option value="Can’t say ">Can’t say </mat-option>
                            </mat-select>
                        </mat-form-field>    
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>Did you face any challenges during your internship? Please share with us the challenges you faced during internship?</mat-label>
                            <mat-select name="FaceChallenge" formControlName="FaceChallenge" multiple appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option value="I did not understand the activities completely">I did not understand the activities completely</mat-option>
                                <mat-option value="I did not have adequate access to resources (smart phone/internet service)">I did not have adequate access to resources (smart phone/internet service)</mat-option>
                                <mat-option value="My parents did not allow me to complete the assigned work">My parents did not allow me to complete the assigned work</mat-option>
                                <mat-option value="I did not get enough time to complete the activities due to myschools / tuitions / household work">I did not get enough time to complete the activities due to myschools / tuitions / household work</mat-option>
                                <mat-option value="Employer was not supportive enough">Employer was not supportive enough </mat-option>
                                <mat-option value="Other co-workers were not supportive enough">Other co-workers were not supportive enough</mat-option>
                                <mat-option value="Other">Other</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100" *ngIf="internshipfeedbackForm.controls.FaceChallenge.value == 'Other'">
                            <mat-label>Please specify the challenges.</mat-label>
                            <input matInput name="FaceChallengeText" formControlName="FaceChallengeText" matTooltipPosition="above">
                        </mat-form-field>
    
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>What skills did you develop through your internship?</mat-label>
                            <mat-select name="SkillsDevelopInInternship" formControlName="SkillsDevelopInInternship" multiple appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option value="My confidence has increased">My confidence has increased</mat-option>
                                <mat-option value="I have developed my communication skills">I have developed my communication skills</mat-option>
                                <mat-option value="I have learned to manage my time effectively">I have learned to manage my time effectively</mat-option>
                                <mat-option value="I have developed creative skills">I have developed creative skills</mat-option>
                                <mat-option value="I have developed digital skills (Google drive, gmail, google doc etc)">I have developed digital skills (Google drive, gmail, google doc etc) </mat-option>
                                <mat-option value="I have developed social media skills">I have developed social media skills</mat-option>
                                <mat-option value="I didn't learn any new skills">I didn't learn any new skills</mat-option>
                                <mat-option value="I am not sure how to respond to this">I am not sure how to respond to this</mat-option>
                                <mat-option value="Other skills">Other skills</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100" *ngIf="internshipfeedbackForm.controls.SkillsDevelopInInternship.value == 'Other skills'">
                            <mat-label>Please specify the skills you developed</mat-label>
                            <input matInput name="SkillsDevelopInInternshipText" formControlName="SkillsDevelopInInternshipText" matTooltipPosition="above">
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>What did you learn through your internship?</mat-label>
                            <mat-select name="LearnInInternship" formControlName="LearnInInternship" multiple appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option value="I have learnt to manage tasks on my own">I have learnt to manage tasks on my own</mat-option>
                                <mat-option value="I got clarity on what career I want to pursue">I got clarity on what career I want to pursue</mat-option>
                                <mat-option value="I have learnt subject specific skills and concepts">I have learnt subject specific skills and concepts</mat-option>
                                <mat-option value="I didn't learn anything">I didn't learn anything</mat-option>
                                <mat-option value="I am not sure how to respond to this">I am not sure how to respond to this </mat-option>
                                <mat-option value="Other">Other</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100" *ngIf="internshipfeedbackForm.controls.LearnInInternship.value == 'Other'">
                            <mat-label>Please specify about the things you learnt</mat-label>
                            <input matInput name="LearnInInternshipText" formControlName="LearnInInternshipText" matTooltipPosition="above">
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>What has been your overall take away from the Internships?</mat-label>
                            <input matInput name="OverallFeedbackInternship" formControlName="OverallFeedbackInternship" matTooltipPosition="above">
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>Did you prepare your internship project report?</mat-label>
                            <mat-select name="PrepareInternshipReport" formControlName="PrepareInternshipReport" appearance="outline" [disabled]="PageRights.IsReadOnly" matTooltipPosition="above">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div fxLayout="column" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px" 
                    *ngIf="PageRights.ActionType ==='new' ">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>Please post pic of yourself while working on activities</mat-label>
                            <ngx-mat-file-input name="attachmentFile" formControlName="ScreenshotFile"
                                [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile($event)">
                            </ngx-mat-file-input>
                            <mat-icon matSuffix>folder</mat-icon>
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>Please post other pic of yourself while working on activities</mat-label>
                            <ngx-mat-file-input name="attachmentFile2" formControlName="ScreenshotFile2"
                                [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile2($event)">
                            </ngx-mat-file-input>
                            <mat-icon matSuffix>folder</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>Please upload  internship project report picture here</mat-label>
                            <ngx-mat-file-input name="attachmentFile3" formControlName="ScreenshotFile3"
                                [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile3($event)">
                            </ngx-mat-file-input>
                            <mat-icon matSuffix>folder</mat-icon>
                        </mat-form-field>
                    </div>

                    <div fxLayout="column" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"  fxLayoutGap.lt-md="0px" 
                    *ngIf=" PageRights.ActionType ==='edit'">
                        
                        <!-- <div fxLayout="column" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <h3 style="margin-bottom:5px; margin-top: 0;">
                                Please post pic of yourself while working on activities: 
                                <a *ngIf="AttachmentURL" target="_blank" href="{{AttachmentURL}}">View Uploaded Image</a>
                            </h3>
                            <mat-form-field appearance="outline" fxFlex="20" fxFlex.lt-md="30" fxFlex.lt-sm="30">
                                <mat-label>Upload new image</mat-label>
                                <ngx-mat-file-input name="attachmentFile" formControlName="ScreenshotFile"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile($event)">
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                            </mat-form-field>
                        </div>

                        <div fxLayout="column" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <h3 style="margin-bottom:5px; margin-top: 0;">
                                Please post other pic of yourself while working on activities: 
                                <a *ngIf="AttachmentURL2" target="_blank" href="{{AttachmentURL2}}">View Uploaded Image</a>
                            </h3>
                            <mat-form-field appearance="outline" fxFlex="20" fxFlex.lt-md="30" fxFlex.lt-sm="30">
                                <mat-label>Upload new image</mat-label>
                                <ngx-mat-file-input name="attachmentFile2" formControlName="ScreenshotFile2"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile2($event)">
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                            </mat-form-field>
                        </div>

                        <div fxLayout="column" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <h3 style="margin-bottom:5px; margin-top: 0;">
                                Please upload  internship project report picture here: 
                                <a *ngIf="AttachmentURL3" target="_blank" href="{{AttachmentURL3}}">View Uploaded Image</a>
                            </h3>
                            <mat-form-field appearance="outline" fxFlex="20" fxFlex.lt-md="30" fxFlex.lt-sm="30">
                                <mat-label>Upload new image</mat-label>
                                <ngx-mat-file-input name="attachmentFile3" formControlName="ScreenshotFile3"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile3($event)">
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                            </mat-form-field>
                        </div> -->

                        <ng-template #showUploadUrl>
                            <a *ngIf="AttachmentURL" target="_blank" href="{{AttachmentURL}}"> View Uploaded Image</a>
                        </ng-template>
                        <ng-template #ShowGcsUrl>
                            <a *ngIf="ImageUrlGCSTag" target="_blank" href="{{ImageUrlGCSTag}}"> View Uploaded Image</a>
                        </ng-template>

                        <div fxLayout="column" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <h3 style="margin-bottom:5px; margin-top: 0;display: flex;">
                                Please post pic of yourself while working on activities:  
                                <div *ngIf="AttachmentURL; then showUploadUrl; else ShowGcsUrl"></div>
                            </h3>
                            <mat-form-field appearance="outline" fxFlex="20" fxFlex.lt-md="30" fxFlex.lt-sm="30">
                                <mat-label>Upload new image</mat-label>
                                <ngx-mat-file-input name="attachmentFile" formControlName="ScreenshotFile"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile($event)">
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                            </mat-form-field>
                        </div>

                        <ng-template #showUploadUrl2>
                            <a *ngIf="AttachmentURL2" target="_blank" href="{{AttachmentURL2}}"> View Uploaded Image</a>
                        </ng-template>
                        <ng-template #ShowGcsUrl2>
                            <a *ngIf="ImageUrlGCS2Tag" target="_blank" href="{{ImageUrlGCS2Tag}}"> View Uploaded Image</a>
                        </ng-template>

                        <div fxLayout="column" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <h3 style="margin-bottom:5px; margin-top: 0; display: flex;">
                                Please post other pic of yourself while working on activities: 
                                <div *ngIf="AttachmentURL2; then showUploadUrl2; else ShowGcsUrl2"></div>
                            </h3>
                            <mat-form-field appearance="outline" fxFlex="20" fxFlex.lt-md="30" fxFlex.lt-sm="30">
                                <mat-label>Upload new image</mat-label>
                                <ngx-mat-file-input name="attachmentFile2" formControlName="ScreenshotFile2"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile2($event)">
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                            </mat-form-field>
                        </div>

                        <ng-template #showUploadUrl3>
                            <a *ngIf="AttachmentURL3" target="_blank" href="{{AttachmentURL3}}"> View Uploaded Image</a>
                        </ng-template>
                        <ng-template #ShowGcsUrl2>
                            <a *ngIf="ImageUrlGCS3Tag" target="_blank" href="{{ImageUrlGCS3Tag}}"> View Uploaded Image</a>
                        </ng-template>

                        <div fxLayout="column" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <h3 style="margin-bottom:5px; margin-top: 0; display: flex;">
                                Add any other picture and videos from your workplace or any work done today: 
                                 <div *ngIf="AttachmentURL3; then showUploadUrl3; else ShowGcsUrl2"></div>
                            </h3>
                            <mat-form-field appearance="outline" fxFlex="20" fxFlex.lt-md="30" fxFlex.lt-sm="30">
                                <mat-label>Upload new image</mat-label>
                                <ngx-mat-file-input name="attachmentFile3" formControlName="ScreenshotFile3"
                                    [accept]="AllowedImageExtensions" (change)="uploadedScreenshotFile3($event)">
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                            </mat-form-field>
                        </div>

                    </div>
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>