import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant'
import { VTClassService } from '../vt-class.service';
import { VTClassModel } from '../vt-class.model';
import { DropdownModel } from 'app/models/dropdown.model';

@Component({
  selector: 'vt-class',
  templateUrl: './create-vt-class.component.html',
  styleUrls: ['./create-vt-class.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateVTClassComponent extends BaseComponent<VTClassModel> implements OnInit {
  vtClassForm: FormGroup;
  vtClassModel: VTClassModel;

  academicYearList: [DropdownModel];
  vtSchoolSectorList: [DropdownModel];
  classList: [DropdownModel];
  sectionList: [DropdownModel];
  aySchoolJobRoleList: [DropdownModel];
  noSectionId = "40b2d9eb-0dbf-11eb-ba32-0a761174c048";

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private vtClassService: VTClassService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default vtClass Model
    this.vtClassModel = new VTClassModel();
  }

  ngOnInit(): void {
    this.vtClassService.getAcademicYearClassSection(this.UserModel).subscribe(results => {
      if (results[0].Success) {
        this.academicYearList = results[0].Results;
      }

      if (results[1].Success) {
        this.classList = results[1].Results;
      }

      if (results[2].Success) {
        this.sectionList = results[2].Results;
        this.sectionList.splice(0, 1);
      }

      this.route.paramMap.subscribe(params => {
        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.vtClassModel = new VTClassModel();

          } else {
            var vtClassId: string = params.get('vtClassId')

            this.vtClassService.getVTClassById(vtClassId)
              .subscribe((response: any) => {
                this.vtClassModel = response.Result;

                if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                  this.vtClassModel.RequestType = this.Constants.PageType.Edit;
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.vtClassModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }

                this.vtClassForm = this.createVTClassForm();
              });
          }
        }
      });
    });

    this.vtClassForm = this.createVTClassForm();
  }

  onSectionChange(selectedSectionIds) {
    if (selectedSectionIds.length == 0) {
      this.sectionList.forEach(sectionItem => {
        sectionItem.IsDisabled = false;
      });
    }
    else {
      if (selectedSectionIds[0] == this.noSectionId) {
        this.sectionList.forEach(sectionItem => {
          if (sectionItem.Id != selectedSectionIds[0]) {
            sectionItem.IsDisabled = true;
          }
        });
      }
      else {
        let sectionItem = this.sectionList.find(s => s.Id == this.noSectionId);
        sectionItem.IsDisabled = true;
      }
    }
  }

  saveOrUpdateVTClassDetails() {
    if (!this.vtClassForm.valid) {
      this.validateAllFormFields(this.vtClassForm);  
      return;
    }
    this.setValueFromFormGroup(this.vtClassForm, this.vtClassModel);
    this.vtClassModel.VTId = this.UserModel.UserTypeId;

    this.vtClassService.createOrUpdateVTClass(this.vtClassModel)
      .subscribe((vtClassResp: any) => {

        if (vtClassResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.VTClass.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(vtClassResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('VTClass deletion errors =>', error);
      });
  }

  //Create vtClass form and returns {FormGroup}
  createVTClassForm(): FormGroup {
    return this.formBuilder.group({
      VTClassId: new FormControl(this.vtClassModel.VTClassId),
      AcademicYearId: new FormControl({ value: this.vtClassModel.AcademicYearId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      VTId: new FormControl({ value: this.vtClassModel.VTId, disabled: this.PageRights.IsReadOnly }),
      ClassId: new FormControl({ value: this.vtClassModel.ClassId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      SectionIds: new FormControl({ value: this.vtClassModel.SectionIds, disabled: this.PageRights.IsReadOnly }, Validators.required),
      IsActive: new FormControl({ value: this.vtClassModel.IsActive, disabled: this.PageRights.IsReadOnly }),
    });
  }
}
