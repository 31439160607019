import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { UserModel } from "app/models/user.model";

@Injectable()
export class CourseMaterialService {
    constructor(private http: BaseService) { }

    getCourseMaterials(): Observable<any> {
        return this.http
            .HttpGet(this.http.Services.CourseMaterial.GetAll)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetAllByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.CourseMaterial.GetAllByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    getCourseMaterialById(courseMaterialId: string) {
        let requestParams = {
            DataId: courseMaterialId
        };

        return this.http
            .HttpPost(this.http.Services.CourseMaterial.GetById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    createOrUpdateCourseMaterial(formData: any) {
        return this.http
            .HttpPost(this.http.Services.CourseMaterial.CreateOrUpdate, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    deleteCourseMaterialById(courseMaterialId: string) {
        var courseMaterialParams = {
            CourseMaterialId: courseMaterialId
        };

        return this.http
            .HttpPost(this.http.Services.CourseMaterial.Delete, courseMaterialParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    getAcademicYearClass(userModel: UserModel): Observable<any[]> {
        let academicYearRequest = this.http.GetMasterDataByType({ DataType: 'AcademicYearsByVT', ParentId: userModel.UserTypeId, SelectTitle: 'Academic Year' });
        //let classRequest = this.http.GetMasterDataByType({ DataType: 'SchoolClasses', SelectTitle: 'Class' });

        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([academicYearRequest]);
    }
}
