import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { ToolsAndEquipmentStatusModel } from './tools-equipment-status.model';
import { ReportService } from 'app/reports/report.service';
import { DropdownModel } from 'app/models/dropdown.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'data-list-view',
  templateUrl: './tools-equipment-status.component.html',
  styleUrls: ['./tools-equipment-status.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class ToolsAndEquipmentStatusComponent extends BaseListComponent<ToolsAndEquipmentStatusModel> implements OnInit {
  toolsAndEquipmentStatusForm: FormGroup;

  academicyearList: [DropdownModel];
  divisionList: [DropdownModel];
  districtList: [DropdownModel];
  sectorList: [DropdownModel];
  jobRoleList: [DropdownModel];
  vtpList: [DropdownModel];
  classList: [DropdownModel];
  monthList: [DropdownModel];
  schoolManagementList: [DropdownModel];

  currentAcademicYearId: string;
  isShowFilterContainer = false;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    public formBuilder: FormBuilder,
    private reportService: ReportService) {
    super(commonService, router, routeParams, snackBar, zone);
  }

  ngOnInit(): void {
    this.reportService.GetDropdownForReports(this.UserModel).subscribe(results => {
      if (results[0].Success) {
        this.academicyearList = results[0].Results;
      }

      if (results[1].Success) {
        this.divisionList = results[1].Results;
      }

      if (results[2].Success) {
        this.sectorList = results[2].Results;
      }

      if (results[3].Success) {
        this.vtpList = results[3].Results;
      }

      if (results[4].Success) {
        this.classList = results[4].Results;
      }

      if (results[5].Success) {
        this.monthList = results[5].Results;
      }

      if (results[6].Success) {
        this.schoolManagementList = results[6].Results;
      }

      let currentYearItem = this.academicyearList.find(ay => ay.IsSelected == true)
      if (currentYearItem != null) {
        this.currentAcademicYearId = currentYearItem.Id;
        this.toolsAndEquipmentStatusForm.get('AcademicYearId').setValue(this.currentAcademicYearId);

        if (this.UserModel.RoleCode === 'DivEO') {
          this.toolsAndEquipmentStatusForm.controls["DivisionId"].setValue(this.UserModel.DivisionId);
          this.toolsAndEquipmentStatusForm.controls["DivisionId"].disable();

          this.onChangeDivision(this.UserModel.DivisionId).then(response => {
            this.getToolsAndEquipmentStatusReports();
          });
        }
        else if (this.UserModel.RoleCode === 'DisEO' || this.UserModel.RoleCode === 'DisRP') {
          this.toolsAndEquipmentStatusForm.controls["DivisionId"].setValue(this.UserModel.DivisionId);
          this.toolsAndEquipmentStatusForm.controls["DivisionId"].disable();

          this.toolsAndEquipmentStatusForm.controls["DistrictId"].setValue(this.UserModel.DistrictId);
          this.toolsAndEquipmentStatusForm.controls["DistrictId"].disable();

          this.onChangeDivision(this.UserModel.DivisionId).then(response => {
            this.getToolsAndEquipmentStatusReports();
          });
        }
        else if (this.UserModel.RoleCode === 'BEO' || this.UserModel.RoleCode === 'BRP') {
          this.toolsAndEquipmentStatusForm.controls["DivisionId"].setValue(this.UserModel.DivisionId);
          this.toolsAndEquipmentStatusForm.controls["DivisionId"].disable();

          this.toolsAndEquipmentStatusForm.controls["DistrictId"].setValue(this.UserModel.DistrictId);
          this.toolsAndEquipmentStatusForm.controls["DistrictId"].disable();

          this.onChangeDivision(this.UserModel.DivisionId).then(response => {
            this.getToolsAndEquipmentStatusReports();
          });
        }
        else {
          this.getToolsAndEquipmentStatusReports();
        }
      }
    });

    this.toolsAndEquipmentStatusForm = this.createToolsAndEquipmentStatusForm();
  }

  resetReportFilters(): void {
    this.toolsAndEquipmentStatusForm.reset();
    this.toolsAndEquipmentStatusForm.get('AcademicYearId').setValue(this.currentAcademicYearId);
    this.toolsAndEquipmentStatusForm.controls["DivisionId"].setValue(this.UserModel.DivisionId);
    this.toolsAndEquipmentStatusForm.controls["DistrictId"].setValue(this.UserModel.DistrictId);

    this.getToolsAndEquipmentStatusReports();
  }

  onChangeDivision(divisionId: string): Promise<any> {
    let promise = new Promise((resolve, reject) => {

      this.commonService.GetMasterDataByType({ DataType: 'Districts', UserId: this.Constants.DefaultStateId, ParentId: divisionId, SelectTitle: 'District' })
        .subscribe((response: any) => {

          this.districtList = response.Results;
          resolve(response.Results);
        }, err => {

          reject(err);
        });
    });

    return promise;
  }

  onChangeSector(sectorId: string): void {
    this.commonService.GetMasterDataByType({ DataType: 'JobRoles', ParentId: sectorId, SelectTitle: 'Job Role' }).subscribe((response: any) => {
      this.jobRoleList = response.Results;
    });
  }

  //Create ToolsAndEquipmentStatus form and returns {FormGroup}
  createToolsAndEquipmentStatusForm(): FormGroup {
    return this.formBuilder.group({
      AcademicYearId: new FormControl(),
      MonthId: new FormControl(),
      DivisionId: new FormControl(),
      DistrictId: new FormControl(),
      SectorId: new FormControl(),
      JobRoleId: new FormControl(),
      ClassId: new FormControl(),
      VTPId: new FormControl(),
      SchoolManagementId: new FormControl()
    });
  }

  getToolsAndEquipmentStatusReports(): void {
    var reportParams = {
      UserId: this.UserModel.LoginId,
      AcademicYearId: this.toolsAndEquipmentStatusForm.get('AcademicYearId').value,
      DivisionId: this.toolsAndEquipmentStatusForm.get('DivisionId').value,
      DistrictId: this.toolsAndEquipmentStatusForm.get('DistrictId').value,
      SectorId: this.toolsAndEquipmentStatusForm.get('SectorId').value,
      JobRoleId: this.toolsAndEquipmentStatusForm.get('JobRoleId').value,
      VTPId: this.toolsAndEquipmentStatusForm.get('VTPId').value,
      ClassId: this.toolsAndEquipmentStatusForm.get('ClassId').value,
      MonthId: this.toolsAndEquipmentStatusForm.get('MonthId').value,
      SchoolManagementId: this.toolsAndEquipmentStatusForm.get('SchoolManagementId').value
    };

    this.reportService.GetToolsAndEquipmentStatusReportsByCriteria(reportParams).subscribe(response => {
      this.displayedColumns = ['SrNo', 'AcademicYear', 'SchoolAllottedYear', 'PhaseName', 'VTPName', 'VCName', 'VCMobile', 'VCEmail', 'VTName', 'VTMobile', 'VTEmail', 'VTDateOfJoining', 'HMName', 'HMMobile', 'HMEmail', 'SchoolManagement', 'DivisionName', 'DistrictName', 'BlockName', 'UDISE', 'SchoolName', 'SectorName', 'JobRoleName', 'ToolsAvailabilityStatus', 'ToolsReceiptStatus', 'MonthOfReceipt'];

      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }
}
