import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant'
import { InternshipService } from '../internship.service';
import { InternshipModel } from '../internship.model';
import { DropdownModel } from 'app/models/dropdown.model';
import { FileUploadModel } from 'app/models/file.upload.model';
import { FuseUtils } from '@fuse/utils';

@Component({
  selector: 'internship',
  templateUrl: './create-internship.component.html',
  styleUrls: ['./create-internship.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateInternshipComponent extends BaseComponent<InternshipModel> implements OnInit {
  showInternshipDetails: boolean = true;
  internshipForm: FormGroup;
  internshipModel: InternshipModel;

  studentList: DropdownModel[];
  vocationalTrainerList: [DropdownModel];
  employerName: string;
  attachmentFile: FileUploadModel;
  attachmentFile2: FileUploadModel;
  attachmentFile3: FileUploadModel;
  attachmentFile4: FileUploadModel;
  attachmentFile5: FileUploadModel;
  attachmentFile6: FileUploadModel;
  attachmentFile7: FileUploadModel;
  attachmentFile8: FileUploadModel;
  attachmentFile9: FileUploadModel;
  attachmentFile10: FileUploadModel;
  attachmentFile11: FileUploadModel;

  AttachmentURL: string;
  AttachmentURL2: string;
  AttachmentURL3: string;
  AttachmentURL4: string;
  AttachmentURL5: string;
  AttachmentURL6: string;
  AttachmentURL7: string;
  AttachmentURL8: string;
  AttachmentURL9: string;
  AttachmentURL10: string;
  AttachmentURL11: string;
  studentFormControl: any;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private internshipService: InternshipService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);
    this.attachmentFile = new FileUploadModel();
    this.attachmentFile2 = new FileUploadModel();
    this.attachmentFile3 = new FileUploadModel();
    this.attachmentFile4 = new FileUploadModel();
    this.attachmentFile5 = new FileUploadModel();
    this.attachmentFile6 = new FileUploadModel();
    this.attachmentFile7 = new FileUploadModel();
    this.attachmentFile8 = new FileUploadModel();
    this.attachmentFile9 = new FileUploadModel();
    this.attachmentFile10 = new FileUploadModel();
    this.attachmentFile11 = new FileUploadModel();

    // Set the default internship Model
    this.internshipModel = new InternshipModel();
  }

  ngOnInit(): void {

    this.internshipService.getStateDivisions(this.UserModel).subscribe(results => {

      if (results[0].Success) {
        this.studentList = results[0].Results;
      }
      if (results[1].Success) {
        this.vocationalTrainerList = results[1].Results;
      }
      
      this.route.paramMap.subscribe(params => {
        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.internshipModel = new InternshipModel();
            if (results[0].Success) {
              this.studentList = results[0].Results;
            }

          } else {
            var internshipId: string = params.get('internshipId')

            this.internshipService.getInternshipById(internshipId)
              .subscribe((response: any) => {
                this.internshipModel = response.Result;

                if (this.PageRights.ActionType == this.Constants.Actions.Edit){
                  this.internshipModel.RequestType = this.Constants.PageType.Edit;
                  if (results[2].Success) {
                    this.studentList = results[2].Results;
                  }
                  this.internshipForm.get('StudentId').disable();                
                }
                
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.internshipModel.RequestType = this.Constants.PageType.View;
                  if (results[2].Success) {
                    this.studentList = results[2].Results;
                  }
                  this.internshipForm.get('StudentId').disable();
                  this.PageRights.IsReadOnly = true;
                }

                this.onChangeStudent(this.internshipModel.StudentId);
                this.onChangeInternshipDaysHours();

                this.onChangeVocationalTrainer(this.internshipModel.VTId);

                this.AttachmentURL = this.internshipModel.Attachment;
                this.AttachmentURL2 = this.internshipModel.Attachment2;
                this.AttachmentURL3 = this.internshipModel.Attachment3;
                this.AttachmentURL4 = this.internshipModel.Attachment4;
                this.AttachmentURL5 = this.internshipModel.Attachment5;
                this.AttachmentURL6 = this.internshipModel.Attachment6;
                this.AttachmentURL7 = this.internshipModel.Attachment7;
                this.AttachmentURL8 = this.internshipModel.Attachment8;
                this.AttachmentURL9 = this.internshipModel.Attachment9;
                this.AttachmentURL10 = this.internshipModel.Attachment10;
                this.AttachmentURL11 = this.internshipModel.Attachment11;

                this.internshipForm = this.createInternshipForm();
              });
          }
        }
      });
    });

    this.internshipForm = this.createInternshipForm();
  }

  uploadedScreenshotFile(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedImageExtensions.indexOf(fileExtn) == -1) {
        this.internshipForm.get('ScreenshotFile').setValue(null);
        this.dialogService.openShowDialog("Please upload Image file only.");
        return;
      }


      this.getUploadedFileData(event, this.Constants.DocumentType.InternshipScreenshot).then((response: FileUploadModel) => {
        this.attachmentFile = response;
      });
    }
  }

  uploadedScreenshotFile2(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedImageExtensions.indexOf(fileExtn) == -1) {
        this.internshipForm.get('ScreenshotFile2').setValue(null);
        this.dialogService.openShowDialog("Please upload Image file only.");
        return;
      }


      this.getUploadedFileData(event, this.Constants.DocumentType.InternshipScreenshot).then((response: FileUploadModel) => {
        this.attachmentFile2 = response;
      });
    }
  }

  uploadedScreenshotFile3(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedImageExtensions.indexOf(fileExtn) == -1) {
        this.internshipForm.get('ScreenshotFile3').setValue(null);
        this.dialogService.openShowDialog("Please upload Image file only.");
        return;
      }


      this.getUploadedFileData(event, this.Constants.DocumentType.InternshipScreenshot).then((response: FileUploadModel) => {
        this.attachmentFile3 = response;
      });
    }
  }

  uploadedScreenshotFile4(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedImageExtensions.indexOf(fileExtn) == -1) {
        this.internshipForm.get('ScreenshotFile4').setValue(null);
        this.dialogService.openShowDialog("Please upload Image file only.");
        return;
      }


      this.getUploadedFileData(event, this.Constants.DocumentType.InternshipScreenshot).then((response: FileUploadModel) => {
        this.attachmentFile4 = response;
      });
    }
  }

  uploadedScreenshotFile5(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedReportExtension.indexOf(fileExtn) == -1) {
        this.internshipForm.get('ScreenshotFile5').setValue(null);
        this.dialogService.openShowDialog("Please upload jpg, jpeg, png, gif, tif, bmp, mp4, doc, pdf, xls, xlsx, pdf file only.");
        return;
      }


      this.getUploadedFileData(event, this.Constants.DocumentType.InternshipScreenshot).then((response: FileUploadModel) => {
        this.attachmentFile5 = response;
      });
    }
  }

  uploadedScreenshotFile6(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedImageExtensions.indexOf(fileExtn) == -1) {
        this.internshipForm.get('ScreenshotFile6').setValue(null);
        this.dialogService.openShowDialog("Please upload Image file only.");
        return;
      }


      this.getUploadedFileData(event, this.Constants.DocumentType.InternshipScreenshot).then((response: FileUploadModel) => {
        this.attachmentFile6 = response;
      });
    }
  }

  uploadedScreenshotFile7(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedImageExtensions.indexOf(fileExtn) == -1) {
        this.internshipForm.get('ScreenshotFile7').setValue(null);
        this.dialogService.openShowDialog("Please upload Image file only.");
        return;
      }


      this.getUploadedFileData(event, this.Constants.DocumentType.InternshipScreenshot).then((response: FileUploadModel) => {
        this.attachmentFile7 = response;
      });
    }
  }

  uploadedScreenshotFile8(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedImageExtensions.indexOf(fileExtn) == -1) {
        this.internshipForm.get('ScreenshotFile8').setValue(null);
        this.dialogService.openShowDialog("Please upload Image file only.");
        return;
      }


      this.getUploadedFileData(event, this.Constants.DocumentType.InternshipScreenshot).then((response: FileUploadModel) => {
        this.attachmentFile8 = response;
      });
    }
  }

  uploadedScreenshotFile9(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedImageExtensions.indexOf(fileExtn) == -1) {
        this.internshipForm.get('ScreenshotFile9').setValue(null);
        this.dialogService.openShowDialog("Please upload Image file only.");
        return;
      }


      this.getUploadedFileData(event, this.Constants.DocumentType.InternshipScreenshot).then((response: FileUploadModel) => {
        this.attachmentFile9 = response;
      });
    }
  }

  uploadedScreenshotFile10(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedReportExtension.indexOf(fileExtn) == -1) {
        this.internshipForm.get('ScreenshotFile10').setValue(null);
        this.dialogService.openShowDialog("Please upload jpg, jpeg, png, gif, tif, bmp, mp4, doc, pdf, xls, xlsx, pdf file only.");
        return;
      }


      this.getUploadedFileData(event, this.Constants.DocumentType.InternshipScreenshot).then((response: FileUploadModel) => {
        this.attachmentFile10 = response;
      });
    }
  }

  uploadedScreenshotFile11(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedReportExtension.indexOf(fileExtn) == -1) {
        this.internshipForm.get('ScreenshotFile11').setValue(null);
        this.dialogService.openShowDialog("Please upload jpg, jpeg, png, gif, tif, bmp, mp4, doc, pdf, xls, xlsx, pdf file only.");
        return;
      }


      this.getUploadedFileData(event, this.Constants.DocumentType.InternshipScreenshot).then((response: FileUploadModel) => {
        this.attachmentFile11 = response;
      });
    }
  }
  
onChangeStudent(StudentId: any) {
  this.commonService.GetMasterDataByType({ DataType: 'StudentEmployer', ParentId: StudentId, SelectTitle: 'StudentEmployer' }).subscribe((response: any) => {

    if (response.Results[1]) {
      this.employerName = response.Results[1].Name;
      this.internshipForm.get('SupervisorName').setValue(this.employerName);
      this.showInternshipDetails = true;
    } else {
      this.internshipForm.get('SupervisorName').setValue('');
      alert("Please add the employer for the student. Go to Student Registrations (Internship Details section)");
      this.showInternshipDetails = false;
    }
  });
}


  onChangeVocationalTrainer(VTId) {
    if (this.UserModel.RoleCode === 'VC') {
      this.commonService.GetMasterDataByType({ DataType : 'StudentsByVt', ParentId: VTId, SelectTitle: 'Select Student' })
      .subscribe((response: any) => {
        if(response.Success){
          this.studentList = response.Results;
        }
      });
    }
  }
  onChangeInternshipDaysHours() {
    var workingDays = this.internshipForm.get('WorkingDays').value;
    var hourPerdays = this.internshipForm.get('HoursPerDay').value;
    if (workingDays && hourPerdays) {
      var totalHoursOfInternship = (workingDays * hourPerdays);
      this.internshipForm.get('TotalHours').setValue(totalHoursOfInternship);

    }

  }
  
  saveOrUpdateInternshipDetails() {
    if (!this.internshipForm.valid) {
      this.validateAllFormFields(this.internshipForm);
      return;
    }

    this.setValueFromFormGroup(this.internshipForm, this.internshipModel);

    if (this.attachmentFile.Base64Data != '') {
      this.internshipModel.ScreenshotFile = new FileUploadModel({
        UserId: FuseUtils.NewGuid(),
        ContentId: null,
        FilePath: null,
        ContentType: this.attachmentFile.ContentType,
        FileName: this.attachmentFile.FileName,
        FileType: this.attachmentFile.FileType,
        FileSize: this.attachmentFile.FileSize,
        Base64Data: this.attachmentFile.Base64Data
      });
    }

    if (this.attachmentFile2.Base64Data != '') {
      this.internshipModel.ScreenshotFile2 = new FileUploadModel({
        UserId: FuseUtils.NewGuid(),
        ContentId: null,
        FilePath: null,
        ContentType: this.attachmentFile2.ContentType,
        FileName: this.attachmentFile2.FileName,
        FileType: this.attachmentFile2.FileType,
        FileSize: this.attachmentFile2.FileSize,
        Base64Data: this.attachmentFile2.Base64Data
      });
    }

    if (this.attachmentFile3.Base64Data != '') {
      this.internshipModel.ScreenshotFile3 = new FileUploadModel({
        UserId: FuseUtils.NewGuid(),
        ContentId: null,
        FilePath: null,
        ContentType: this.attachmentFile3.ContentType,
        FileName: this.attachmentFile3.FileName,
        FileType: this.attachmentFile3.FileType,
        FileSize: this.attachmentFile3.FileSize,
        Base64Data: this.attachmentFile3.Base64Data
      });
    }

    if (this.attachmentFile4.Base64Data != '') {
      this.internshipModel.ScreenshotFile4 = new FileUploadModel({
        UserId: FuseUtils.NewGuid(),
        ContentId: null,
        FilePath: null,
        ContentType: this.attachmentFile4.ContentType,
        FileName: this.attachmentFile4.FileName,
        FileType: this.attachmentFile4.FileType,
        FileSize: this.attachmentFile4.FileSize,
        Base64Data: this.attachmentFile4.Base64Data
      });
    }

    if (this.attachmentFile5.Base64Data != '') {
      this.internshipModel.ScreenshotFile5 = new FileUploadModel({
        UserId: FuseUtils.NewGuid(),
        ContentId: null,
        FilePath: null,
        ContentType: this.attachmentFile5.ContentType,
        FileName: this.attachmentFile5.FileName,
        FileType: this.attachmentFile5.FileType,
        FileSize: this.attachmentFile5.FileSize,
        Base64Data: this.attachmentFile5.Base64Data
      });
    }

    if (this.attachmentFile6.Base64Data != '') {
      this.internshipModel.ScreenshotFile6 = new FileUploadModel({
        UserId: FuseUtils.NewGuid(),
        ContentId: null,
        FilePath: null,
        ContentType: this.attachmentFile6.ContentType,
        FileName: this.attachmentFile6.FileName,
        FileType: this.attachmentFile6.FileType,
        FileSize: this.attachmentFile6.FileSize,
        Base64Data: this.attachmentFile6.Base64Data
      });
    }

    if (this.attachmentFile7.Base64Data != '') {
      this.internshipModel.ScreenshotFile7 = new FileUploadModel({
        UserId: FuseUtils.NewGuid(),
        ContentId: null,
        FilePath: null,
        ContentType: this.attachmentFile7.ContentType,
        FileName: this.attachmentFile7.FileName,
        FileType: this.attachmentFile7.FileType,
        FileSize: this.attachmentFile7.FileSize,
        Base64Data: this.attachmentFile7.Base64Data
      });
    }

    if (this.attachmentFile8.Base64Data != '') {
      this.internshipModel.ScreenshotFile8 = new FileUploadModel({
        UserId: FuseUtils.NewGuid(),
        ContentId: null,
        FilePath: null,
        ContentType: this.attachmentFile8.ContentType,
        FileName: this.attachmentFile8.FileName,
        FileType: this.attachmentFile8.FileType,
        FileSize: this.attachmentFile8.FileSize,
        Base64Data: this.attachmentFile8.Base64Data
      });
    }

    if (this.attachmentFile9.Base64Data != '') {
      this.internshipModel.ScreenshotFile9 = new FileUploadModel({
        UserId: FuseUtils.NewGuid(),
        ContentId: null,
        FilePath: null,
        ContentType: this.attachmentFile9.ContentType,
        FileName: this.attachmentFile9.FileName,
        FileType: this.attachmentFile9.FileType,
        FileSize: this.attachmentFile9.FileSize,
        Base64Data: this.attachmentFile9.Base64Data
      });
    }

    if (this.attachmentFile10.Base64Data != '') {
      this.internshipModel.ScreenshotFile10 = new FileUploadModel({
        UserId: FuseUtils.NewGuid(),
        ContentId: null,
        FilePath: null,
        ContentType: this.attachmentFile10.ContentType,
        FileName: this.attachmentFile10.FileName,
        FileType: this.attachmentFile10.FileType,
        FileSize: this.attachmentFile10.FileSize,
        Base64Data: this.attachmentFile10.Base64Data
      });
    }

    if (this.attachmentFile11.Base64Data != '') {
      this.internshipModel.ScreenshotFile11 = new FileUploadModel({
        UserId: FuseUtils.NewGuid(),
        ContentId: null,
        FilePath: null,
        ContentType: this.attachmentFile11.ContentType,
        FileName: this.attachmentFile11.FileName,
        FileType: this.attachmentFile11.FileType,
        FileSize: this.attachmentFile11.FileSize,
        Base64Data: this.attachmentFile11.Base64Data
      });
    }


    this.internshipService.createOrUpdateInternship(this.internshipModel)
      .subscribe((internshipResp: any) => {

        if (internshipResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.Internship.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(internshipResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('Internship deletion errors =>', error);
      });
  }

  //Create Internship form and returns {FormGroup}
  createInternshipForm(): FormGroup {
    return this.formBuilder.group({
      InternshipId: new FormControl(this.internshipModel.InternshipId),
      StudentId: new FormControl({ value: this.internshipModel.StudentId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      InternshipType: new FormControl({ value: this.internshipModel.InternshipType, disabled: this.PageRights.IsReadOnly }),
      DateOfCommencement: new FormControl({ value: this.getDateValue(this.internshipModel.DateOfCommencement), disabled: this.PageRights.IsReadOnly }),

      DateOfCompletion: new FormControl({ value: this.getDateValue(this.internshipModel.DateOfCompletion), disabled: this.PageRights.IsReadOnly }),

      WorkingDays: new FormControl({ value: this.internshipModel.WorkingDays, disabled: this.PageRights.IsReadOnly },[Validators.pattern(this.Constants.Regex.Number)]),
      HoursPerDay: new FormControl({ value: this.internshipModel.HoursPerDay, disabled: this.PageRights.IsReadOnly },[Validators.pattern(this.Constants.Regex.Number)]),
      TotalHours: new FormControl({ value: this.internshipModel.TotalHours, disabled: this.PageRights.IsReadOnly }),

      SupervisorName: new FormControl({ value: this.internshipModel.SupervisorName, disabled: true }),

      SafteyMesuresTaken: new FormControl({ value: this.internshipModel.SafteyMesuresTaken, disabled: this.PageRights.IsReadOnly }),
      StudentParticipatedInOrientation: new FormControl({ value: this.internshipModel.StudentParticipatedInOrientation, disabled: this.PageRights.IsReadOnly }),
      // ParentConsentFormsSigned: new FormControl({ value: this.internshipModel.ParentConsentFormsSigned, disabled: this.PageRights.IsReadOnly }),
      // TotalDaysSpent: new FormControl({ value: this.internshipModel.TotalDaysSpent, disabled: this.PageRights.IsReadOnly }),

      StudentCompletedInternship: new FormControl({ value: this.internshipModel.StudentCompletedInternship, disabled: this.PageRights.IsReadOnly }),

      DailyDairyMaintained: new FormControl({ value: this.internshipModel.DailyDairyMaintained, disabled: this.PageRights.IsReadOnly }),
      StudentFilledFeedbackForm: new FormControl({ value: this.internshipModel.StudentFilledFeedbackForm, disabled: this.PageRights.IsReadOnly }),

      EmployerFeedback: new FormControl({ value: this.internshipModel.EmployerFeedback, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(225)]),
      //StudentFeedback: new FormControl({ value: this.internshipModel.StudentFeedback, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(225)]),
      IsConsentApproved: new FormControl({ value: this.internshipModel.IsConsentApproved, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(5)]),
      LetterSignedByEmployer: new FormControl({ value: this.internshipModel.LetterSignedByEmployer, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(5)]),

      AttendanceMaintained: new FormControl({ value: this.internshipModel.AttendanceMaintained, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(5)]),
      WorkRegisterMaintained: new FormControl({ value: this.internshipModel.WorkRegisterMaintained, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(5)]),
      OJTCertificatePresent: new FormControl({ value: this.internshipModel.OJTCertificatePresent, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(5)]),
      ReportCreatedByStudent: new FormControl({ value: this.internshipModel.ReportCreatedByStudent, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(5)]),

      // InternshipImage1: new FormControl({ value: this.internshipModel.InternshipImage1, disabled: this.PageRights.IsReadOnly }),
      // InternshipImage2: new FormControl({ value: this.internshipModel.InternshipImage2, disabled: this.PageRights.IsReadOnly }),
      // InternshipImage3: new FormControl({ value: this.internshipModel.InternshipImage3, disabled: this.PageRights.IsReadOnly }),
      // InternshipImage4: new FormControl({ value: this.internshipModel.InternshipImage4, disabled: this.PageRights.IsReadOnly }),

      ScreenshotFile: new FormControl({ value: this.internshipModel.ScreenshotFile, disabled: this.PageRights.IsReadOnly }),
      ScreenshotFile2: new FormControl({ value: this.internshipModel.ScreenshotFile2, disabled: this.PageRights.IsReadOnly }),
      ScreenshotFile3: new FormControl({ value: this.internshipModel.ScreenshotFile3, disabled: this.PageRights.IsReadOnly }),
      ScreenshotFile4: new FormControl({ value: this.internshipModel.ScreenshotFile4, disabled: this.PageRights.IsReadOnly }),
      ScreenshotFile5: new FormControl({ value: this.internshipModel.ScreenshotFile5, disabled: this.PageRights.IsReadOnly }),

      ScreenshotFile6: new FormControl({ value: this.internshipModel.ScreenshotFile6, disabled: this.PageRights.IsReadOnly }),
      ScreenshotFile7: new FormControl({ value: this.internshipModel.ScreenshotFile7, disabled: this.PageRights.IsReadOnly }),
      ScreenshotFile8: new FormControl({ value: this.internshipModel.ScreenshotFile8, disabled: this.PageRights.IsReadOnly }),
      ScreenshotFile9: new FormControl({ value: this.internshipModel.ScreenshotFile9, disabled: this.PageRights.IsReadOnly }),

      ScreenshotFile10: new FormControl({ value: this.internshipModel.ScreenshotFile8, disabled: this.PageRights.IsReadOnly }),
      ScreenshotFile11: new FormControl({ value: this.internshipModel.ScreenshotFile9, disabled: this.PageRights.IsReadOnly }),

    });
  }
}
